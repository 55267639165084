import {
    StyleSheet
  } from 'react-native';
  import {
    CARD_TEXT_COLOR,
    COLOR_PRIMARY,
    COLOR_WHITE
  } from '../../../constants/layout';

export const cardStyleRules = {
    container: {
      backgroundColor: COLOR_WHITE
    },
    detailsCardContainer: {
      backgroundColor: COLOR_WHITE
    },
    containerWithBottomMargin: {
      backgroundColor: COLOR_WHITE,
      marginBottom: 10
    },
    inner: {
      backgroundColor: 'white'
    },
    cardIcon: {
      alignItems: 'flex-start',
      marginLeft: 0,
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column'
    },
    cardIconwithBubble: {
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 15,
      display: 'flex',
      flexDirection: 'column',
      width: 75,
      height: 75,
      borderRadius: 75,
      backgroundColor: COLOR_PRIMARY
    },
    content: {
      flex: 1,
      padding: 0,
      paddingLeft: 0,
      marginLeft: 10
    },
    title: {
      fontSize: 14,
      lineHeight: 16,
      fontWeight: 'normal',
      textAlign: 'left',
      color: CARD_TEXT_COLOR,
      marginBottom: 0
    },
    titleWithAction: {
      marginBottom: 0,
      lineHeight: 16,
      fontSize: 14,
      fontWeight: 'normal',
      textAlign: 'left',
      color: CARD_TEXT_COLOR
    },
    body: {
      fontSize: 14,
      color: CARD_TEXT_COLOR,
      marginTop: 5,
      textAlign: 'left'
    },
    detailsBody: {
      marginTop: 5
    },
    bodyWithAction: {
      fontSize: 14,
      color: CARD_TEXT_COLOR,
      marginTop: 5,
      textAlign: 'left'
    },
    chevron: {
      position: 'absolute',
      end: 0,
      top: '13%'
    },
    chevronWithButtons: {
      position: 'absolute',
      end: 0,
      top: '15%'
    },
    chevronMiddle: {
      position: 'absolute',
      end: 0,
      top: '40%'
    },
    expandableChevron: {
      position: 'absolute',
      end: 0,
      top: '0%'
    },
    card: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: 15,
      marginBottom: 7,
      marginTop: 7,
      padding: 18,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: '#fff',
      borderRadius: 4,
      shadowOpacity: 0.15,
      shadowRadius: 7,
      shadowOffset: {
          height: 2,
          width: 2
      },
      elevation: 7
    },
    cardTitle: {
        fontSize: 16,
        marginLeft: 25,
        marginRight: 25,
        marginTop: 10,
        color: CARD_TEXT_COLOR,
        textAlign: 'left'
    },
    horizontalSeparator: {
      borderBottomColor: '#d8d8d8',
      height: 10,
      marginTop: 10,
      marginBottom: 15,
      borderBottomWidth: 1,
      width: '100%'
    },
    buttonsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    buttonOutsideLeft: {
      width: '48%',
      alignItems: 'flex-start',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    buttonOutsideRight: {
      width: '48%',
      alignItems: 'flex-start',
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    buttonTouch: {
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row'
    },
    button: {
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row'
    },
    buttonIcon: {
      marginRight: 5
    },
    buttonText: {
     marginTop: 3,
     fontWeight: 'bold',
     fontSize: 14,
     color: COLOR_PRIMARY
    },
    vertical: {
      position: 'absolute',
      zIndex: 10,
      marginLeft: '99%'
    },
    infoRow: {
      width: '100%',
      flex: 1,
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 15
    },
    hiddenRow: {
      display: 'none'
    },
    infoRowTitle: {
      width: '30%',
      fontSize: 14,
      color: CARD_TEXT_COLOR,
      fontWeight: '500',
      flexShrink: 0
    },
    infoRowContent: {
      width: '70%',
      paddingLeft: 15,
      fontSize: 14,
      fontWeight: '300',
      color: CARD_TEXT_COLOR
    },
    infoRowContentText: {
      paddingLeft: 15,
      fontSize: 14,
      fontWeight: '300',
      color: CARD_TEXT_COLOR
    },
    infoRowHighlight: {
      width: '70%',
      paddingLeft: 15
    }
  };
  const cardStyles = StyleSheet.create(cardStyleRules);

  export default cardStyles;
