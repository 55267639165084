import React from 'react';
import { translate } from 'react-i18next';
import {
  COLOR_ACTION, COLOR_WHITE
} from '../../../constants/layout';
import Card from '../../layout/Card/Card';
import { formatShortDate } from '../../../helpers/formatters';

const RescheduleInProgressCard = ({
  t,
  jobDate
}) =>
  !jobDate
  ? null
  : <Card
    introText=""
    body={
      t('dashboard.notification.rescheduleInProgress')
      .replace('%s', formatShortDate(jobDate))
    }
    titleSize={16}
    icon={{
      name: 'notifications-none',
      iconSet: 'MaterialIcons',
      iconSize: 50,
      iconColor: COLOR_WHITE,
      iconBubbleColor: COLOR_ACTION
    }}
    buttons={false}
    cardStyle={false}
    displayChevron={false}
  />
;

export default translate()(RescheduleInProgressCard);
