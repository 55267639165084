import gql from 'graphql-tag';

const jobTeaser = gql`
    fragment jobTeaser on Job {
      reference
      id: reference
      state
      bookingDetails {
        start: appointmentStart(format: ISO_8601)
        end: appointmentEnd(format: ISO_8601)
        startTime: appointmentStart(format: TIME)
        endTime: appointmentEnd(format: TIME)
        day: appointmentStart(format: DAY)
        date: appointmentStart(format: DATE)
        dayShort: appointmentStart(format: DAY_SHORT)
        dateShort: appointmentStart(format: DATE_SHORT)
        frequency
        formattedFrequency: frequency(translate: true),
        serviceType: serviceType(translate: true)
      }
      rating {
        averageScore
      }
      reschedulingState
    }
  `;

export const getFutureJobs = gql`
  query getFutureJobs {
    jobs(from: "NOW", state: [NEW, READY]) {
      ...jobTeaser
    }
  }
  ${jobTeaser}
  `;

export const getPastJobs = gql`
  query getPastJobs {
    jobs(until: "NOW", sort: DESC, state: [READY, DONE, CANCELED]) {
      ...jobTeaser
    }
  }
  ${jobTeaser}
`;

export const getJob = gql`
  query getJob($reference: String) {
    job(reference: $reference) {
      id: reference
      state
      reference
      cleaner
      receipt
      reschedulingState
      payment {
        method
        last4Digits
      }
      address {
        city
        area
        building
        apartment
      }
      additionalInfo {
        keyPickUp
        keyAnswer
        specialInstructions
      }
      bookingDetails {
        frequency
        formattedFrequency: frequency(translate: true)
        duration(unit: HOUR)
        ironing(unit: HOUR)
        cleaningMaterial
        startTime: appointmentStart(format: TIME)
        endTime: appointmentEnd(format: TIME)
        start: appointmentStart(format: ISO_8601)
        end: appointmentEnd(format: ISO_8601)
        day: appointmentStart(format: DAY)
        dayShort: appointmentStart(format: DAY_SHORT)
        date: appointmentStart(format: DATE)
        dateShort: appointmentStart(format: DATE_SHORT)
        serviceType: serviceType(translate: true)
      }
      total {
        itemAmount {
          raw
          formatted
        }
        discountAmount {
          raw
          formatted
        }
        voucherCode
        vatAmount {
          raw
          formatted
        }
        grossAmount {
          raw
          formatted
        }
      }
      cancellationFees {
        raw
        formatted
      }
      order {
        reference
        cancellationFees {
          raw
          formatted
        }
      }
      rating {
        averageScore
      }
    }
  }
  `;

export const getJobCancellationReasons = gql`
  query getJobCancellationReasons {
    jobCancellationReasons {
      id: value
      value
      label
    }
  }
`;

export const cancelJob = gql`
  mutation cancelJob(
    $reference: String!
    $fees: Int!
    $reason: EJobCancellationReason!
    $comment: String
  ) {
    cancelJob(
      reference: $reference
      fees: $fees
      reason: $reason
      comment: $comment
    ) {
      status
      message
    }
  }
  `;

export const getJobCancellationFees = gql`
  query getJobCancellationFees($reference: String) {
    job(reference: $reference) {
      cancellationFees {
        raw
        formatted
      }
    }
  }
`;

export const rateJob = gql`
  mutation rateJob(
    $reference: String!
    $customerAtHome: Boolean!
    $quality: Int!
    $punctuality: Int
    $friendliness: Int
    $comment: String
  ) {
    rateJob(
      reference: $reference
      customerAtHome: $customerAtHome
      quality: $quality
      punctuality: $punctuality
      friendliness: $friendliness
      comment: $comment
    ) {
      status
      message
    }
  }
  `;

export const rescheduleJob = gql`
  mutation rescheduleJob ($reference: String!)
  {
    rescheduleJob(reference: $reference) {
        status
        message
    }
  }
`;

export const getMinimumPricingForBundles = gql`
  query getMinimumPricingForBundles {
    lowestPricePerHour {
      formatted
      raw
    }
  }
`;

export const getBasePricing = gql`
  query getBasePricing {
    basePrice {
      formatted
      raw
    }
  }
`;
