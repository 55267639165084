import React, { Component } from 'react';
import { isFeatureAvailable, FeaturesEnum } from '../features';
import { WarningModal } from '../components/WarningModal';

export class WarningModalTrigger extends Component {
  state = { visible: false };

  open = () => {
    this.setState({ visible: true });
  };

  close = () => {
    this.setState({ visible: false });
  };

  render() {
    const isEnabled = isFeatureAvailable(FeaturesEnum.DISABLE_BOOKING_BUTTONS);
    const { component: C, triggerProp = 'onPress', ...rest } = this.props;
    if (!isEnabled) {
      return <C {...rest} />;
    }

    const { visible } = this.state;
    const componentProps = {
      ...rest,
      [triggerProp]: this.open
    };

    return (
      <>
        {visible ? <WarningModal onPress={this.close} /> : null}
        <C {...componentProps} />
      </>
    );
  }
}
