import React, { Component } from 'react';
import { Platform } from 'react-native';
import getDisplayName from '../getDisplayName';

/**
 * Inject navigate, navigateReplace and route props into the wrapped component
 * @param {*} data
 */
export default function withNavigation(data = {}) {
  return WrappedComponent =>
    (class extends Component {
      static displayName = `WithNavigation(${getDisplayName(
        WrappedComponent
      )})`;

      navigate = (route, params = {}) => {
        const { history, navigation } = this.props;
        const compiledRoute = Object.entries(params).reduce(
          (acc, [key, value]) => {
            return acc.replace(`:${key}`, value);
          },
          route
        );

        if (Platform.OS === 'web') {
          history.push(compiledRoute, params);
        } else {
          const routeParams = { routeName: route, params }

          // Initial routes generates the own key, thus it may lead to multiple screens being rendered.
          // Use the key only if the compiled route holds some params
          if (compiledRoute !== route) {
            routeParams.key = compiledRoute;
          }
          navigation.navigate(routeParams);
        }
      };

      navigateReplace = (route, params = {}) => {
        const { history, navigation } = this.props;
        if (Platform.OS === 'web') {
          const compiledRoute = Object.entries(params).reduce(
            (acc, [key, value]) => {
              return acc.replace(`:${key}`, value);
            },
            route
          );
          history.replace(compiledRoute, params);
        } else {
          navigation.replace(route, params);
        }
      };

      navigateBack = () => {
        const { history, navigation } = this.props;
        if (Platform.OS === 'web') {
          history.goBack();
        } else {
          navigation.goBack();
        }
      };

      updateNavigationParams = params => {
        const { history, location, navigation } = this.props;
        if (Platform.OS === 'web') {
          history.replace(location.pathname, params);
        } else {
          navigation.setParams(params);
        }
      };

      render() {
        const params =
          (Platform.OS === 'web'
            ? { ...this.props.match.params, ...this.props.location.state }
            : this.props.navigation.state.params) || {};

        const pathName =
          Platform.OS === 'web'
            ? this.props.match.path
            : this.props.navigation.state.routeName;

        return (
          <WrappedComponent
            navigate={this.navigate}
            navigateReplace={this.navigateReplace}
            navigateBack={this.navigateBack}
            pathName={pathName}
            params={params}
            updateNavigationParams={this.updateNavigationParams}
            {...this.props}
          />
        );
      }
    });
}
