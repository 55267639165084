import React from 'react';
import { isEmpty } from 'lodash';
import { translate } from 'react-i18next';
import { CARD_TEXT_COLOR } from '../../constants/layout';

import Card from '../layout/Card/Card';
import SystemMessage from '../../components/SystemMessage';
import List from '../layout/List';
import ListDivider from '../../components/layout/ListDivider';
import { formatTimespan, formatTitle } from '../../helpers/formatters';
import { navigateToDetails } from '../../helpers/navigation';

const JobList = ({
  jobs,
  label = '',
  emptyMessage = null,
  navigate,
  skipMessage
}) => {
  return isEmpty(jobs) && !skipMessage ? (
    <SystemMessage fullscreen message={emptyMessage} />
  ) : (
    <List>
      {!!label && <ListDivider title={label} />}
      {jobs.map(job => {
        const {
          bookingDetails: { serviceType, day, date }
        } = job;
        const title = `${serviceType}\n${formatTitle(day, date)}`;
        return (
          <Card
            key={job.reference}
            title={title}
            body={`${formatTimespan(
              job.bookingDetails.startTime,
              job.bookingDetails.endTime
            )}, ${job.bookingDetails.formattedFrequency}`}
            onPress={() =>
              !!job.reference &&
              navigateToDetails(
                navigate,
                job.reference,
                formatTitle(job.bookingDetails.day, job.bookingDetails.date),
                formatTimespan(
                  job.bookingDetails.startTime,
                  job.bookingDetails.endTime
                )
              )
            }
            displayChevron={!!job.reference}
            titleSize={14}
            icon={{
              name: 'event',
              iconSet: 'MaterialIcons',
              iconSize: 20,
              iconColor: CARD_TEXT_COLOR
            }}
            buttons={false}
            bottomMargin={false}
          />
        );
      })}
    </List>
  );
};

export default translate()(JobList);
