import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import { Image } from 'react-native';
import List from '../components/layout/List';
import ListItem from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import { getEnvironmentVariable, setRegion } from '../constants/environment';
import { setAppLanguage } from '../helpers/i18n';
import { reload } from '../helpers/system';
import withNavigation from '../helpers/withNavigation';

class ChangeRegion extends Component {
  handleRegionPress = async id => {
    await setRegion(id);
    setAppLanguage(getEnvironmentVariable('REACT_APP_LANGUAGE'));
    reload();
  };

  render() {
    const { t } = this.props;

    return (
      <ScrollView>
        <List margin={false}>
          <ListItem
            icon={
              <Image
                style={{ width: 35, height: 26 }}
                source={require('../assets/images/countries/ae.png')}
              />
            }
            title={t('regions.ae')}
            onPress={() => this.handleRegionPress('ae')}
          />
          <ListItem
            icon={
              <Image
                style={{ width: 35, height: 26 }}
                source={require('../assets/images/countries/sa.png')}
              />
            }
            title={t('regions.sa')}
            onPress={() => this.handleRegionPress('sa')}
          />
        </List>
      </ScrollView>
    );
  }
}

export default compose(
  translate(),
  withNavigation()
)(ChangeRegion);
