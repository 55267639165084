import creditcardutils from 'creditcardutils';
import { bool, object, string } from 'yup';
import i18n from '../helpers/i18n';
import { getSalutations } from './customer';

// Matches extended latin characters, spaces, dashes and apostrophes
const NAME_PATTERN = /^[a-zA-Z\s\-'\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/;
// Matches UAE phone numbers starting with the country code or 05
const PHONE_PATTERN = /^((\+|00\s?)971(\s|-)?(\(0\))?|0)(\s|-)?5\d{1}(\s|-)?\d{3}(\s|-)?\d{4}$/;

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const getUpdateProfileSchema = ({
  namePattern = NAME_PATTERN,
  mobilePattern = PHONE_PATTERN
} = {}) => {
  const REQUIRED_MESSAGE = i18n.t('ui.form.validation.required');
  const INVALID_CHOICE_MESSAGE = i18n.t('ui.form.validation.choice');
  const INVALID_PHONE_NUMBER_FORMAT = i18n.t('ui.form.validation.phoneNumber');
  const INVALID_NAME_FORMAT = i18n.t('ui.form.validation.nameFormat');

  return object().shape({
    salutation: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .oneOf(Object.keys(getSalutations()), INVALID_CHOICE_MESSAGE),
    firstName: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(namePattern), INVALID_NAME_FORMAT),
    lastName: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(namePattern), INVALID_NAME_FORMAT),
    building: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    apartment: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    area: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    city: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    description: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    havePets: bool().required(REQUIRED_MESSAGE),
    mobileNumber: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(mobilePattern), INVALID_PHONE_NUMBER_FORMAT),
    smsNotification: bool().required(REQUIRED_MESSAGE)
  });
};

export const getUpdatePaymentCCSchema = () => {
  const REQUIRED_MESSAGE = i18n.t('ui.form.validation.required');
  const INVALID_CC_NUMBER_FORMAT = i18n.t('ui.form.validation.ccNumber');
  const INVALID_CC_EXPIRY_FORMAT = i18n.t('ui.form.validation.ccExpiry');

  return object().shape({
    holderName: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    cardNumber: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .test('is-cc-number', INVALID_CC_NUMBER_FORMAT, value =>
        creditcardutils.validateCardNumber(value)
      ),
    expires: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .test('is-cc-exp', INVALID_CC_EXPIRY_FORMAT, value => {
        const { month, year } = creditcardutils.parseCardExpiry(value);
        return creditcardutils.validateCardExpiry(month, year);
      })
  });
};

export const getUpdatePasswordSchema = () => {
  const REQUIRED_MESSAGE = i18n.t('ui.form.validation.required');
  const PASSWORD_MUST_MATCH = i18n.t('ui.form.validation.passwordsMatch');
  const INVALID_PASSWORD_LENGTH = i18n.t('ui.form.validation.passwordLength');

  return object().shape({
    oldPassword: string()
      .ensure()
      .required(REQUIRED_MESSAGE),
    newPassword: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .min(6, INVALID_PASSWORD_LENGTH),
    confirmPassword: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .test('confirm-password', PASSWORD_MUST_MATCH, function(value) {
        const { newPassword } = this.parent;
        return value === newPassword;
      })
  });
};

export const saveContactSchema = () => {
  const REQUIRED_MESSAGE = i18n.t('ui.form.validation.required');
  const INVALID_CHOICE_MESSAGE = i18n.t('ui.form.validation.choice');
  const INVALID_NAME_FORMAT = i18n.t('ui.form.validation.nameFormat');
  const INVALID_EMAIL = i18n.t('ui.form.validation.email');

  return object().shape({
    salutation: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .oneOf(Object.keys(getSalutations()), INVALID_CHOICE_MESSAGE),
    firstName: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(NAME_PATTERN), INVALID_NAME_FORMAT),
    lastName: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(NAME_PATTERN), INVALID_NAME_FORMAT),
    email: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
      .matches(new RegExp(EMAIL_PATTERN), INVALID_EMAIL),
    phone: string()
      .ensure()
      .required(REQUIRED_MESSAGE)
  });
};
