import React from 'react';
import { translate } from 'react-i18next';
import {
  COLOR_PRIMARY, COLOR_WHITE
} from '../../../constants/layout';
import Card from '../../layout/Card/Card';

const PushNotificationCard = ({
  onPress,
  notification,
  t
}) => {
  const { data } = notification;
  return (
    <Card
      introText=""
      title={data.title}
      body={data.body}
      action={data.action}
      titleSize={16}
      icon={{
        name: 'notifications-none',
        iconSet: 'MaterialIcons',
        iconSize: 50,
        iconColor: COLOR_WHITE,
        iconBubbleColor: COLOR_PRIMARY
      }}
      buttons={false}
      cardStyle={false}
      onPress={onPress}
      displayChevron={false}
    />
  );
};

export default translate()(PushNotificationCard);
