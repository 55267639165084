import sum from 'lodash/sum';
import isArray from 'lodash/isArray';

const MIN_LEVEL = 3;
const MIN_AVERAGE = 4;

export const isGreatRating = (arr) => {
  if (!isArray(arr)) {
    throw new Error(
      `'arr' argument must be an array, "${arr}" (${typeof arr}) is given`
    );
  }

  if (!arr.every(a => typeof a === 'number' && !isNaN(a))) {
    throw new Error(
      `'arr' Array, "${arr}" (${typeof arr}) is given`
    );
  }

  if (arr.length === 0) {
    return false;
  }

  if (arr.some(e => e < MIN_LEVEL)) {
    return false;
  }

  const average = sum(arr) / arr.length;
  return average >= MIN_AVERAGE;
};
