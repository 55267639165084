
import { COLOR_WHITE } from '../constants/layout';
import { View } from 'react-native';
import styled from 'styled-components/native';

export const createCard = (cmp, defaults = {}) => styled(cmp)`
margin: ${defaults.margin || '16px'};
box-shadow: 0 1px 8.5px rgba(0, 0, 0, .1);
border-radius: 4px;
padding: ${defaults.padding || '12px 16px'};
background-color: ${COLOR_WHITE};
elevation: 4
`;

export const SimpleCard = createCard(View)
