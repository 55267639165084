import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import ErrorHandler from '../components/ErrorHandler';
import Button from '../components/Button';
import {
  ListItemContent,
  ListItemForButton
} from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import { STATES } from '../constants/jobs';
import { PAYMENT_METHOD_TYPE } from '../constants/paymentMethod'
import { COLOR_WHITE, COLOR_GRAY, COLOR_PRIMARY } from '../constants/layout';
import {
  formatFullAddress,
  formatTimespan,
  formatTitle
} from '../helpers/formatters';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import withNavigation from '../helpers/withNavigation';
import DetailsCard from '../components/layout/Card/DetailsCard';
import Card from '../components/layout/Card/Card';
import DatePicker from '../components/DatePicker';
import setupForm from '../components/form/MutatingForm';
import Icon from '../components/Icon';
import { getJob } from '../graphql/jobs.js';
import { bookAgain, newBookingTotal } from '../graphql/book.js';
import { Query, withApollo } from 'react-apollo';
import { openFunnel } from '../helpers/navigation';

const styles = StyleSheet.create({
  linkWrapper: {
    marginBottom: 20,
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    flexGrow: 0,
    fontSize: 16,
    textAlign: 'center',
    color: COLOR_PRIMARY,
    fontWeight: '500'
  },
  linkIcon: {
    flexShrink: 0,
    marginLeft: 5,
    width: 24
  }
});

const { MutatingForm } = setupForm();

const getPaymentMethodDetails = (payment, t) => {
  switch (payment.method) {
    case PAYMENT_METHOD_TYPE.CASH:
      return t('quickbook.details.payment.cash');
    case PAYMENT_METHOD_TYPE.CREDIT_CARD:
      return `${t('quickbook.details.payment.creditCard')} **** **** **** **** ${payment.last4Digits}`;
    default:
      return '-'
  }
}

class QuickBook extends Component {
  state = {
    date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    price: null,
    success: false
  };

  componentDidUpdate() {
    const {
      params: { reference, title = null, subtitle = null },
      pathName,
      navigateReplace,
      job
    } = this.props;
    if ((!title || !subtitle) && job) {
      const {
        bookingDetails: {
          startTime = null,
          endTime = null,
          day = null,
          date = null
        } = {}
      } = job;

      navigateReplace(pathName, {
        reference,
        title: formatTitle(day, date),
        subtitle: formatTimespan(startTime, endTime)
      });
    }
  }

  changeDate = (id, date, time) => {
    this.setState({ ...this.state, date: new Date(date) });
    this.getPricingInfo(id, new Date(date), time);
  };

  getPricingInfo(id, date, time) {
    const { client } = this.props;
    const newDate = this.getStringDate(date);

    client.query({
      query: newBookingTotal,
      fetchPolicy: 'network-only',
      variables: {
        date: newDate,
        time: time,
        reference: id
      }
    }).then(response => {
      this.setState({
        ...this.state,
        price: response.data.newBookingTotal.grossAmount.formatted
      });
    });
  }

  getStringDate(date) {
    return `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  }

  getStringTime(date) {
    let dateDetails = date.split('+').slice(0, 1);
      dateDetails = dateDetails[0].replace('T', ' ').split(' ')[1].split(':');
    let { 0: hours, 1: minutes } = dateDetails;

    return `${hours}:${minutes}`;
  }

  renderJob = ({ data: { job }, refetch }) => {
    const {
      date: displayDate,
      success: bookingSuccess,
      details: jobDetails,
      price
    } = this.state;
    const { t, navigate } = this.props;
    const {
      id,
      cleaner,
      state,
      bookingDetails: {
        day,
        cleaningMaterial,
        duration,
        formattedFrequency,
        ironing,
        start,
        startTime
      },
      payment,
      additionalInfo: { keyAnswer, keyPickUp, specialInstructions },
      address: { building, apartment, city, area, description }
    } = job;

    const formattedAddress =
      !!job && formatFullAddress(building, apartment, city, area);
    const jobIsCancelled = [STATES.CANCELED, STATES.CANCELED_WITH_FEE].includes(
      state
    );
    const time = this.getStringTime(start);

    let details = [];
    jobIsCancelled &&
      details.push({
        title: t('jobDetails.cleaningDetails.status'),
        content: t('jobDetails.cancelledTitle'),
        hideable: false
      });

    !jobIsCancelled &&
      details.push({
        title: t('quickbook.details.duration'),
        content: duration + ', ' + t('jobDetails.frequencyTitle', {
          frequency: formattedFrequency
        }),
        hideable: false
      });

    details.push(
      {
        title: t('quickbook.newbooking.startDate'),
        content: `${day}, ${startTime}`
      },
      {
        title: t('jobDetails.cleaningDetails.ironing'),
        content: ironing || t('ui.none')
      },
      {
        title: t('jobDetails.cleaningDetails.materials'),
        content: cleaningMaterial ? t('ui.boolean.yes') : t('ui.none'),
        hideable: false
      },
      {
        title: t('jobDetails.cleaningDetails.access'),
        content: keyAnswer ? `${keyPickUp}\n${keyAnswer}` : keyPickUp,
        hideable: false
      },
      {
        title: t('jobDetails.cleaningDetails.cleaner'),
        content: cleaner || t('jobDetails.cleanerNotAssigned'),
        hideable: false
      }
    );
    const addressDetails = [{
      title: t('jobDetails.cleaningDetails.address'),
      content: formattedAddress
    }];

    const paymentDetails = [
      {
        title: t('quickbook.details.payment.method'),
        content: getPaymentMethodDetails(payment, t)
      }
    ];

    price && details.push(
      {
        title: t('quickbook.newbooking.total'),
        content: price,
        hideable: false
      }
    );

    description &&
      details.push({
        title: t('jobDetails.cleaningDetails.description'),
        content: description,
        hideable: true
      });

    specialInstructions &&
      details.push({
        title: t('jobDetails.cleaningDetails.instructions'),
        content: specialInstructions,
        hideable: true
      });

    let newJob = [];
    if (jobDetails) {
      const time = `${jobDetails.start} - ${jobDetails.end}`;

      newJob.push({
        icon: (
          <Icon
            name="calendar-check"
            set="MaterialCommunityIcons"
            color={COLOR_GRAY}
            size={16}
            flipRTL
          />
        ),
        content: jobDetails.date
      });
      newJob.push({
        icon: (
          <Icon
            name="clock-outline"
            set="MaterialCommunityIcons"
            color={COLOR_GRAY}
            size={16}
            flipRTL
          />
        ),
        content: time
      });
      newJob.push({
        icon: <Icon name="credit-card" color={COLOR_GRAY} size={16} flipRTL />,
        content: `${t('quickbook.newbooking.total')}: ${jobDetails.amount}`
      });
    }

    !price &&
    this.getPricingInfo(id, new Date(this.state.date), time);

    return bookingSuccess ? (
      <React.Fragment>
        <ScrollView style={{ backgroundColor: COLOR_WHITE }}>
          <View
            style={{ alignItems: 'center', marginTop: 52, marginBottom: 16 }}
          >
            <Icon
              name="check-outline"
              set="MaterialCommunityIcons"
              color={COLOR_GRAY}
              size={48}
            />
            <Text style={{ fontSize: 18, marginTop: 16 }}>
              {t('quickbook.newbooking.requestSent')}
            </Text>
          </View>
          <DetailsCard
            title={t('quickbook.newbooking.title')}
            titleSize={16}
            marginTop={20}
            body={newJob}
          />

          <ListItemForButton>
            <ListItemContent>
              <Button
                ctaBorder
                rounded
                title={t('quickbook.cta.backToOverview')}
                textColor={COLOR_PRIMARY}
                onPress={() => navigate('/')}
              />
            </ListItemContent>
          </ListItemForButton>
        </ScrollView>
      </React.Fragment>
    ) : (
      <MutatingForm
        mutation={bookAgain}
        model="bookAgain"
        mapFieldsToParameters={async fields => {
          const { date } = this.state;
          const newDate = this.getStringDate(date);

          return {
            date: newDate,
            time: time,
            reference: id
          };
        }}
        initialValues={{
          date: '',
          time: '',
          reference: ''
        }}
        onSuccess={result => {
          const {
            data: {
              bookAgain: {
                bookingDetails: {
                  date,
                  appointmentStart: start,
                  appointmentEnd: end
                },
                total: {
                  grossAmount: { formatted: amount }
                }
              }
            }
          } = result;
          this.setState({
            ...state,
            success: true,
            details: { date, start, end, amount }
          });
        }}
        children={(action, { loading }, { handleSubmit }) => (
          <React.Fragment>
            <ScrollView
              refresh={refetch}
              loading={loading}
              style={{ backgroundColor: COLOR_WHITE }}
            >
              <DetailsCard
                introText={t('dashboard.overview.lastCleaningLabel')}
                titleSize={16}
                icon={null}
                body={details}
                marginTop={20}
                expandable={details.some(d => d.hideable)}
              />
              <DetailsCard
                icon={null}
                body={addressDetails}
                marginTop={20}
              />
              <DetailsCard
                icon={null}
                body={paymentDetails}
                marginTop={20}
              />
              <Card
                children={
                  <DatePicker
                    value={displayDate}
                    className="datepicker"
                    maxDate={
                      new Date(
                        new Date().getTime() + 60 * 60 * 24 * 2 * 30 * 1000
                      )
                    }
                    minDate={
                      new Date(new Date().getTime() + 60 * 60 * 24 * 1000)
                    }
                    onChange={date => this.changeDate(id, date, time)}
                    flipRTL
                  />
                }
                introText={t('quickbook.startDate.title') + ': '}
                titleSize={16}
                marginTop={30}
              />
              <ListItemForButton>
                <ListItemContent>
                  <Button
                    cta
                    rounded
                    title={t('quickbook.cta.title')}
                    loading={!!loading}
                    disabled={!!loading}
                    onPress={() => handleSubmit()}
                  />
                </ListItemContent>
              </ListItemForButton>
              <TouchableOpacity
                onPress={() => openFunnel()}
                style={styles.linkWrapper}
              >
                <Text style={styles.link}>
                  {t('quickbook.details.changeBookingDetails')}
                </Text>
                <Icon
                  flipRTL
                  style={styles.linkIcon}
                  size={16}
                  color={COLOR_PRIMARY}
                  set="CustomIcons"
                  name="right-arrow"
                />
              </TouchableOpacity>
            </ScrollView>
          </React.Fragment>
        )}
      />
    );
  };

  render() {
    const {
      params: { reference }
    } = this.props;

    return (
      <Query
        query={getJob}
        variables={{ reference }}
        fetchPolicy="cache-and-network"
        children={props => {
          const { data: { job } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!job}
              children={this.renderJob}
            />
          );
        }}
      />
    );
  }
}

QuickBook.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  navigateBack: PropTypes.func,
  navigateReplace: PropTypes.func,
  onProfileButtonClick: PropTypes.func,
  params: PropTypes.object,
  pathName: PropTypes.string,
  t: PropTypes.func
};

export default compose(
  withNavigation(),
  translate(),
  withApollo
)(QuickBook);
