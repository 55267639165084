import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import styled from 'styled-components/native';
import { I18nManager, Platform } from 'react-native';
import { ScrollView } from '../components/layout/ScrollView';
import {
  COLOR_GRAY,
  COLOR_PRIMARY,
  CARD_TEXT_COLOR,
  CARD_CHEVRON_COLOR,
  COLOR_WHITE
} from '../constants/layout';
import USPs from '../components/bundles/USPs';
import ChoiceGroup from '../components/layout/ChoiceGroup';
import Icon from '../components/Icon';
import withNavigation from '../helpers/withNavigation';
import RichCard from '../components/layout/Card/RichCard';
import cardStyles from '../components/layout/Card/CardStyles';
import { getBundles } from '../graphql/bundles.js';
import { withApollo } from 'react-apollo';
import Button from '../components/Button';
import { getEnvironmentVariable } from '../constants/environment';
import { GA_CAMPAIGN_USP } from '../constants/analytics';
import { navigateToPublicBundle, navigateToPrivateBundle, openWebpage } from '../helpers/navigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import * as events from '../constants/analyticsEvents';

class Bundles extends Component {
  state = {
    plans: [],
    success: false,
    index: 0,
    lowestPrice: null,
    replaced: false
  }
  ga = new GoogleAnalytics();

  constructor(props) {
    super(props);
    this.getPlans();
  }

  getPlans() {
    const { client } = this.props;

    client.query({
      query: getBundles,
      fetchPolicy: 'network-only'
    })
    .then(response => {
      if (response.data.cleaningBundles && response.data.cleaningBundles.length) {
        const { data: { cleaningBundles: bundles } } = response;

        let lowestPrice = 1000000;
        let lowestPriceFormatted = null
        for (let i in bundles) {
          if (lowestPrice > bundles[i].price.raw) {
            lowestPrice = bundles[i].price.raw
            lowestPriceFormatted = bundles[i].price.formatted
          }
        }

        this.setState({
          ...this.state,
          plans: bundles,
          lowestPrice: lowestPriceFormatted
        })
      } else {
        this.navigateToFunnel();
      }
    }).catch(error => {
      this.navigateToFunnel();
    });
  }

  navigateToFunnel() {
    openWebpage(
      `${getEnvironmentVariable(
        'REACT_APP_WEB_URL'
      )}/checkout/details?` + GA_CAMPAIGN_USP,
      true
    )
  }

  render() {
    const { t, navigate, pathName } = this.props;
    const { index, plans } = this.state;

    return (
      <React.Fragment>
      <ScrollView style={{ backgroundColor: COLOR_WHITE }}>
        <Title>{t('bundles.page.title')}</Title>
        {/**
          !!lowestPrice &&
          <Subtitle>{t('bundles.page.subtitle')}<StartingPrice>{lowestPrice}</StartingPrice></Subtitle>
        **/}
        <ChoiceGroup
          choices={[
            {
              label: t('bundles.choices.bundles'),
              subtitle: t('bundles.choices.bundles.subtitle')
            }, {
              label: t('bundles.choices.regular')
            }
          ]}
          index={index}
          onChange={(e, i) => {
            this.ga.recordEvent('Customer App', !i ? events.BUNDLES_TOGGLE_BUNDLES : events.BUNDLES_TOGGLE_REGULAR);
            this.setState({ ...this.state, index: i })
          }}
        />

        {!index && <React.Fragment>
          <USPs
            options={[
              t('bundles.usps.first'),
              t('bundles.usps.second'),
              t('bundles.usps.third')
            ]}
            outterStyle={{ marginBottom: 10 }}
          />

          {plans.map((plan, i) =>
            <RichCard
              writingDirection={I18nManager.isRTL
              ? 'rtl'
              : 'ltr'}
              key={i}
              onPress={() => {
                this.ga.recordEvent('Customer App', events.BUNDLES_SELECT_BUNDLE + ' ' + plan.name);
                if (pathName === '/plans') {
                  navigateToPrivateBundle(navigate, i, plan.name, plan);
                } else {
                  navigateToPublicBundle(navigate, i, plan.name, plan);
                }
              }}
              style={i === plans.length - 1 ? { marginBottom: 50 } : null }
              title={Platform.OS === 'ios'
                  ? <CardTitle
                    writingDirection={I18nManager.isRTL
                    ? 'rtl'
                    : 'ltr'}
                  >{plan.name}</CardTitle>
                  : <CardTitle>{plan.name}</CardTitle>}
              content={
                  <React.Fragment>
                    <CardText>
                      {t('bundles.card.startingPrice')}&nbsp;
                      <CardTextPrice>{plan.price.formatted}</CardTextPrice>
                    </CardText>
                    <CardText>
                      {t('bundles.card.period').replace('%d', plan.nbOfWeeks)}
                    </CardText>
                    <CardText>
                      {t('bundles.card.cleanings', { cleanings: plan.nbAppointmentsPerWeek })}
                    </CardText>
                    <CardText>
                      {plan.nbHoursPerAppointment} {t('bundles.card.hours')}
                    </CardText>
                    <Bottom />
                  </React.Fragment>
              }
              chevron={
                <Icon
                  name="chevron-right"
                  set='MaterialCommunityIcons'
                  size={28}
                  flipRTL
                  color={CARD_CHEVRON_COLOR}
                  style={cardStyles.chevronMiddle}
                />
              }
            />
          )}
        </React.Fragment>
        }

        {index === 1 &&
          <React.Fragment>
            <USPs
              title={t('bundles.usps.regular.title')}
              options={[
                t('bundles.usps.regular.first'),
                t('bundles.usps.regular.second'),
                t('bundles.usps.regular.third')
              ]}
              outterStyle={{ marginTop: 45, marginBottom: 49 }}
            />
            <Button
              cta
              bold
              justrounded
              title={t('bundles.cta')}
              onPress={() => {
                this.ga.recordEvent('Customer App', events.BUNDLES_BOOK_REGULAR);
                this.navigateToFunnel();
              }}
              style={{ margin: 22 }}
            />
          </React.Fragment>
        }
      </ScrollView>
      </React.Fragment>
    );
  }
}

Bundles.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  navigateBack: PropTypes.func,
  navigateReplace: PropTypes.func,
  onProfileButtonClick: PropTypes.func,
  params: PropTypes.object,
  pathName: PropTypes.string,
  t: PropTypes.func
};

export default compose(
  withApollo,
  withNavigation(),
  translate()
)(Bundles);

const Title = styled.Text`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  color: ${COLOR_GRAY};
  text-align: center;
  margin-top: 50px;
`;

/*
const Subtitle = styled.Text`
  font-size: 13px;
  font-weight: normal;
  line-height: 22px;
  color: ${COLOR_SUBTITLE};
  text-align: center;
`;

const StartingPrice = styled.Text`
  font-size: 21px;
  font-weight: bold;
  line-height: 22px;
  color: ${COLOR_PRIMARY};
  text-align: center;
`;
*/

const CardTitle = styled.Text`
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  color: ${CARD_TEXT_COLOR};
  margin-top: 10;
  margin-bottom: 10;
  text-align: left;
`;

const CardText = styled.Text`
  font-size: 15px;
  font-weight: 300;
  color: ${CARD_TEXT_COLOR};
  margin: 2px 0px;
  text-align: left;
`;

const CardTextPrice = styled.Text`
  font-size: 15px;
  font-weight: bold;
  color: ${COLOR_PRIMARY};
  margin: 0;
`;

const Bottom = styled.View`
  height: 10px;
`;
