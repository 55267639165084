import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { flowRight as compose, get } from 'lodash';
import { translate } from 'react-i18next';
import { Alert, Platform } from 'react-native';
import ErrorHandler from '../components/ErrorHandler';
import Button from '../components/Button';
import {
  ListItemContent,
  ListItemForButtonSlim
} from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import { FREQUENCIES, STATES, RESCHEDULING_STATES } from '../constants/jobs';
import { COLOR_WHITE, COLOR_ACTION, COLOR_PRIMARY } from '../constants/layout';
import { getJob } from '../graphql/jobs.js';
import {
  formatFullAddress,
  formatTimespan,
  formatTitle
} from '../helpers/formatters';
import {
  openWebpage,
  navigateToRating,
  navigateToBook,
  navigateToRescheduleBook
} from '../helpers/navigation';
import withNavigation from '../helpers/withNavigation';
import DetailsCard from '../components/layout/Card/DetailsCard';
import { getEnvironmentVariable } from '../constants/environment';
import { GA_CAMPAIGN_USP } from '../constants/analytics';
import Icon from '../components/Icon';

import { WarningModalTrigger } from '../components/WarningModalTrigger';

class JobDetails extends Component {
  componentDidUpdate() {
    const {
      params: { reference, title = null, subtitle = null },
      pathName,
      navigateReplace,
      job
    } = this.props;
    if ((!title || !subtitle) && job) {
      const {
        bookingDetails: {
          startTime = null,
          endTime = null,
          day = null,
          date = null
        } = {}
      } = job;

      navigateReplace(pathName, {
        reference,
        title: formatTitle(day, date),
        subtitle: formatTimespan(startTime, endTime)
      });
    }
  }

  askForConfirmation = function(title) {
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      if (Platform.OS === 'web') {
        const result = window.confirm(title);
        result ? resolve('OK') : reject('NO');
      } else {
        Alert.alert(
          '',
          title,
          [
            {
              text: t('ui.cancel'),
              onPress: () => reject('NO'),
              style: 'cancel'
            },
            { text: t('ui.ok'), onPress: () => resolve('OK') }
          ],
          { cancelable: false, onDismiss: () => reject('DISMISS') }
        );
      }
    });
  };

  handleJobCancellation = async job => {
    try {
      const { navigate, t } = this.props;

      const {
        reference,
        cancellationFees: { raw: rawFee, formatted: formattedFee },
        bookingDetails: { dayShort, dateShort, startTime, endTime }
      } = job;

      if (
        !rawFee ||
        (await this.askForConfirmation(
          t('jobDetails.cancellationWarning', { fee: formattedFee })
        )) === 'OK'
      ) {
        navigate(`/cancel/:reference`, {
          reference,
          fees: rawFee,
          job: reference,
          subtitle: `${formatTitle(dayShort, dateShort)}: ${formatTimespan(
            startTime,
            endTime
          )}`
        });
      }
    } catch (e) {
    }
  };

  handleOrderCancellation = async job => {
    try {
      const { navigate, t } = this.props;

      const {
        reference: jobReference,
        bookingDetails: { day, startTime, endTime },
        order: {
          reference: orderReference,
          cancellationFees: { raw: rawFee, formatted: formattedFee }
        }
      } = job;

      if (
        !rawFee ||
        (await this.askForConfirmation(
          t('jobDetails.cancellationWarning', { fee: formattedFee })
        )) === 'OK'
      ) {
        navigate(`/cancelOrder/:reference`, {
          reference: orderReference,
          fees: rawFee,
          job: jobReference,
          subtitle: `${day}, ${formatTimespan(startTime, endTime)}`
        });
      }
    } catch (e) {

    }
  };

  renderJob = ({ data: { job }, refetch, loading }) => {
    const { t, navigate } = this.props;
    const {
      id,
      cleaner,
      state,
      receipt,
      bookingDetails: {
        cleaningMaterial,
        duration,
        frequency,
        formattedFrequency,
        ironing,
        startTime,
        endTime,
        day,
        date,
        serviceType
      },
      additionalInfo: { keyAnswer, keyPickUp, specialInstructions },
      address: { building, apartment, city, area, description },
      total: { voucherCode },
      rating
    } = job;

    const discountRaw = get(job, 'total.discountAmount.raw');
    const discountAmount = get(job, 'total.discountAmount.formatted');
    const grossRaw = get(job, 'total.grossAmount.raw');
    const grossAmount = get(job, 'total.grossAmount.formatted');
    const itemRaw = get(job, 'total.itemAmount.raw');
    const itemAmount = get(job, 'total.itemAmount.formatted');
    const vatRaw = get(job, 'total.vatAmount.raw');
    const vatAmount = get(job, 'total.vatAmount.formatted');
    const averageScore = rating ? rating.averageScore : false;
    const formattedAddress =
      !!job && formatFullAddress(building, apartment, city, area);
    const jobIsCancelled = [STATES.CANCELED, STATES.CANCELED_WITH_FEE].includes(
      state
    );
    const jobIsDone = state === STATES.DONE;
    const showModifyBlock = !jobIsCancelled && !jobIsDone;
    const showRatingButton = jobIsDone && !averageScore;
    const jobIsRescheduling = job.reschedulingState === RESCHEDULING_STATES.RESCHEDULING_REQUESTED;
    const jobIsReschedulable = job.reschedulingState === RESCHEDULING_STATES.RESCHEDULABLE

    let details = [];

    jobIsCancelled &&
      details.push({
        title: t('jobDetails.cleaningDetails.status'),
        content: t('jobDetails.cancelledTitle')
      });

    details.push({
      title: t('jobDetails.cleaningDetails.serviceType'),
      content: serviceType
    });

    !jobIsCancelled &&
      details.push({
        title: t('jobDetails.cleaningDetails.frequency'),
        content: t('jobDetails.frequencyTitle', {
          frequency: formattedFrequency
        })
      });

    details.push({
      title: t('jobDetails.cleaningDetails.cleaner'),
      content: cleaner || t('jobDetails.cleanerNotAssigned')
    });

    showRatingButton &&
      details.push({
        title: t('jobDetails.cleaningDetails.rating'),
        content: averageScore
      });

    details.push(
      {
        title: t('jobDetails.cleaningDetails.duration'),
        content: duration ? `${duration}, ${formattedFrequency}` : '–'
      },
      {
        title: t('jobDetails.cleaningDetails.address'),
        content: formattedAddress
      }
    );

    description &&
      details.push({
        title: t('jobDetails.cleaningDetails.description'),
        content: description
      });

    details.push(
      {
        title: t('jobDetails.cleaningDetails.ironing'),
        content: ironing || t('ui.none')
      },
      {
        title: t('jobDetails.cleaningDetails.materials'),
        content: cleaningMaterial ? t('ui.boolean.yes') : t('ui.none')
      },
      {
        title: t('jobDetails.cleaningDetails.access'),
        content: keyAnswer ? `${keyPickUp}\n${keyAnswer}` : keyPickUp
      }
    );
    specialInstructions &&
      details.push({
        title: t('jobDetails.cleaningDetails.instructions'),
        content: specialInstructions
      });

    let paymentDetails = [];
    !!itemRaw &&
      !!discountRaw &&
      paymentDetails.push({
        title: t('jobDetails.priceTitle'),
        content: itemAmount
      });

    !!discountRaw &&
      paymentDetails.push({
        title: t('jobDetails.discountTitle'),
        content: `${discountAmount}\n${voucherCode}`
      });

    !!grossRaw &&
      paymentDetails.push({
        title: t('jobDetails.finalPriceTitle'),
        content: grossAmount
      });

    !vatRaw &&
      paymentDetails.push({
        title: t('jobDetails.vatTitle'),
        content: vatAmount
      });

    paymentDetails.push({
      title: t('jobDetails.invoiceTitle'),
      content: receipt
        ? t('jobDetails.invoiceViewNow')
        : t('jobDetails.invoiceNotAvailable'),
      onPress: receipt ? () => openWebpage(receipt, true) : null
    });

    return (
      <ScrollView
        refresh={refetch}
        loading={loading}
        style={{ backgroundColor: COLOR_WHITE }}
      >
        <DetailsCard
          title={t('jobDetails.cleaningDetails.cleaningDetails')}
          titleSize={16}
          icon={null}
          body={details}
          marginTop={20}
        />
        <DetailsCard
          title={t('jobDetails.cleaningDetails.paymentDetails')}
          titleSize={16}
          icon={null}
          body={paymentDetails}
        />

        {jobIsReschedulable && !jobIsRescheduling && (
          <ListItemForButtonSlim>
            <ListItemContent>
              <Button
                cta
                rounded
                title={t('jobDetails.cta.reschedule')}
                onPress={() => navigateToRescheduleBook(navigate, job.id)}
                icon={
                  <Icon
                    name='calendar-edit'
                    set='MaterialCommunityIcons'
                    color={COLOR_WHITE}
                  />
                }
              />
            </ListItemContent>
          </ListItemForButtonSlim>
        )}

        {jobIsRescheduling && (
          <ListItemForButtonSlim>
            <ListItemContent>
              <Button
                cta
                rounded
                title={t('jobDetails.cta.rescheduleInProgress')}
                disabledGreyedOut
                icon={
                  <Icon
                    name='calendar-edit'
                    set='MaterialCommunityIcons'
                    color={COLOR_WHITE}
                  />
                }
              />
            </ListItemContent>
          </ListItemForButtonSlim>
        )}

        {showModifyBlock && !jobIsRescheduling && (
          <ListItemForButtonSlim>
            <ListItemContent>
              <Button
                ctaAlert
                rounded
                title={t('jobDetails.cancelAppointmentTitle')}
                onPress={() => this.handleJobCancellation(job)}
              />
            </ListItemContent>
          </ListItemForButtonSlim>
        )}

        {showModifyBlock && !jobIsRescheduling &&
          frequency !== FREQUENCIES.ONCE && (
            <ListItemForButtonSlim>
              <ListItemContent>
                <Button
                  ctaAlertBorder
                  textColor={COLOR_ACTION}
                  rounded
                  title={t('jobDetails.cancelBookingTitle')}
                  onPress={() => this.handleOrderCancellation(job)}
                />
              </ListItemContent>
            </ListItemForButtonSlim>
        )}

        {showRatingButton && (
          <ListItemForButtonSlim>
            <ListItemContent>
              <Button
                ctaBorder
                textColor={COLOR_PRIMARY}
                rounded
                title={t('rating.title')}
                onPress={() =>
                  navigateToRating(
                    navigate,
                    id,
                    formatTitle(day, date),
                    formatTimespan(startTime, endTime)
                  )
                }
              />
            </ListItemContent>
          </ListItemForButtonSlim>
        )}

        {frequency === FREQUENCIES.ONCE &&
          jobIsDone && (
            <ListItemForButtonSlim>
              <ListItemContent>
                <WarningModalTrigger
                  component={Button}
                  cta
                  rounded
                  textColor={COLOR_WHITE}
                  noborder
                  title={t('dashboard.lastCleaning.bookCleaning')}
                  onPress={() =>
                    navigateToBook(
                      navigate,
                      id,
                      t('quickbook.newbooking.mainTitle')
                    )
                  }
                />
              </ListItemContent>
            </ListItemForButtonSlim>
          )}

        {frequency === FREQUENCIES.ONCE &&
          jobIsDone && (
            <ListItemForButtonSlim>
              <ListItemContent>
                <Button
                  textColor={COLOR_PRIMARY}
                  empty
                  title={t('quickbook.ctachange.title')}
                  onPress={() =>
                    openWebpage(
                      `${getEnvironmentVariable(
                        'REACT_APP_WEB_URL'
                      )}/checkout/details?` + GA_CAMPAIGN_USP,
                      true
                    )
                  }
                />
              </ListItemContent>
            </ListItemForButtonSlim>
          )}
      </ScrollView>
    );
  };

  render() {
    const {
      params: { reference }
    } = this.props;

    return (
      <Query
        query={getJob}
        variables={{ reference }}
        fetchPolicy="network-only"
        children={props => {
          const { data: { job } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!job}
              children={this.renderJob}
            />
          );
        }}
      />
    );
  }
}

JobDetails.propTypes = {
  job: PropTypes.object,
  navigateReplace: PropTypes.func,
  navigate: PropTypes.func,
  params: PropTypes.object,
  pathName: PropTypes.string,
  t: PropTypes.func
}

export default compose(
  withNavigation(),
  translate()
)(JobDetails);
