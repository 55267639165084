import PropTypes from 'prop-types';
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_BACKGROUND_LIGHT_GREEN, COLOR_PRIMARY, COLOR_WHITE } from '../../../constants/layout';
import CTAButton from '../../CTAButton';
import i18n from '../../../helpers/i18n';
import { openWebpage } from '../../../helpers/navigation';

const CtaContainer = styled(View)`
  margin: 12px 0 14px 0;
  border-bottom-color: ${COLOR_BACKGROUND_LIGHT_GREEN};
  border-bottom-width: 1px;
  padding-bottom: 24px;
  padding-top: 24px;
`

const CtaButton = styled(CTAButton)`
  border-radius: 30px;
`

const CtaLink = ({ children, onPress, href }) => {
  function visitHref() {
    onPress()
    if (href) {
      openWebpage(href);
    }
  }
  return (
    <CtaContainer>
      <CtaButton onPress={visitHref} color={COLOR_WHITE} textColor={COLOR_PRIMARY} title={i18n.t('sidebar.link.request_new_booking')}>
        {children}
      </CtaButton>
    </CtaContainer>
  )
}

CtaLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onPress: PropTypes.func.isRequired
}

export { CtaLink as BaseCtaLink }
export default CtaLink;
