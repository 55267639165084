import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import setupClient from '../helpers/setupClient';

class StateManager extends Component {
  state = {
    client: null
  };

  async componentDidMount() {
    const client = await setupClient();
    this.setState({ client });
  }

  render() {
    const { client } = this.state;
    return client && <ApolloProvider client={client} {...this.props} />;
  }
}

export default StateManager;
