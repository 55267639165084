import gql from 'graphql-tag';

export const bookAgain = gql`
  mutation bookAgain($date: String!, $time: String!, $reference: String!) {
    bookAgain(date: $date, time: $time, reference: $reference) {
      status
      message
      bookingDetails {
        date: appointmentStart(format: DATE)
        appointmentEnd(format: TIME)
        appointmentStart(format: TIME)
        cleaningMaterial
        duration(unit: HOUR)
        frequency(translate: true)
        ironing(unit: HOUR)
      }
      __typename
      bookingDetails {
        date: appointmentStart(format: DATE)
        appointmentEnd(format: TIME)
        appointmentStart(format: TIME)
        cleaningMaterial
        duration(unit: HOUR)
        frequency(translate: true)
        ironing(unit: HOUR)
      }
      total {
        grossAmount {
          formatted
        }
      }
    }
  }`
;

export const newBookingTotal = gql`
  query newBookingTotal($reference: String!, $date: String!, $time: String!) {
    newBookingTotal(reference: $reference, date: $date, time: $time) {
      itemAmount{formatted}
      vatAmount{formatted}
      grossAmount{formatted}
    }
  }`
;
