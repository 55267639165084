import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import { getRegion } from '../constants/environment';
import CheckoutPayment from '../components/CheckoutPayment';
import AdyenPayment from '../components/AdyenPayment';

class UpdatePayment extends Component {
  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('updatePayment');
  }

  render() {
    const selectedRegion = getRegion();
    if (selectedRegion === 'ae') {
      return (<AdyenPayment />);
    } else {
      return (<CheckoutPayment />);
    }
  }
}

export default compose(
  withNavigation(),
  translate()
)(UpdatePayment);
