import creditcardutils from 'creditcardutils';
import React, { Component } from 'react';
import { get, flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import Fab from '../components/Fab';
import setupForm from '../components/form/MutatingForm';
import Icon from '../components/Icon';
import List from '../components/layout/List';
import { ScrollView } from '../components/layout/ScrollView';
import AdyenEncryptor from '../components/AdyenEncryptor';
import { getAdyenEncryptionKey } from '../constants/encryption';
import { COLOR_TEXT_SECONDARY, COLOR_WHITE } from '../constants/layout';
import { getUpdatePaymentCCSchema } from '../constants/validations';
import { addCreditCard } from '../graphql/wallet.js';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';

const { MutatingForm, FormItem } = setupForm();

class AdyenPayment extends Component {
  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('updatePayment');
  }

  render() {
    const { navigate, t } = this.props;
    return (
      <MutatingForm
        mutation={addCreditCard}
        model="addCreditCard"
        mapFieldsToParameters={async fields => {
          const data = mapFieldsToAdyen(fields);
          const encryptedData = await this._encryptor.encrypt(data);
          return {
            encrypted: encryptedData,
            generationTime: data.generationtime,
            truncated: fields.cardNumber.replace(' ', '').slice(-4),
            ckoCardToken: ''
          };
        }}
        initialValues={{
          holderName: '',
          cardNumber: '',
          expires: ''
        }}
        validationSchema={getUpdatePaymentCCSchema()}
        onSuccess={result => {
          navigate('/payments', {
            flashMessage: get(result, 'data.addCreditCard.message')
          });
        }}
        children={(action, { loading }, { handleSubmit }) => (
          <React.Fragment>
            <ScrollView>
              <List>
                <FormItem
                  label={t('updatePayment.holderTitle')}
                  name="holderName"
                  icon={
                    <Icon color={COLOR_TEXT_SECONDARY} name="credit-card" />
                  }
                  inputProps={{ }}
                />
                <FormItem
                  label={t('updatePayment.cardNumberTitle')}
                  name="cardNumber"
                  format="cc-number"
                  inputProps={{
                    autoComplete: 'cc-number',
                    keyboardType: 'numeric'
                  }}
                />
                <FormItem
                  label={t('updatePayment.expiryTitle')}
                  name="expires"
                  placeholder="MM / YYYY"
                  format="cc-exp"
                  inputProps={{
                    autoComplete: 'cc-exp',
                    keyboardType: 'numeric'
                  }}
                />
              </List>
            </ScrollView>
            <Fab
              disabled={loading}
              onPress={handleSubmit}
              icon={<Icon name="save" color={COLOR_WHITE} />}
            />
            <AdyenEncryptor
              ref={ref => (this._encryptor = ref)}
              encryptionKey={getAdyenEncryptionKey()}
            />
          </React.Fragment>
        )}
      />
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(AdyenPayment);

const mapFieldsToAdyen = fields => {
  const { month, year } = creditcardutils.parseCardExpiry(fields.expires);

  return {
    number: fields.cardNumber,
    holderName: fields.holderName,
    expiryMonth: month.toString(),
    expiryYear: year.toString(),
    generationtime: new Date().toISOString()
  };
};
