import styled from 'styled-components/native';
import { COLOR_WHITE } from '../../constants/layout';

const Paper = styled.View`
  border-radius: 2px;
  background-color: ${COLOR_WHITE};
  padding: 10px;
  ${props =>
    props.elevation ? `box-shadow: 0 ${props.elevation}px 10px rgba(0, 0, 0, ${props.elevation * 0.1})` : ''};
  ${props => (props.rounded ? `border-radius: 5px` : '')};
`

export default Paper;
