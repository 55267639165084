import React, { Component } from 'react';
import { flowRight as compose, get } from 'lodash';
import { translate } from 'react-i18next';
import Fab from '../components/Fab';
import setupForm from '../components/form/MutatingForm';
import Icon from '../components/Icon';
import List from '../components/layout/List';
import { ScrollView } from '../components/layout/ScrollView';
import { COLOR_TEXT_SECONDARY, COLOR_WHITE } from '../constants/layout';
import { getUpdatePasswordSchema } from '../constants/validations';
import { updatePassword } from '../graphql/customer.js';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics'

const { MutatingForm, FormItem } = setupForm();

class UpdatePassword extends Component {
  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('updatePassword');
  }

  render() {
    const { navigate, t } = this.props;
    return (
      <React.Fragment>
        <MutatingForm
          mutation={updatePassword}
          model="updatePassword"
          onSuccess={props =>
            navigate('/profile', {
              flashMessage: get(props, 'data.updatePassword.message')
            })
          }
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={getUpdatePasswordSchema()}
          children={(action, { loading }, { handleSubmit }) => (
            <React.Fragment>
              <ScrollView>
                <List>
                  <FormItem
                    label={t('updatePassword.oldPasswordTitle')}
                    name="oldPassword"
                    icon={<Icon color={COLOR_TEXT_SECONDARY} name="lock" />}
                    inputProps={{
                      secureTextEntry: true
                    }}
                  />
                  <FormItem
                    label={t('updatePassword.newPasswordTitle')}
                    name="newPassword"
                    inputProps={{
                      secureTextEntry: true
                    }}
                  />
                  <FormItem
                    label={t('updatePassword.repeatPasswordTitle')}
                    name="confirmPassword"
                    inputProps={{
                      secureTextEntry: true
                    }}
                  />
                </List>
              </ScrollView>
              <Fab
                onPress={handleSubmit}
                icon={<Icon name="save" color={COLOR_WHITE} />}
                disabled={loading}
              />
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(UpdatePassword);
