import React from 'react';
import { ProgressBar as NativeProgressBar } from 'react-native';
import Fade from './Fade';

export default function ProgressBar({ loading = true, ...props }) {
  return (
    <Fade visible={loading} {...props}>
      <NativeProgressBar indeterminate={true} color="#FFFFFF44" />
    </Fade>
  );
}
