import { encode } from 'base-64';
import {
  getMultipleFromStorage,
  saveMultipleToStorage,
  removeMultiFromStorage
} from '../storage';

import parseUrl from 'url-parse';
import { makeAuthenticationUrl } from '../../constants/auth';
import { getEnvironmentVariable } from '../../constants/environment';
import Sentry from '../sentry';
import { navigate } from '../navigation';
import { generateUniqueMessageId } from '../helpers'
import { logRequestResult } from '../logger';

const uniqueMessageId = generateUniqueMessageId();
const localKeys = {
  accessToken: 'AUTH_ACCESS_TOKEN',
  refreshToken: 'AUTH_REFRESH_TOKEN',
  refreshTime: 'AUTH_REFRESH_TIME',
  expiresIn: 'AUTH_ACCESS_EXPIRES_IN'
};

export async function checkAndRefreshAuthToken(operation) {
  const tokenData = await getLocalTokens();

  if (tokenData.accessToken) {
    if (Date.now() - tokenData.refreshTime > tokenData.expiresIn * 1000) {
      // Token is timed out, perform a token refresh
      try {
        await logRequestResult(
          'CART: Token expired, Generating new access token',
          {}, { error: false });

        const {
          access_token: newAccessToken,
          refresh_token: newRefreshToken,
          expires_in: expiresIn
        } = await fetchAccessTokens(tokenData.refreshToken, true);
        setAuthTokens(newAccessToken, newRefreshToken, expiresIn);

        await logRequestResult(
          'CART: Token expired, Generated new access tokens',
          {}, { error: false });

        return {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          expiresIn: expiresIn
        }
      } catch (e) {
        await logRequestResult(
            'CART: Token expired, Exception while generating new access token',
            { message: e.message, details: e }, { error: true });
        Sentry.setTags({
          'uid': uniqueMessageId
        });
        Sentry.captureException(e);
        navigate('/logout', { error: true });
        return false;
      }
    } else {
      return tokenData;
    }
  } else {
    return {};
  }
}

export const makeAuthorizeUrl = (
  redirectUri = getEnvironmentVariable('REACT_APP_AUTH_REDIRECT_URI')
) => {
  const authorizeUrl = getEnvironmentVariable('REACT_APP_AUTH_AUTHORIZE_URL');
  const clientId = getEnvironmentVariable('REACT_APP_AUTH_CLIENT_ID');

  return `${authorizeUrl}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}`;
};

export async function fetchAccessTokens(code = null, refresh = false) {
  const action = refresh ? 'refresh' : 'fetch';
  let apiUrl = '';

  try {
    const accessTokenUrl = getEnvironmentVariable(
      'REACT_APP_AUTH_ACCESS_TOKEN_URL'
    );
    const url = parseUrl(accessTokenUrl);

    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Uid': uniqueMessageId
    };

    if (url.auth) {
      headers['Authorization'] = `Basic ${encode(url.auth)}`;
      apiUrl = accessTokenUrl.replace(`${url.auth}@`, '');
    } else {
      apiUrl = accessTokenUrl;
    }

    const response = await fetch(apiUrl, {
      body: JSON.stringify({
        client_id: getEnvironmentVariable('REACT_APP_AUTH_CLIENT_ID'),
        grant_type: refresh ? 'refresh_token' : 'authorization_code',
        redirect_uri: makeAuthenticationUrl(),
        [refresh ? 'refresh_token' : 'code']: code
      }),
      cache: 'no-cache',
      method: 'POST',
      headers
    });
    const data = await response.json();

    await logRequestResult('fetchAccessTokens', {
      grant_type: refresh ? 'refresh_token' : 'authorization_code',
      [refresh ? 'refresh_token' : 'code']: code
      }, data);

    if (!response.ok) {
      if (typeof data.error.hint !== 'undefined') {
        Sentry.setTags({ errorHint: data.error.hint });
      }

      if (typeof data.error.code !== 'undefined') {
        Sentry.setTags({ errorCode: data.error.code });
      }

      if (data.error && data.error === 'invalid_request') {
        throw new Error(
          `${action}Token Request Invalid Error: ${data.message}`
        );
      }

      throw new Error(`${action}Token Response Error: ${response.status}`);
    }

    if (!data || !data.access_token || !data.refresh_token) {
      throw new Error(`${action}Token Token Missing Error`);
    }
    return data;
  } catch (e) {
    Sentry.setTags({
      apiUrl: apiUrl,
      uid: uniqueMessageId,
      client_id: getEnvironmentVariable('REACT_APP_AUTH_CLIENT_ID'),
      grant_type: refresh ? 'refresh_token' : 'authorization_code',
      redirect_uri: makeAuthenticationUrl(),
      [refresh ? 'refresh_token' : 'code']: code
    });

    await logRequestResult('fetchAccessTokens: Exception', { message: e.message, details: e }, { error: true });

    // Rethrowing, doesn't need sentry tracking
    if (e instanceof TypeError) {
      throw new Error(`${action}Token Network Error`);
    }
    throw e;
  }
}

export async function getLocalTokens() {
  const tokens = await getMultipleFromStorage([
    localKeys.accessToken,
    localKeys.refreshToken,
    localKeys.refreshTime,
    localKeys.expiresIn
  ]);
  const tokenMap = new Map(tokens);

  return {
    accessToken: tokenMap.get('AUTH_ACCESS_TOKEN'),
    refreshToken: tokenMap.get('AUTH_REFRESH_TOKEN'),
    refreshTime: parseInt(tokenMap.get('AUTH_REFRESH_TIME'), 10),
    expiresIn: parseInt(tokenMap.get('AUTH_ACCESS_EXPIRES_IN'), 10)
  };
}

export async function setAuthTokens(accessToken, refreshToken, expiresIn) {
  return saveMultipleToStorage([
    [localKeys.accessToken, accessToken],
    [localKeys.refreshToken, refreshToken],
    [localKeys.refreshTime, Date.now().toString()],
    [localKeys.expiresIn, expiresIn ? expiresIn.toString() : '60']
  ]);
}

export function resetAuthTokens() {
  return removeMultiFromStorage([
    'AUTH_ACCESS_TOKEN',
    'AUTH_REFRESH_TOKEN',
    'AUTH_REFRESH_TIME',
    'AUTH_ACCESS_EXPIRES_IN'
  ]);
}
