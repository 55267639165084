import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import { Text } from 'react-native';
import Button from '../components/Button';
import ErrorHandler from '../components/ErrorHandler';
import Fab from '../components/Fab';
import Icon from '../components/Icon';
import List from '../components/layout/List';
import ListItem, {
  ListItemContent,
  ListItemEl,
  ListItemIcon
} from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import { getSalutations } from '../constants/customer';
import { COLOR_TEXT_SECONDARY } from '../constants/layout';
import { getCustomer } from '../graphql/customer.js';
import { formatFullAddress } from '../helpers/formatters';
import withNavigation from '../helpers/withNavigation';

import GoogleAnalytics from '../helpers/analytics/googleAnalytics'

class Profile extends Component {
  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('profile');
  }

  renderProfile = ({ data: { customer } = {}, refetch, loading }) => {
    const { navigate, t } = this.props;

    const {
      email,
      name: { firstName, lastName, salutation },
      address: { building, apartment, city, area, description },
      additionalInfo,
      mobileNumber,
      smsNotifications
    } = customer;

    const pets = additionalInfo ? !!additionalInfo.pets : false;
    const salutations = getSalutations();

    const formattedAddress = formatFullAddress(building, apartment, city, area);

    return (
      <React.Fragment>
        <ScrollView refresh={refetch} loading={loading}>
          <List margin={false}>
            <ListItem
              title={t('profile.emailTitle')}
              subtitle={email}
              icon={<Icon color={COLOR_TEXT_SECONDARY} name="person" />}
            />
            <ListItem
              title={t('profile.nameTitle')}
              subtitle={`${salutations[salutation]} ${firstName} ${lastName}`}
              indent
            />

            <ListItem
              title={t('profile.addressTitle')}
              subtitle={formattedAddress}
              icon={<Icon color={COLOR_TEXT_SECONDARY} name="business" />}
            />
            <ListItem
              title={t('profile.descriptionTitle')}
              subtitle={description}
              indent
            />
            <ListItem
              title={t('profile.petsTitle')}
              subtitle={pets ? t('ui.boolean.yes') : t('ui.boolean.no')}
              indent
            />

            <ListItem
              title={t('profile.phoneTitle')}
              subtitle={mobileNumber}
              icon={<Icon color={COLOR_TEXT_SECONDARY} name="notifications" />}
            />
            <ListItem
              title={t('profile.smsTitle')}
              subtitle={
                smsNotifications ? t('ui.boolean.yes') : t('ui.boolean.no')
              }
              indent
            />
            <ListItemEl>
              <ListItemIcon />
              <ListItemContent dense>
                <Button
                  primary
                  justrounded
                  title={t('payments.shortTitle')}
                  onPress={() => navigate('/payments')}
                />
              </ListItemContent>
            </ListItemEl>

            <ListItemEl>
              <ListItemIcon />
              <ListItemContent dense>
                <Button
                  primary
                  justrounded
                  title={t('profile.updatePasswordTitle')}
                  onPress={() => navigate('/profile/password')}
                />
              </ListItemContent>
            </ListItemEl>

            <ListItemEl>
              <ListItemIcon />
              <ListItemContent dense>
                <Button
                  primary
                  justrounded
                  title={t('profile.logoutTitle')}
                  onPress={() => navigate('/logout')}
                />
              </ListItemContent>
            </ListItemEl>
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
              <ListItemEl>
                <ListItemIcon />
                <ListItemContent dense>
                  <Text
                    style={{ fontSize: 10, color: COLOR_TEXT_SECONDARY }}
                  >{`${process.env.REACT_APP_REVISION} // ${
                    process.env.REACT_APP_ENVIRONMENT
                  }`}</Text>
                </ListItemContent>
              </ListItemEl>
            )}
          </List>
        </ScrollView>
        <Fab onPress={() => navigate('/profile/update')} />
      </React.Fragment>
    );
  };

  render() {
    return (
      <Query query={getCustomer} fetchPolicy="cache-and-network">
        {props => {
          const { data: { customer } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!customer}
              children={this.renderProfile}
            />
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(Profile);
