import React, { Component } from 'react';
import { Platform, StatusBar } from 'react-native';
import styled from 'styled-components/native';
import Header from '../components/Header';
import Notification from '../components/Notification';
import { COLOR_BACKGROUND, COLOR_PRIMARY } from '../constants/layout';
import getDisplayName from '../helpers/getDisplayName';
import { NetworkStatusNotifier } from '../helpers/networkStatus';
import { getMatchedRoute } from '../helpers/routing';
import withNavigation from '../helpers/withNavigation';

class Screen extends Component {
  render() {
    const {
      children,
      params,
      navigateReplace,
      navigateBack,
      updateNavigationParams,
      pathName,
      toggleDrawer,
      showHeader = Platform.OS === 'web'
    } = this.props;

    const { flashMessage } = params;
    const { parent: parentRoute, tab, showLogo, title, subtitle, showBackButton, system, publicRoute } = getMatchedRoute(
      pathName
    );

    const displayTitle = params.title ? params.title : title;
    const displaySubtitle = params.subtitle ? params.subtitle : subtitle;
    return (
      <ScreenContainer>
        <NetworkStatusNotifier
          render={({ loading }) => (
            <React.Fragment>
              <StatusBar
                backgroundColor={COLOR_PRIMARY}
                barStyle="light-content"
                networkActivityIndicatorVisible={loading}
              />
              {showHeader && (
                <Header
                  title={displayTitle}
                  subtitle={displaySubtitle}
                  showBackButton={showBackButton}
                  showMenuButton={true}
                  loading={loading}
                  onBackButtonClick={() =>
                    parentRoute ? navigateReplace(parentRoute) : navigateBack()
                  }
                  tab={tab}
                  showLogo={showLogo}
                  publicRoute={publicRoute}
                  system={system}
                  onMenuButtonClick={() => toggleDrawer()}
                />
              )}
            </React.Fragment>
          )}
        />
        {children}
        <Notification
          open={!!flashMessage}
          message={flashMessage}
          onClose={() =>
            updateNavigationParams({ ...params, flashMessage: null })
          }
        />
      </ScreenContainer>
    );
  }
}

const EnhancedScreen = withNavigation()(Screen);

export default EnhancedScreen;

export function withScreen(screenProps) {
  return WrappedComponent =>
    (class extends Component {
      static displayName = `WithScreen(${getDisplayName(WrappedComponent)})`;

      render() {
        return (
          <EnhancedScreen {...this.props}>
            <WrappedComponent {...this.props} {...screenProps}/>
          </EnhancedScreen>
        );
      }
    });
}

const ScreenContainer = styled.View`
  flex: 1;
  background-color: ${COLOR_BACKGROUND};
`;
