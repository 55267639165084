import { AppRegistry } from 'react-native';
import App from './App';
import { getEnvironmentVariable, setRegion } from './constants/environment';
import { setAppLanguage } from './helpers/i18n';
import Sentry from './helpers/sentry';

async function setup() {
  await setRegion(process.env.REACT_APP_WEB_REGION);
  setAppLanguage(getEnvironmentVariable('REACT_APP_LANGUAGE'));

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    Sentry.init({
      dsn: 'https://4992c382d77246eb8ae4e7c328b5cd0c@sentry.io/665736',
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_REVISION
    });
  }

  // register the app
  AppRegistry.registerComponent('App', () => App);

  AppRegistry.runApplication('App', {
    initialProps: {},
    rootTag: document.getElementById('root'),
    callback: () => {
      document.getElementById('splashscreen').style.opacity = '0';
    }
  });
}

setup();
