import PropTypes from 'prop-types';
import React from 'react';
import { Link } from './link';

class InternalLink extends React.Component {
  get isActive() {
    const { href, pathName } = this.props;
    if (pathName && pathName.length) {
      const consideredPathname = pathName[pathName.length - 1] === '/' ? pathName.slice(0, -1) : pathName
      return consideredPathname === href
    } else {
      return true;
    }
  }

  render() {
    const { children, icon } = this.props
    return (
      <Link active={this.isActive} onPress={this.visitHref} icon={icon}>
        {children}
      </Link>
    )
  }

  visitHref = () => {
    const { onPress, href, navigate, replace, navigateReplace } = this.props
    onPress()

    if (replace) {
      navigateReplace(href)
    } else {
      navigate(href)
    }
  }
}

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
  navigate: PropTypes.func,
  replace: PropTypes.bool,
  pathName: PropTypes.string,
  icon: PropTypes.string
}

export default InternalLink;
