import React, { Component } from 'react';
import { flowRight as compose, get } from 'lodash';
import { translate } from 'react-i18next';
import CheckoutJS from '../components/CheckoutJS';
import Fab from '../components/Fab';
import setupForm from '../components/form/MutatingForm';
import Icon from '../components/Icon';
import { COLOR_WHITE } from '../constants/layout';
import { addCreditCard } from '../graphql/wallet.js';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import { StyleSheet } from 'react-native';

const { MutatingForm } = setupForm();

class CheckoutPayment extends Component {
  constructor(props) {
    super(props);
    this.postMessage = this.postMessage.bind(this);
    this.state = { enableSubmit: false, cardData: { token: '', last4: '' } };
  }

  postMessage(data) {
    if (data.action === 'validation' && data.enableSubmit) {
      this.setState({ ...this.state, enableSubmit: true });
    }

    if (data.action === 'tokenised') {
      this.setState({ enableSubmit: true, cardData: { token: data.token, last4: data.last4 } });
    }
  }

  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('updatePayment');
  }

  render() {
    const { navigate } = this.props;
    let enableSubmit = this.state.enableSubmit;
    let cardData = this.state.cardData;

    return (
      <MutatingForm
        mutation={addCreditCard}
        model="addCreditCard"
        mapFieldsToParameters={async fields => {
          let ckoCardToken = '';
          let last4 = '';
          if (window && window.Frames) {
            const txData = await window.Frames.submitCard()
            .then(function (data) {
                return data;
            });
            ckoCardToken = txData.cardToken;
            last4 = txData.card.last4;
          } else if (!cardData.token.length) {
            const data = await this._checkout.getTxData();
            const txData = JSON.parse(data);
            ckoCardToken = txData.token;
            last4 = txData.last4;
          } else {
            return false;
          }

          return {
            encrypted: '',
            generationTime: new Date().toISOString(),
            truncated: last4,
            ckoCardToken: ckoCardToken
          };
        }}
        initialValues={{
          holderName: '',
          cardNumber: '',
          expires: ''
        }}
        onError={(error) => {
          if (typeof this._checkout.reload === 'function') {
            this._checkout.reload();
          } else {
            navigate('/payments');
            navigate('/payments/update', {
              flashMessage: error,
              reload: true
            });
          }
        }}
        onSuccess={result => {
          navigate('/payments', {
            flashMessage: get(result, 'data.addCreditCard.message')
          });
        }}
        children={(action, { loading }, { handleSubmit }) => (
          <React.Fragment>
              <CheckoutJS
                postMessage={this.postMessage} style={styles.form}
                ref={ref => (this._checkout = ref)}
              />
              <Fab
                class='pay-now-button'
                disabled={!enableSubmit}
                onPress={handleSubmit}
                icon={<Icon name="save" color={COLOR_WHITE} />}
              />
          </React.Fragment>
        )}
      />
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(CheckoutPayment);

const styles = StyleSheet.create({
  form: {
   marginTop: 100,
   padding: 20
  }
});
