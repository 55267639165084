import React from 'react';
import { StatusBar } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_PRIMARY } from '../../constants/layout';

const ToolbarContainer = styled.View`
  min-height: 56px;
  flex-direction: row;
  align-items: center;
  z-index: 1;
`;

const ToolbarIconContainer = styled.View`
  min-width: 56px;
  padding: 12px 0;
`;

const ToolbarTitleContainer = styled.View`
  flex: 1;
  padding: 0 16px;
`;

const ToolbarTitleText = styled.Text`
  text-align: left;
`;

function Toolbar({
  title,
  leftIcon = null,
  rightIcon = null,
  loading = false,
  backgroundColor = COLOR_PRIMARY
}) {
  return (
    <ToolbarContainer style={{ backgroundColor }}>
      <StatusBar
        backgroundColor={backgroundColor}
        barStyle="light-content"
        networkActivityIndicatorVisible={loading}
      />

      {!!leftIcon && (
        <ToolbarIconContainer style={{ paddingLeft: 16 }}>
          {leftIcon}
        </ToolbarIconContainer>
      )}

      {!!title && (
        <ToolbarTitleContainer>
          {typeof title === 'string' ? (
            <ToolbarTitleText>{title}</ToolbarTitleText>
          ) : (
            title
          )}
        </ToolbarTitleContainer>
      )}

      {!!rightIcon && (
        <ToolbarIconContainer
          style={{ paddingRight: 16, alignItems: 'flex-end' }}
        >
          {rightIcon}
        </ToolbarIconContainer>
      )}
    </ToolbarContainer>
  );
}

export default Toolbar;
