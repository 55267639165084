import React from 'react';
import { translate } from 'react-i18next';
import { COLOR_PRIMARY, COLOR_RATING } from '../../../constants/layout';
import {
  navigateToRating,
  navigateToBook
 } from '../../../helpers/navigation';
import Card from '../../layout/Card/Card';
import {
  formatTime,
  formatTimespan,
  formatTitle
} from '../../../helpers/formatters';
import { FREQUENCIES } from '../../../constants/jobs';
import { WarningModalTrigger } from '../../WarningModalTrigger';

const LastCleaning = ({
  id,
  onPress,
  frequency,
  frequencyLabel,
  day,
  date,
  startTime,
  endTime,
  start,
  end,
  serviceType,
  displayRatingButton,
  navigate,
  t
}) => {
  const duration =
    new Date(new Date(end) - new Date(start)).getTime() / 3600000;
  const buttons = [];
  if (displayRatingButton) {
    buttons.push({
      iconName: 'rate-it',
      iconSet: 'CustomIcons',
      iconSize: 24,
      iconColor: COLOR_RATING,
      text: t('dashboard.lastCleaning.rateCleaning'),
      onPress: () =>
        navigateToRating(
          navigate,
          id,
          formatTitle(day, date),
          formatTimespan(startTime, endTime)
        )
    });
  }

  if (frequency === FREQUENCIES.ONCE) {
    buttons.push({
      renderComponent: WarningModalTrigger,
      iconName: 'calendar-plus',
      iconSet: 'MaterialCommunityIcons',
      iconSize: 24,
      iconColor: COLOR_PRIMARY,
      text: t('dashboard.lastCleaning.bookCleaning'),
      onPress: () =>
        navigateToBook(navigate, id, t('quickbook.newbooking.mainTitle'))
    });
  }

  return (
    <Card
      introText={t('dashboard.overview.lastCleaningLabel') + ':'}
      title={`${serviceType}\n${duration} hours, ${frequencyLabel}`}
      body={day + ', ' + formatTime(startTime)}
      displayChevron={false}
      titleSize={14}
      icon={null}
      buttons={buttons}
      cardStyle={false}
      onPress={onPress}
      disabled={!onPress}
    />
  );
};

export default translate()(LastCleaning);
