import { AsyncStorage } from 'react-native';

const pathNamekey = 'LOGIN_REDIRECT_PATHNAME';

export const getRedirectAfterLoginPathname = async () => {
  const result = await AsyncStorage.getItem(pathNamekey);
  return result || '/';
};

export const setRedirectAfterLoginPathname = async value => {
  if (value) {
    return AsyncStorage.setItem(pathNamekey, value);
  } else {
    return AsyncStorage.removeItem(pathNamekey);
  }
};
