import React, { Component } from 'react';
import { getCheckoutPublicKey } from '../constants/encryption';
import { StyleSheet, View } from 'react-native';

export default class CheckoutJS extends Component {
  encrypt(data) {
    if (this._encryptor) {
      return this._encryptor.encrypt(data);
    } else {
      throw new Error('Encryptor is not ready');
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.checkout.com/js/frames.js';
    script.async = true;
    script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }

  scriptLoaded() {
    const { postMessage } = this.props;
    let paymentForm = document.getElementById('payment-form');

    window.Frames.init({
      publicKey: getCheckoutPublicKey(),
      containerSelector: '.frames-container',
      cardValidationChanged: function () {
        // if all fields contain valid information, the Pay now
        // button will be enabled and the form can be submitted
        if (window.Frames.isCardValid()) {
          let data = { action: 'validation', enableSubmit: true };
          postMessage(data);
        }
      },
      cardSubmitted: function () {
      },
      cardTokenised: function(event) {
      }
    });
    paymentForm.addEventListener('submit', function (event) {
      event.preventDefault();
      window.Frames.submitCard()
        .then(function (data) {
          window.Frames.addCardToken(paymentForm, data.cardToken);
          paymentForm.submit();
        })
        .catch(function (err) {
          // eslint-disable-next-line
          console.log(err)
        });
    });
  }

  render() {
    return (
      <View style={styles.form}>
        <form id="payment-form" method="POST" action="">
          <div className="frames-container">
          </div>
        </form>
    </View>
    )
  }
}

const styles = StyleSheet.create({
  form: {
   marginTop: 20,
   padding: 20
  }
});
