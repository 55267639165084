import { AsyncStorage } from 'react-native';
export const REGION_KSA = 'sa';
export const REGION_UAE = 'ae';

let selectedRegion = REGION_UAE;

export function getEnvironmentVariable(id, override) {
  const environmentVariables = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    REACT_APP_AUTH_AUTHORIZE_URL: process.env.REACT_APP_AUTH_AUTHORIZE_URL,
    REACT_APP_AUTH_ACCESS_TOKEN_URL:
      process.env.REACT_APP_AUTH_ACCESS_TOKEN_URL,
    REACT_APP_AUTH_REDIRECT_URI: process.env.REACT_APP_AUTH_REDIRECT_URI,
    REACT_APP_AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_WEB_URL: process.env.REACT_APP_WEB_URL,
    REACT_APP_LANGUAGE: process.env.REACT_APP_LANGUAGE,
    REACT_APP_ADYEN_KEY: process.env.REACT_APP_ADYEN_KEY,
    REACT_APP_ANALYTICS_ID: process.env.REACT_APP_ANALYTICS_ID,
    REACT_APP_CHECKOUT_PUBLIC_KEY: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY
  };

  const environmentVariable = environmentVariables[id];
  if (!environmentVariable) {
    return undefined;
  }
  const environmentMap = environmentVariable.toString().split(';');

  return selectedRegion === REGION_UAE ? environmentMap[0] : environmentMap[1];
}

export function setRegion(region) {
  selectedRegion = region;
  return AsyncStorage.setItem('REGION', region);
}

export function getRegion() {
  return selectedRegion;
}

export const isRegion = (region) => selectedRegion === region;
