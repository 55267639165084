import i18n from '../helpers/i18n';

export const SALUTATION_MR = 'MR';
export const SALUTATION_MRS = 'MRS';

export function getSalutations() {
  return {
    [SALUTATION_MR]: i18n.t('customer.salutations.mr'),
    [SALUTATION_MRS]: i18n.t('customer.salutations.mrs')
  };
}
