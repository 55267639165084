import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/native'
import { TouchableOpacity, Platform, Text } from 'react-native';
import Icon from '../../Icon'
import { COLOR_BACKGROUND_LIGHT_GREEN, COLOR_WHITE } from '../../../constants/layout'

const LinkItem = styled(TouchableOpacity)`
  ${Platform.OS === 'web' ? 'cursor: pointer;' : ''}
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
`

const LinkText = styled(({ active, innerRef, ...props }) => <Text {...props} />)`
  font-size: 16px;
  font-weight: 300;
  ${Platform.OS === 'web' ? 'display: block;' : ''}
  position: relative;
  margin-left: 20px;
  color: ${COLOR_WHITE};
`

export function Link({ children, active, onPress, icon }) {
  return (
    <LinkItem activeOpacity={1.0} onPress={onPress}>
      {icon && <Icon set='MaterialCommunityIcons' name={icon} color={COLOR_BACKGROUND_LIGHT_GREEN} />}
      <LinkText active={active}>{children}</LinkText>
    </LinkItem>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.string
}
