import gql from 'graphql-tag';

export const getCustomer = gql`
  query getCustomer {
    customer {
      id: email
      email
      name {
        firstName
        lastName
        salutation
      }
      address {
        city
        area
        building
        apartment
        description
      }
      additionalInfo {
        pets
      }
      mobileNumber
      smsNotifications
    }
  }`;

export const updateProfile = gql`
  mutation updateProfile(
    $apartment: String!
    $area: String!
    $building: String!
    $city: String!
    $description: String
    $firstName: String!
    $havePets: Boolean!
    $lastName: String!
    $mobileNumber: String!
    $salutation: ESalutation
    $smsNotification: Boolean!
  ) {
    updateProfile(
      apartment: $apartment
      area: $area
      building: $building
      city: $city
      description: $description
      firstName: $firstName
      havePets: $havePets
      lastName: $lastName
      mobileNumber: $mobileNumber
      salutation: $salutation
      smsNotification: $smsNotification
    ) {
      status
      message
    }
  }
  `;

export const updatePassword = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
      fieldErrors {
        fieldName
        errorMessage
      }
    }
  }`;
