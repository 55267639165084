import React from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import {
  COLOR_BACKGROUND,
  COLOR_WHITE,
  COLOR_LIST_ITEM_ACTION,
  COLOR_PRIMARY
} from '../../constants/layout';

export const PaymentItemEl = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLOR_WHITE};
`;

export const ListItemContent = styled(({ dense, innerRef, nopadding, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  flex: 1;
  padding: ${props => (props.nopadding ? '0' : (props.dense ? '12' : '16'))}px 16px;
`;

export const ListItemTitle = styled(({ alert, innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  ${params =>
    params.alert &&
    css`
      color: ${COLOR_LIST_ITEM_ACTION};
    `};
    color: #444;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

export const ListItemSubtitle = styled.Text`
  margin-top: 2px;
  font-weight: 300;
  color: ${COLOR_LIST_ITEM_ACTION};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

export const PayItemAmount = styled.Text`
  margin-top: 2px;
  font-weight: 300;
  color: ${COLOR_PRIMARY};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

export const ListItemAction = styled.View`
  display: flex;
  padding: 8px 16px 8px 0;
`;

/**
 * @param {Object} params
 * @param {string=} params.title
 * @param {string=} params.subtitle
 * @param {string=} params.amount
 * @param {ReactElement=} params.action
 * @param {any=} params.onPress
 * @param {Object=} params.style
 * @param {any=} params.children
 */
export default function PaymentItem(params) {
  const {
    title,
    subtitle,
    amount,
    action,
    onPress,
    children,
    ...props
  } = params;

  const Element = children ? (
    <PaymentItemEl {...props} children={children} />
  ) : (
    <PaymentItemEl {...props}>
      <ListItemContent>
        <ListItemTitle>{title}</ListItemTitle>
        {subtitle && <ListItemSubtitle>{subtitle}</ListItemSubtitle>}
        {amount && <PayItemAmount>{amount}</PayItemAmount>}
      </ListItemContent>
      {action && <ListItemAction>{action}</ListItemAction>}
    </PaymentItemEl>
  );

  return onPress ? (
    <TouchableHighlight underlayColor={COLOR_BACKGROUND} onPress={onPress}>
      {Element}
    </TouchableHighlight>
  ) : (
    Element
  );
}
