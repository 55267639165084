import React, { Component } from 'react';
import { Animated, Platform } from 'react-native';

export default class Fade extends Component {
  state = {
    fadeAnim: new Animated.Value(0),
    visible: this.props.visible
  };

  componentDidUpdate(prevProps) {
    const { visible: prevVisible } = prevProps;
    const { visible } = this.props;

    if (prevVisible !== visible) {
      this.setState({ visible: true });
      Animated.timing(this.state.fadeAnim, {
        toValue: visible ? 1 : 0,
        duration: 300,
        useNativeDriver: Platform.OS !== 'web'
      }).start(() => this.setState({ visible }));
    }
  }

  render() {
    const { fadeAnim, visible: elVisible } = this.state;
    const { children, style = {}, visible, ...props } = this.props;

    return (
      <Animated.View
        style={[
          style,
          {
            opacity: fadeAnim
          }
        ]}
        {...props}
      >
        {elVisible && children}
      </Animated.View>
    );
  }
}
