import gql from 'graphql-tag';

export const getBundles = gql`
  query getBundles {
    cleaningBundles {
      name
      nbAppointmentsPerWeek
      nbHoursPerAppointment(unit: NONE)
      nbOfWeeks:period(unit: NONE)
      period(unit: WEEK)
      price {
        formatted
        raw
      }
    }
  }`
;

export const hasActiveBundlebookings = gql`
  query hasActiveBundlebookings {
    atLeastOneActiveBundleBooking
  }
`;

export const createBundleBooking = gql`
  mutation createBundleBooking(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $name: String!
    $price: Int!
    $period: Int!
    $nbAppointmentsPerWeek: Int!
    $nbHoursPerAppointment: Float!
  ) {
    createBundleBooking(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      name: $name
      price: $price
      period: $period
      nbAppointmentsPerWeek: $nbAppointmentsPerWeek
      nbHoursPerAppointment: $nbHoursPerAppointment
    ) {
      status
      message
    }
  }
`;
