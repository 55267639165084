import gql from 'graphql-tag';

export const getOrderCancellationReasons = gql`
  query getOrderCancellationReasons {
    orderCancellationReasons {
      id: value
      value
      label
    }
  }
  `;

export const cancelOrder = gql`
  mutation cancelOrder(
    $reference: String!
    $fees: Int!
    $reason: EOrderCancellationReason!
    $comment: String
  ) {
    cancelOrder(
      reference: $reference
      fees: $fees
      reason: $reason
      comment: $comment
    ) {
      status
      message
    }
  }
  `;
