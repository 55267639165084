import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Notification from './Notification';
import SystemMessage from './SystemMessage';

class ErrorHandler extends Component {
  state = {
    showError: false
  };

  static getDerivedStateFromProps(newProps) {
    if (newProps.error) {
      return {
        showError: true
      };
    }

    return null;
  }

  render() {
    const {
      dataIsAvailable = false,
      error,
      loading,
      refetch,
      persistent = false,
      critical = false,
      children,
      t
    } = this.props;

    const { showError } = this.state;

    let content = null;

    if (!dataIsAvailable) {
      if (error) {
        content = <SystemMessage message={t('ui.error.fetching')} fullscreen />;
      } else if (loading) {
        content = <SystemMessage message={t('ui.loading')} fullscreen />;
      } else {
        content = <SystemMessage message={t('ui.notFound')} fullscreen />;
      }
    } else {
      if (critical && error) {
        content = <SystemMessage message={t('ui.error.fetching')} fullscreen />;
      } else {
        content = children(this.props);
      }
    }

    return (
      <React.Fragment>
        {content}
        <Notification
          open={showError}
          message={t('ui.notification.networkIssues.message')}
          action={t('ui.notification.networkIssues.action')}
          persistent={persistent}
          onAction={async () => {
            if (!loading) {
              await refetch();
              this.setState({ showError: false });
            }
          }}
          onClose={() => this.setState({ showError: false })}
        />
      </React.Fragment>
    );
  }
}

export default translate()(ErrorHandler);
