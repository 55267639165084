import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ErrorHandler from '../ErrorHandler';
import { ScrollView } from '../layout/ScrollView';
import { getFutureJobs } from '../../graphql/jobs.js';
import JobList from './JobList';
import { COLOR_WHITE } from '../../constants/layout';

class FutureJobs extends Component {
  state = {
    info: []
  };

  render = () => {
    const { t, navigate } = this.props;

    return (
      <Query key="future" query={getFutureJobs} fetchPolicy="cache-and-network">
        {props => {
          const { data: { jobs } = {}, loading, refetch } = props;

          return (
            <ErrorHandler {...props} dataIsAvailable={!!jobs}>
              {() => {
                const [...futureJobs] = jobs;

                return (
                  <ScrollView refresh={refetch} loading={loading} style={{ backgroundColor: COLOR_WHITE }}>
                    <JobList
                      jobs={futureJobs}
                      emptyMessage={t('dashboard.upcoming.emptyMessage')}
                      navigate={navigate}
                    />
                  </ScrollView>
                );
              }}
            </ErrorHandler>
          );
        }}
      </Query>
    );
  };
}

FutureJobs.propTypes = {
  navigate: PropTypes.func,
  t: PropTypes.func
}

export default (FutureJobs);
