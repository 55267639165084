import React from 'react';
import styled from 'styled-components/native';
import { COLOR_PRIMARY, SHADOW_STYLE } from '../constants/layout';
import Icon from './Icon';

export default function Fab({
  disabled = false,
  mini = false,
  onPress,
  icon = null,
  color = COLOR_PRIMARY,
  ...props
}) {
  return (
    <FabContainer
      {...props}
      style={[
        props.style,
        mini && { width: 42, height: 42, borderRadius: 21 },
        SHADOW_STYLE,
        { elevation: 8 }
      ]}
    >
      <FabWrapper style={mini && { width: 42, height: 42, borderRadius: 21 }}>
        <FabButton onPress={onPress} disabled={disabled}>
          <FabContent style={{ backgroundColor: color }}>
            {icon || <Icon name="edit" size={mini ? 20 : 24} color="white" />}
          </FabContent>
        </FabButton>
      </FabWrapper>
    </FabContainer>
  );
}

const FabContainer = styled.View`
  position: absolute;
  end: 16px;
  bottom: 16px;
  border-radius: 28px;
`;

const FabWrapper = styled.View`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  overflow: hidden;
`;

const FabContent = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const FabButton = styled.TouchableHighlight`
  flex: 1;
`;

export const FabSpacer = styled.View`
  height: 80px;
`;
