export async function registerForPushNotificationsAsync() {
  return new Promise((resolve) => {
      resolve({ update: false });
  });
}

export async function saveTokenData() {
  return new Promise((resolve) => {
      resolve(true);
  });
}
