import { matchPath } from 'react-router-dom';
import { getRoutes } from '../constants/routing';

export function getMatchedRoute(path) {
  const matchedRoute = Object.entries(getRoutes()).find(([routePath, route]) =>
    matchPath(path, {
      path: routePath,
      exact: route.exact,
      strict: route.strict
    })
  );

  return matchedRoute ? matchedRoute[1] : null;
}
