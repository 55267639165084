import React, { Component } from 'react';
import { Platform } from 'react-native';
import Redirect from '../components/routing/Redirect';
import { getEnvironmentVariable } from '../constants/environment';
import { resetAuthTokens } from '../helpers/auth';
import { clearCache } from '../helpers/cache';
import withNavigation from '../helpers/withNavigation';
import { setRedirectAfterLoginPathname } from '../helpers/navigation/redirectAfterLogin';

function readCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

class Logout extends Component {
  state = { isReady: false }
  async componentDidMount() {
    await resetAuthTokens();
    await clearCache();
    await setRedirectAfterLoginPathname(null);
    if (Platform.OS === 'web') {
      const webUrl = getEnvironmentVariable('REACT_APP_WEB_URL');

      const sessionId = readCookie('PHPSESSID');

      const formData = new FormData();
      formData.append('sid', sessionId);

      await fetch(`${webUrl}/logout`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
    }
    this.setState({ isReady: true })
  }

  render() {
    if (!this.state.isReady) {
      return null;
    }
    const { error } = this.props.params;
    return <Redirect to={{ pathname: '/login', error, disableAuth: true }} />;
  }
}

export default withNavigation()(Logout);
