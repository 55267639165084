import { getEnvironmentVariable } from './environment';

export function getAdyenEncryptionKey() {
  return (
    getEnvironmentVariable('REACT_APP_ADYEN_KEY') ||
    '10001|825D62046E9725F17B4498922882CE15CF6E73D68194C8F212893F8A154EB736BD6DA250E3A5EDB154252EF2CD792AFC08FC4D04FECC7224387A6313218D18ED2765BE556F3343FEE478AFE27EF8A3D45221874734D601B174C7C001E65E12C7D8386A8816E8B509383400CED2B578166058AF1DE6E3D3BB0FF80D25479DF276348C1BCDE2DC191D9529E429439D35E36C883B7F583BB24A964DB0EF822B175CA5F373A96E6B5B2D7324A13A7454DF26A9D1FBBA368A7DABAC80A1614C7D4CA93160524DE502F28A87AEDDCEC92572AF3283D63F998AE3FE9550A480FBD9B3A3E0CFA8CA4A30F7819E861F551D2FC867BBCA6FEA14DA6C0BB549ADEAA4CB9637'
  );
}

export function getCheckoutPublicKey() {
  return (
    getEnvironmentVariable('REACT_APP_CHECKOUT_PUBLIC_KEY') ||
    'pk_test_31884d83-a6ca-4143-b843-44b10bfd6de7'
  );
}
