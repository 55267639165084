import React from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { COLOR_TEXT_SECONDARY } from '../constants/layout';

export default function SystemMessage({ message, fullscreen = false }) {
  return (
    <MessageContainer fullscreen={fullscreen}>
      <MessageElement>{message}</MessageElement>
    </MessageContainer>
  );
}

const MessageContainer = styled(({ innerRef, fullscreen, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  ${({ fullscreen }) =>
    fullscreen &&
    css`
      flex: 1;
      justify-content: center;
    `};
`;

const MessageElement = styled.Text`
  margin: 16px;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR_TEXT_SECONDARY};
  text-align: center;
`;
