import React, { Component } from 'react';
import {
  TouchableOpacity,
  View
} from 'react-native';
import { COLOR_WHITE } from '../../../constants/layout';
import cardStyles from './CardStyles';
import PropTypes from 'prop-types';

class RichCard extends Component {
  render() {
    const {
      title,
      introText,
      content,
      onPress,
      icon,
      chevron,
      style
    } = this.props;

    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={!onPress}
        activeOpacity={0.8}
      >
        <View style={{ backgroundColor: COLOR_WHITE, zIndex: 1, ...style }}>
          {!!introText && introText}
          <View style={cardStyles.container}>
            <View style={cardStyles.card}>
              {
                !!icon && icon
              }
              <View style={cardStyles.content}>
                {
                  !!title && title
                }
                {
                  !!content && content
                }
                {
                  !!chevron && chevron
                }
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

RichCard.propTypes = {
  title: PropTypes.object,
  introText: PropTypes.object,
  content: PropTypes.object,
  icon: PropTypes.object,
  chevron: PropTypes.object,
  style: PropTypes.object,
  onPress: PropTypes.func
};

export default (RichCard);
