import materialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import customIcons from '../assets/icons/font/CustomIcons.ttf';

const FONTS = {
  MaterialIcons: materialIcons,
  MaterialCommunityIcons: materialCommunityIcons,
  CustomIcons: customIcons
};

const style = document.createElement('style');
style.type = 'text/css';

Object.entries(FONTS).forEach(([name, font]) => {
  const iconFontStyles = `@font-face {
    src: url(${font});
    font-family: ${name};
    font-display: block;
  }`;

  if (style.styleSheet) {
    style.styleSheet.cssText += iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }
});

// Inject stylesheet
document.head.appendChild(style);
