import React from 'react';
import { translate } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { SimpleCard } from '../SimpleCard';
import { CleanerAvatar, CleanerAvatarSizeEnum } from './CleanerAvatar';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY
} from '../../constants/layout';

function RatingHeader({ cleaningDate, cleaner, cleaningInfo, t }) {
  return (
    <SimpleCard style={styles.header}>
      <View style={styles.avatarContainer}>
        <CleanerAvatar size={CleanerAvatarSizeEnum.SMALL}/>
        <Text style={styles.cleanerAvatarName}>{cleaner}</Text>
      </View>
      <View style={styles.infoSection}>
        <Text style={styles.cleanerTitle}>
          {t('rating.cleaner')}:
          <Text style={styles.cleanerTitleName}>{cleaner}</Text>
        </Text>
        <Text style={styles.dateTitle}>{cleaningDate}</Text>
        {cleaningInfo.map((text, index) => (
          <Text key={index} style={styles.infoText}>
            {text}
          </Text>
        ))}
      </View>
    </SimpleCard>
  );
}

export default translate()(RatingHeader);

const imageSize = 69;
const borderSize = 2;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  avatarContainer: {
    marginRight: 30,
    maxWidth: imageSize + borderSize * 5,
    marginBottom: 16
  },
  cleanerAvatarName: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: '500',
    textTransform: 'uppercase',
    color: COLOR_PRIMARY
  },
  dateTitle: {
    fontSize: 14,
    color: COLOR_PRIMARY,
    lineHeight: 24
  },
  cleanerTitle: {
    fontSize: 18,
    lineHeight: 24
  },
  cleanerTitleName: {
    fontWeight: '600',
    fontStyle: 'italic',
    marginLeft: 5
  },
  infoSection: {
    flexBasis: '50%',
    flexShrink: 1,
    flexGrow: 1
  },
  infoText: {
    color: COLOR_TEXT_SECONDARY
  }
});
