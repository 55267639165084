import qs from 'qs';
import { openWebpage } from '../navigation';

export * from './shared';

export async function executeAuthRequest(authUrl) {
  openWebpage(authUrl);
}

export function handleAuthRequest() {
  return new Promise((resolve, reject) => {
    const params = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });
    if (params.code) {
      resolve(params.code);
    }
  });
}
