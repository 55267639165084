import { getRegion } from '../constants/environment';
import get from 'lodash/get';

import ae from './config.ae';
import sa from './config.sa';

const configs = { ae, sa };

export const getFeatureValue = feature => {
  const region = getRegion();
  return get(configs, [region, feature]);
};

export const isFeatureAvailable = feature => Boolean(getFeatureValue(feature));
