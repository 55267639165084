import React, { Component } from 'react';
import { Dimensions, I18nManager } from 'react-native';
import {
  dashboardPath,
  profilePath,
  paymentsPath,
  updatePasswordPath,
  logoutPath,
  plansPath
} from '../../helpers/urls';
import withNavigation from '../../helpers/withNavigation';
import { flowRight as compose } from 'lodash';
import i18n from '../../helpers/i18n';
import { getEnvironmentVariable } from '../../constants/environment';
import { GA_CAMPAIGN_USP } from '../../constants/analytics';
import GoogleAnalytics from '../../helpers/analytics/googleAnalytics';
import { slide as Menu } from 'react-burger-menu';
import { COLOR_WHITE, COLOR_PRIMARY } from '../../constants/layout';
import InternalLink from './item/internal_link';
import ExternalLink from './item/external_link';
import CtaLink from './item/cta_link';
import { WarningModalTrigger } from '../WarningModalTrigger';

class Sidebar extends Component {
  state = {
    isOpen: false,
    window: 0
  };

  ga = new GoogleAnalytics();
  track = (action) => {
    this.ga.recordEvent('Customer App', 'Customer navigated to ' + action);
    this.closeDrawer();
  }

  closeDrawer = () => {
    this.setState({ ...this.state, isOpen: false });
  }

  handleStateChange (state) {
    this.setState({ ...this.state, isOpen: state.isOpen });
  }

  funnelURL = getEnvironmentVariable('REACT_APP_WEB_URL') &&
    `${getEnvironmentVariable('REACT_APP_WEB_URL')}/checkout/details?${GA_CAMPAIGN_USP}`;

  rafURL = getEnvironmentVariable('REACT_APP_WEB_URL') &&
  `${getEnvironmentVariable('REACT_APP_WEB_URL')}/refer?${GA_CAMPAIGN_USP}`;

  helpURL = getEnvironmentVariable('REACT_APP_WEB_URL') &&
  `${getEnvironmentVariable('REACT_APP_WEB_URL')}/help?${GA_CAMPAIGN_USP}`;

  burger = (margin, display, rtl) => {
    let position = {
      display: display ? 'block' : 'none',
      position: 'fixed',
      width: '20px',
      height: '20px',
      top: '20px'
    }
    rtl ? position.left = margin : position.right = margin;
    return position;
  };

  updateDimensions() {
    this.setState({
      ...this.state,
      width: window.innerWidth
    });
  }

  componentDidMount() {
    const ww = Math.round(Dimensions.get('window').width);
    this.setState({ ...this.state, width: ww });
    window.addEventListener('resize', () => (this.updateDimensions()));
  }

  render() {
    const { navigate, location } = this.props;
    const { isOpen, width } = this.state;
    const menuWidth = width > 500 ? '320px' : '100%';
    const cr = !!location && location.pathname && location.pathname === '/changeRegion';
    const isAuthDisabled = (!!this.props && !!this.props.params && !!this.props.params.disableAuth) || cr;
    const burgerRight = this.burger(width <= 1170 ? '20px' : (width - 1170) / 2 + 20 + 'px', !isAuthDisabled, I18nManager.isRTL);

    return (
        <Menu
          right
          width={menuWidth}
          styles={ styles(burgerRight, isAuthDisabled) }
          isOpen={ isOpen }
          onStateChange={(state) => this.handleStateChange(state)}
        >
         <InternalLink href={dashboardPath()} onPress={() => (this.track('dashboard'))} icon='calendar-clock' navigate={navigate}>
          {i18n.t('sidebar.link.overview')}
          </InternalLink>

          <InternalLink href={profilePath()} onPress={() => (this.track('profile'))} icon='account-circle' navigate={navigate}>
            {i18n.t('sidebar.link.myprofile')}
          </InternalLink>

          <InternalLink
            href={paymentsPath()}
            onPress={() => (this.track('payments'))}
            icon='file-download-outline'
            navigate={navigate}
          >
            {i18n.t('sidebar.link.payments')}
          </InternalLink>

          <InternalLink href={updatePasswordPath()} onPress={() => (this.track('update password'))} icon='account-key' navigate={navigate}>
            {i18n.t('sidebar.link.updatePassword')}
          </InternalLink>

          <WarningModalTrigger
            component={CtaLink}
            onPress={() => {
              navigate(plansPath);
              this.track('funnel');
            }}
            navigate={navigate}>
            {i18n.t('sidebar.link.request_new_booking')}
          </WarningModalTrigger>

          <ExternalLink
            href={this.rafURL}
            onPress={() => (this.track('RaF'))}
            icon='ticket-percent'
            navigate={navigate}
            authenticated={true}
          >
            {i18n.t('sidebar.externalLink.RAF')}
          </ExternalLink>

          <ExternalLink href={this.helpURL} onPress={() => (this.track('help'))} icon='help-circle-outline' navigate={navigate}>
            {i18n.t('sidebar.externalLink.help')}
          </ExternalLink>

          <InternalLink href={logoutPath()} onPress={() => (this.track('log out'))} icon='logout-variant' navigate={navigate}>
            {i18n.t('sidebar.link.logout')}
          </InternalLink>
      </Menu>
    )
  }
}
export default compose(
  withNavigation()
)(Sidebar);

export const styles = (burger) => ({
  bmBurgerButton: burger,
  bmBurgerBars: {
    background: COLOR_WHITE,
    height: '3px'
  },
  bmBurgerBarsHover: {
    background: COLOR_WHITE
  },
  bmCrossButton: {
    height: '30px',
    width: '30px',
    right: '30px',
    top: '20px'
  },
  bmCross: {
    background: COLOR_WHITE,
    width: '20px',
    height: '5px'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0px',
    overflow: 'hidden'
  },
  bmMenu: {
    background: COLOR_PRIMARY,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    height: '100%',
    overflow: 'hidden'
  },
  bmMorphShape: {
    fill: COLOR_WHITE
  },
  bmItemList: {
    color: COLOR_WHITE,
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0px'
  }
});
