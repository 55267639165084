import React, { useEffect, useState } from 'react';
import { Image, TouchableOpacity, View, Dimensions, Text, StyleSheet } from 'react-native';
import { translate } from 'react-i18next';
import cardStyles from '../../layout/Card/CardStyles';
import { orientationChange } from '../../../helpers/eventListeners';

const Announcement = ({
  navigate,
  t
}) => {
  const defaultImageWidth = 403;
  const defaultImageHeight = 163;
  const { width, height } = Dimensions.get('window');
  const [dimensions, setDimensions] = useState({ width, height });

  useEffect(() => {
    orientationChange(updateDimensions);
  });

  const updateDimensions = (width, height) => {
    setDimensions({ width, height });
  }

  let newHeight = 0;
  const announcementImageHeight =
    (newHeight = Math.round(dimensions.height * defaultImageHeight / defaultImageWidth)) > defaultImageHeight
      ? defaultImageHeight
      : newHeight;

  const announcementImageWidth =
    dimensions.width - 8 > defaultImageWidth
    ? defaultImageWidth
    : dimensions.width - 14;
  return (
    <View
      style={defaultStyles.container}
      >
      <Text style={[cardStyles.cardTitle, defaultStyles.title]}>{t('dashboard.announcements.title')}:</Text>
      <TouchableOpacity
        onPress={() => navigate('/bundlesPrivate', { title: t('bundles.page.pagetitle'), replace: true })}
        activeOpacity={0.8}
        >
        <Image
          source={
            require('../../../assets/images/announcements/bundles.png')
          }
          resizeMode='contain'
          style={{
            height: announcementImageHeight,
            width: announcementImageWidth,
            alignSelf: 'center'
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default translate()(Announcement);
const defaultStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4
  },
  title: {
    fontWeight: 'normal'
  }
})
