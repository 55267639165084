import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ErrorHandler from '../ErrorHandler';
import { ScrollView } from '../layout/ScrollView';
import { getPastJobs } from '../../graphql/jobs.js';
import JobList from './JobList';
import { navigateToRating, navigateToDetails, navigateToBook } from '../../helpers/navigation';
import Card from '../layout/Card/Card';
import { formatTimespan, formatTitle } from '../../helpers/formatters';
import { CARD_TEXT_COLOR, COLOR_WHITE, COLOR_PRIMARY, COLOR_RATING } from '../../constants/layout';
import { STATES, FREQUENCIES } from '../../constants/jobs';
import { WarningModalTrigger } from '../WarningModalTrigger';

class PastJobs extends Component {
  state = {
    info: []
  };

  render = () => {
    const { t, navigate } = this.props;

    return (
      <Query key="past" query={getPastJobs} fetchPolicy="cache-and-network">
        {props => {
          const { data: { jobs } = {}, refetch, loading } = props;
          const [ lastJob, ...jobsList ] = jobs;
          let lastJobCard = null;

          if (lastJob) {
            const { bookingDetails } = lastJob;
            const buttons = [];

            if (!lastJob.rating && lastJob.state === STATES.DONE) {
              buttons.push({
                iconName: 'rate-it',
                iconSet: 'CustomIcons',
                iconSize: 24,
                iconColor: COLOR_RATING,
                text: t('dashboard.lastCleaning.rateCleaning'),
                onPress: () => (
                  navigateToRating(
                    navigate,
                    lastJob.id,
                    formatTitle(
                      day,
                      date
                    ),
                    formatTimespan(
                      startTime,
                      endTime
                    ))
                )
              });
            }

            if (bookingDetails.frequency === FREQUENCIES.ONCE && lastJob.state === STATES.DONE) {
              buttons.push({
                renderComponent: WarningModalTrigger,
                iconName: 'calendar-plus',
                iconSet: 'MaterialCommunityIcons',
                iconSize: 24,
                iconColor: COLOR_PRIMARY,
                text: t('dashboard.lastCleaning.bookCleaning'),
                onPress: () =>
                  navigateToBook(navigate, lastJob.id, t('quickbook.newbooking.mainTitle'))
              });
            }

            const { day, date, startTime, endTime, formattedFrequency, serviceType } = lastJob.bookingDetails;
            lastJobCard = (
              <Card
                marginTop={10}
                title={`${serviceType}\n${day} ${date}`}
                body={
                  formatTimespan(startTime, endTime) + ', ' +
                  formattedFrequency
                }
                titleSize={14}
                icon={{
                  name: 'event',
                  iconSet: 'MaterialIcons',
                  iconSize: 20,
                  iconColor: CARD_TEXT_COLOR
                }}
                buttons={buttons}
                onPress={() => (
                  navigateToDetails(
                    navigate,
                    lastJob.id,
                    formatTitle(
                      day,
                      date
                    ),
                    formatTimespan(
                      startTime,
                      endTime
                    ))
                )}
                displayChevron={true}
              />
            );
          }

          return (
            <ErrorHandler {...props} dataIsAvailable={!!jobsList}>
              {() => {
                return (
                  <ScrollView refresh={refetch} loading={loading} style={{ backgroundColor: COLOR_WHITE }}>
                    {lastJobCard}
                    <JobList
                      jobs={jobsList}
                      emptyMessage={t('dashboard.past.emptyMessage')}
                      navigate={navigate}
                      skipMessage={lastJob}
                    />
                  </ScrollView>
                );
              }}
            </ErrorHandler>
          );
        }}
      </Query>
    );
  };
}

PastJobs.propTypes = {
  navigate: PropTypes.func,
  t: PropTypes.func
}

export default (PastJobs);
