import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import Button from '../components/Button';
import ErrorHandler from '../components/ErrorHandler';
import Fab from '../components/Fab';
import Icon from '../components/Icon';
import List from '../components/layout/List';
import ListDivider from '../components/layout/ListDivider';
import PaymentItem from '../components/layout/PaymentItem';
import Paper from '../components/layout/Paper';
import { ScrollView } from '../components/layout/ScrollView';
import Spinner from '../components/Spinner';
import SystemMessage from '../components/SystemMessage';
import { FREQUENCIES } from '../constants/jobs';
import {
  COLOR_ACTION,
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY,
  COLOR_WHITE,
  COLOR_LIST_ITEM_ACTION
} from '../constants/layout';
import { getPayments } from '../graphql/wallet.js';
import { openWebpage } from '../helpers/navigation';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';

const PAYMENTS_LIMIT = 10;

const InvoiceInfo = ({ amount, invoice = false, error = false }) => {
  return (
    <View style={styles.amount}>
      <Text style={[styles.amountLabel, error && styles.amountLabelError]}>
        {amount}
      </Text>
      {invoice && (
        <Icon
          style={{ width: 27 }}
          size={26}
          name="download"
          set="CustomIcons"
          color={COLOR_TEXT_SECONDARY}
      />
      )}
    </View>
  );
};

const WalletBalance = (amount, t) => {
  return (
    <View style={styles.walletBallance}>
      <Icon style={styles.walletAmountIcon}
          size={50}
          name="wallet"
          set="CustomIcons"
          color={COLOR_TEXT_SECONDARY}
      />
      <View style={styles.walletContent}>
        <Text style={[styles.walletLabel]}>
          {t('payments.yourBalance')}
        </Text>
        <Text style={[styles.walletAmount]}>
          {amount.formatted}
        </Text>
      </View>
    </View>
  );
};

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

const groupPayments = payments =>
  payments.reduce((months, payment) => {
    const [currentMonth] = months.slice(-1);
    if (currentMonth && currentMonth.name === payment.month) {
      return [
        ...months.slice(0, -1),
        {
          ...currentMonth,
          payments: [...currentMonth.payments, payment]
        }
      ];
    } else {
      return [
        ...months,
        {
          name: payment.month,
          payments: [payment]
        }
      ];
    }
  }, []);

class Payments extends Component {
  state = {
    allPaymentsLoaded: false
  };

  componentDidMount() {
    const ga = new GoogleAnalytics();
    ga.recordView('payments');
  }

  renderPayments = ({
    data: {
      wallet: { balance = 0, failedPayments = [], successfulPayments = [] } = {}
    } = {},
    error,
    loading,
    refetch,
    fetchMore
  }) => {
    const { allPaymentsLoaded } = this.state;
    const { navigate, t } = this.props;

    const groupedPayments = groupPayments(successfulPayments);

    const handleScroll = e => {
      if (!loading && !allPaymentsLoaded && isCloseToBottom(e.nativeEvent)) {
        fetchMore({
          variables: {
            skip: successfulPayments.length
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              !fetchMoreResult ||
              !fetchMoreResult.wallet.successfulPayments.length
            ) {
              this.setState({ allPaymentsLoaded: true });
              return prev;
            }

            if (
              fetchMoreResult.wallet.successfulPayments.length < PAYMENTS_LIMIT
            ) {
              this.setState({ allPaymentsLoaded: true });
            }

            return {
              ...prev,
              wallet: {
                ...prev.wallet,
                successfulPayments: [
                  ...prev.wallet.successfulPayments,
                  ...fetchMoreResult.wallet.successfulPayments
                ]
              }
            };
          }
        });
      }
    };

    return (
      <ScrollView
        onScroll={handleScroll}
        scrollEventThrottle={1000}
        refresh={refetch}
        loading={loading}
      >
        {WalletBalance(balance, t)}
        {!!failedPayments.length && (
          <Paper style={styles.pendingContainer} elevation={2}>
            <Text style={styles.pendingLabel}>
              {t('payments.pendingTitle')}
            </Text>
            <List>{this.renderTransactions(failedPayments, true)}</List>
            <Button
              secondary
              action
              title={t('payments.pendingAction')}
              onPress={() => navigate('/payments/update')}
            />
          </Paper>
        )}
        {successfulPayments.length ? (
          <React.Fragment>
            <List style={{ margin: 16 }}>
              {groupedPayments.map((month, index) => (
                <React.Fragment key={index}>
                  <ListDivider sticky title={month.name} />
                  {this.renderTransactions(month.payments)}
                </React.Fragment>
              ))}
            </List>
            {successfulPayments.length >= PAYMENTS_LIMIT &&
              !allPaymentsLoaded && <Spinner />}
          </React.Fragment>
        ) : error ? (
          <SystemMessage message={t('ui.error.fetching')} />
        ) : (
          <SystemMessage fullscreen message={t('payments.emptyMessage')} />
        )}
      </ScrollView>
    );
  };

  renderTransactions = (transactions, error = false) =>
    transactions.map(
      ({
        id,
        date,
        amount: { formatted: formattedAmount },
        invoice,
        details
      }) => {
        const {
          frequency = undefined,
          formattedFrequency = undefined,
          appointmentStart = undefined,
          weekday = undefined
        } = details || {};

        const { t } = this.props;

        const title = frequency
          ? frequency === FREQUENCIES.ONCE
            ? `${formattedFrequency}, ${appointmentStart}`
            : `${formattedFrequency}, ${weekday}`
          : t('ui.misc');

        return (
          <PaymentItem style={styles.paymentItem}
            key={id}
            title={title}
            subtitle={date}
            amount={formattedAmount}
            action={
              <InvoiceInfo
                invoice={!!invoice}
                error={error}
              />
            }
            onPress={!!invoice && (() => openWebpage(invoice, true))}
          />
        );
      }
    );

  render() {
    const { navigate } = this.props;

    return (
      <React.Fragment>
        <Query
          query={getPayments}
          variables={{ skip: 0, limit: PAYMENTS_LIMIT }}
          fetchPolicy="cache-and-network"
          notifyOnNetworkStatusChange={true}
          children={props => {
            const {
              data: { wallet: { balance, failedPayments, successfulPayments } = {} } = {}
            } = props;

            return (
              <ErrorHandler
                {...props}
                dataIsAvailable={failedPayments && successfulPayments && balance}
                children={this.renderPayments}
              />
            );
          }}
        />
        <Fab
          onPress={() => navigate('/payments/update')}
          icon={<Icon name="add" size={24} color={COLOR_WHITE} />}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(Payments);

const styles = StyleSheet.create({
  walletBallance: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 15,
    marginBottom: 7,
    padding: 21,
    backgroundColor: '#b3d8e7',
    borderRadius: 4,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: {
        height: 2,
        width: 2
    },
    elevation: 7
  },
  walletAmountIcon: {
    alignItems: 'flex-start',
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 70
  },
  walletContent: {
    flex: 1,
    marginLeft: 10
  },
  walletLabel: {
    fontSize: 18,
    color: COLOR_LIST_ITEM_ACTION,
    fontWeight: '300',
    display: 'flex',
    flexDirection: 'column'
  },
  walletAmount: {
    fontSize: 28,
    color: COLOR_LIST_ITEM_ACTION,
    display: 'flex',
    flexDirection: 'column'
  },
  paymentItem: {
    borderRadius: 4,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: {
        height: 2,
        width: 2
    },
    elevation: 7,
    marginBottom: 10
  },
  pendingContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 15,
    marginBottom: 8
  },
  pendingLabel: {
    padding: 16,
    color: COLOR_ACTION,
    fontSize: 24
  },
  negativeLabel: {
    color: COLOR_ACTION
  },
  amount: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  amountLabel: {
    color: COLOR_PRIMARY,
    fontSize: 14
  },
  amountLabelError: {
    color: COLOR_ACTION
  }
});
