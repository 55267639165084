import React from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import styled from 'styled-components/native';
import {
  COLOR_BACKGROUND,
  COLOR_TEXT_SECONDARY,
  COLOR_WHITE,
  COLOR_GRAY
} from '../../constants/layout';

export const ListItemEl = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLOR_WHITE};
`;

export const ListItemForButton = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  margin-top: 15px;
`;

export const ListItemForButtonSlim = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  margin-top: 0px;
`;

export const ListItemIcon = styled.View`
  min-width: 56px;
  padding: 8px 0 8px 16px;
  align-items: flex-start;
`;

export const ListItemIconUSP = styled.View`
  align-items: flex-start;
  margin-left: 30px;
  margin-right: 16px;
`;

export const ListItemContent = styled(({ dense, innerRef, nopadding, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  flex: 1;
  padding: ${props => (props.nopadding ? '0' : (props.dense ? '12px 16px' : '16px'))};
`;

export const ListItemContentUSP = styled(({ dense, innerRef, nopadding, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  padding: ${props => (props.nopadding ? '0' : (props.dense ? '12px 16px' : '16px'))};
`;

export const ListItemTitle = styled(({ innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  font-size: 16px;
  line-height: 18px;
  text-align: left;
`;

export const ListItemTitleUSP = styled.Text`
  color: ${COLOR_GRAY}
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  font-weight: 400;
`;

export const ListItemSubtitle = styled.Text`
  margin-top: 2px;
  color: ${COLOR_TEXT_SECONDARY};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

export const ListItemAction = styled.View`
  display: flex;
  padding: ${props => props.nopadding ? '0' : '8px 16px 8px 0'};
`;

/**
 * @param {Object} params
 * @param {any=} params.icon
 * @param {boolean} params.bareIcon
 * @param {string=} params.title
 * @param {string=} params.subtitle
 * @param {ReactElement=} params.action
 * @param {any=} params.onPress
 * @param {Boolean=} params.dense
 * @param {Boolean=} params.nopadding
 * @param {Boolean=} params.indent
 * @param {Object=} params.style
 * @param {any=} params.children
 */
export default function ListItem(params) {
  const {
    icon,
    bareIcon,
    title,
    subtitle,
    action,
    onPress,
    dense = false,
    nopadding = false,
    indent = false,
    children,
    ...props
  } = params;

  const iconEl = bareIcon ? (<ListItemIconUSP>{icon}</ListItemIconUSP>) : (<ListItemIcon>{icon}</ListItemIcon>);
  const Element = children ? (
    <ListItemEl {...props} children={children} />
  ) : (
    <ListItemEl {...props}>
      {(icon || indent) && iconEl }
      {
        bareIcon
        ? (<ListItemContentUSP dense={dense} nopadding={nopadding}>
          <ListItemTitleUSP>{title}</ListItemTitleUSP>
          {subtitle && <ListItemSubtitle>{subtitle}</ListItemSubtitle>}
        </ListItemContentUSP>)
        : (<ListItemContent dense={dense} nopadding={nopadding}>
          <ListItemTitle>{title}</ListItemTitle>
          {subtitle && <ListItemSubtitle>{subtitle}</ListItemSubtitle>}
        </ListItemContent>)
      }

      {action && <ListItemAction nopadding={nopadding}>{action}</ListItemAction>}
    </ListItemEl>
  );

  return onPress ? (
    <TouchableHighlight underlayColor={COLOR_BACKGROUND} onPress={onPress}>
      {Element}
    </TouchableHighlight>
  ) : (
    Element
  );
}
