import React from 'react';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { COLOR_WHITE, SECONDARY_COLOR } from '../constants/layout';

export default function TextInput({ style = {}, ...props }) {
  return (
    <TextInputElement
      style={style}
      placeholderTextColor={SECONDARY_COLOR}
      underlineColorAndroid="transparent"
      {...props}
    />
  );
}

const TextInputElement = styled.TextInput`
  padding: 10px 16px;
  ${props =>
    props.multiline
      ? css`
          min-height: ${props.numberOfLines * 20 + 20}px;
          text-align-vertical: top;
        `
      : 'min-height: 48px'};
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  background-color: ${COLOR_WHITE};
  ${Platform.OS === 'web' && 'outline-style: none;'};
  ${Platform.OS === 'web' && 'outline-width: 0;'};
`;
