import PropTypes from 'prop-types';
import React from 'react';
import { Link } from './link';
import { openWebpage } from '../../../helpers/navigation';

class ExternalLink extends React.Component {
  visitHref = () => {
    const { onPress, href, authenticated } = this.props
    onPress && onPress();
    openWebpage(href, authenticated);
  }

  render() {
    const { children, icon } = this.props
    return (
      <Link onPress={this.visitHref} icon={icon}>
        {children}
      </Link>
    )
  }
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  onPress: PropTypes.func,
  href: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  icon: PropTypes.string
}

export default ExternalLink;
