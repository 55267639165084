import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query, withApollo } from 'react-apollo';
import Composer from 'react-composer';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import Tabs from '../components/layout/Tabs';
import { TAB_BAR_STYLE } from '../constants/layout';
import { getDashboardTab, setDashboardTab } from '../graphql/ui.js';
import withNavigation from '../helpers/withNavigation';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics'
import Overview from '../components/dashboard/Overview';
import FutureJobs from '../components/dashboard/FutureJobs';
import PastJobs from '../components/dashboard/PastJobs';

class Dashboard extends Component {
  ga = new GoogleAnalytics();

  componentDidMount() {
    if (this.selectedTab === 0) {
      this.ga.recordView('overview');
    } else if (this.selectedTab === 1) {
      this.ga.recordView('upcomingJobs');
    } else {
      this.ga.recordView('pastJobs');
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Composer
        components={[
          <Query key="dashboardquery" query={getDashboardTab} children={() => null} />,
          <Mutation key="dashboardmutation" mutation={setDashboardTab} children={() => null} />
        ]}
      >
        {([
          {
            data: { uiState }
          },
          setDashboardTab
        ]) => {
          const { dashboardTab: selectedTab = 0 } = uiState || {};
          this.selectedTab = selectedTab;
          return (
            <React.Fragment>
              <Tabs
                tabs={[
                  t('dashboard.tabs.overview'),
                  t('dashboard.tabs.futureCleanings'),
                  t('dashboard.tabs.pastCleanings')
                ]}
                index={selectedTab}
                onChange={(e, index) => {
                  if (selectedTab === 0) {
                    this.ga.recordView('overview');
                  } else if (selectedTab === 1) {
                    this.ga.recordView('upcomingJobs');
                  } else {
                    this.ga.recordView('pastJobs');
                  }
                  setDashboardTab({ variables: { index } });
                }}
                style={{ ...TAB_BAR_STYLE, zIndex: 2, elevation: 5 }}
              />
              { selectedTab === 0 && <Overview {...this.props} /> }
              { selectedTab === 1 && <FutureJobs {...this.props} /> }
              { selectedTab === 2 && <PastJobs {...this.props} /> }

            </React.Fragment>
          );
        }}
      </Composer>
    );
  }
}

Dashboard.propTypes = {
  client: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func
}

export default compose(
  withApollo,
  withNavigation(),
  translate()
)(Dashboard);
