import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';
import Button from '../components/Button';
import ErrorHandler from '../components/ErrorHandler';
import Icon from '../components/Icon';
import IconPlaceholder from '../components/IconPlaceholder';
import List from '../components/layout/List';
import ListDivider from '../components/layout/ListDivider';
import ListItem from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import TextInput from '../components/TextInput';
import {
  COLOR_BACKGROUND,
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY
} from '../constants/layout';
import { cancelJob, getJobCancellationReasons } from '../graphql/jobs.js';
import {
  cancelOrder,
  getOrderCancellationReasons
} from '../graphql/orders.js';
import withNavigation from '../helpers/withNavigation';

class Cancellation extends Component {
  state = {
    selectedReason: null,
    comment: '',
    isSubmitting: false
  };

  renderCancellationForm = (cancel, cancellationReasons) => {
    const { t } = this.props;
    const { selectedReason, comment, isSubmitting } = this.state;
    const {
      params: { reference, fees = 0, job = null },
      navigate,
      cancellationModel
    } = this.props;

    const handleCancellationSubmission = async () => {
      this.setState({ isSubmitting: true });

      try {
        const mutation = await cancel({
          variables: {
            reference,
            fees,
            reason: selectedReason,
            comment
          }
        });

        const {
          data: {
            [cancellationModel === 'JOB' ? 'cancelJob' : 'cancelOrder']: {
              status,
              message
            }
          } = {}
        } = mutation;

        if (status === 'SUCCESS') {
          this.setState({ isSubmitting: false });
          if (job) {
            navigate(`/details/:reference`, {
              flashMessage: message,
              reference: job
            });
          } else {
            navigate('/', {
              flashMessage: message
            });
          }
        } else {
          this.setState({ isSubmitting: false, error: message });
        }
      } catch (e) {
        this.setState({
          isSubmitting: false,
          error: t('ui.error.generic')
        });
      }
    };

    return (
      <ScrollView>
        {cancellationReasons && (
          <React.Fragment>
            <Text style={styles.title}>
              {t('cancellation.headline').toUpperCase()}
            </Text>
            <Text style={styles.title}>
              {t('cancellation.reasonsTitle').toUpperCase()}
            </Text>

            <List margin={false}>
              {cancellationReasons.map(reason => (
                <ListItem
                  key={reason.value}
                  title={reason.label}
                  icon={
                    selectedReason === reason.value ? (
                      <Icon name="check" color={COLOR_PRIMARY} />
                    ) : (
                      <IconPlaceholder />
                    )
                  }
                  onPress={() =>
                    this.setState({ selectedReason: reason.value })
                  }
                  style={styles.listItem}
                />
              ))}
            </List>
            <List margin={false}>
              <ListDivider title={t('cancellation.commentsTitle')} />
              <TextInput
                placeholder={t('cancellation.commentsPlaceholder')}
                multiline
                numberOfLines={4}
                onChangeText={comment => this.setState({ comment })}
                value={comment}
              />
            </List>
            <Button
              gigantic
              disabled={!selectedReason || isSubmitting}
              onPress={handleCancellationSubmission}
              title={t('cancellation.submit')}
            />
          </React.Fragment>
        )}
      </ScrollView>
    );
  };

  render() {
    const { cancellationModel } = this.props;

    const cancellationReasonsQuery =
      cancellationModel === 'JOB'
        ? getJobCancellationReasons
        : getOrderCancellationReasons;

    const cancellationReasonsFieldname =
      cancellationModel === 'JOB'
        ? 'jobCancellationReasons'
        : 'orderCancellationReasons';

    const cancellationMutation =
      cancellationModel === 'JOB' ? cancelJob : cancelOrder;

    return (
      <Query query={cancellationReasonsQuery} fetchPolicy="cache-and-network">
        {props => {
          const {
            data: { [cancellationReasonsFieldname]: cancellationReasons } = {}
          } = props;

          return (
            <ErrorHandler
              {...props}
              critical
              dataIsAvailable={!!cancellationReasons}
              children={props => {
                const {
                  data: {
                    [cancellationReasonsFieldname]: cancellationReasons
                  } = {}
                } = props;

                return (
                  <Mutation
                    mutation={cancellationMutation}
                    refetchQueries={['getFutureJobs', 'getPastJobs', 'getJob']}
                    children={cancel =>
                      this.renderCancellationForm(cancel, cancellationReasons)
                    }
                  />
                );
              }}
            />
          );
        }}
      </Query>
    );
  }
}

export const JobCancellation = compose(
  withNavigation(),
  translate(),
  WrappedComponent => props => (
    <WrappedComponent {...props} cancellationModel="JOB" />
  )
)(Cancellation);

export const OrderCancellation = compose(
  withNavigation(),
  translate(),
  WrappedComponent => props => (
    <WrappedComponent {...props} cancellationModel="ORDER" />
  )
)(Cancellation);

const styles = StyleSheet.create({
  title: {
    padding: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    color: COLOR_TEXT_SECONDARY,
    lineHeight: 18
  },
  listItem: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: COLOR_BACKGROUND
  }
});
