import React from 'react';
import { ScrollView, Text, TouchableHighlight } from 'react-native';
import styled from 'styled-components/native';
import {
  COLOR_PRIMARY,
  COLOR_WHITE,
  CARD_TEXT_COLOR,
  COLOR_GRAY
} from '../../constants/layout';

export default function Tabs({
  tabs = [],
  index = 0,
  style,
  onChange = (e, index) => {}
}) {
  return (
    <TabContainer style={style}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        alwaysBounceHorizontal={false}
      >
        {tabs.map((label, tabIndex) => {
          const active = tabIndex === index;
          return (
            <TouchableHighlight
              key={tabIndex}
              onPress={e => onChange(e, tabIndex)}
            >
              <Tab>
                <TabLabel active={active} numberOfLines={1}>
                  {label}
                </TabLabel>
                {active && <TabBorder />}
              </Tab>
            </TouchableHighlight>
          );
        })}
      </ScrollView>
    </TabContainer>
  );
}

const TabContainer = styled.View`
  background-color: ${COLOR_WHITE};
  align-items: stretch;
  min-height: 50px;
  borderBottomColor: ${COLOR_GRAY};
  borderBottomWidth: 1;
`;

const Tab = styled.View`
  min-width: 76px;
  min-height: 48px;
  padding: 15px 12px;
  flex: 1;
  background-color: ${COLOR_WHITE};
`;

const TabLabel = styled(({ active, innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  color: ${({ active }) => (active ? COLOR_PRIMARY : CARD_TEXT_COLOR)};
  font-weight: ${({ active }) => (active ? 500 : 'normal')};
  font-size: 14px;
  text-align: center;
  opacity: ${({ active }) => (active ? 1 : 0.7)};
`;

const TabBorder = styled.View`
  position: absolute;
  bottom: 0;
  start: 0;
  end: 0;
  border-bottom-width: 3px;
  border-bottom-color: ${COLOR_PRIMARY};
`;
