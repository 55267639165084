import React from 'react';
import { translate } from 'react-i18next';
import {
  COLOR_PRIMARY,
  COLOR_CANCEL_ACTION
} from '../../../constants/layout';
import {
  navigateToCancelBook,
  navigateToRescheduleBook
} from '../../../helpers/navigation';
import {
  formatTimespan,
  formatTitle
} from '../../../helpers/formatters';
import { RESCHEDULING_STATES } from '../../../constants/jobs';
import Card from '../../layout/Card/Card';

const NextCleaning = ({
  onPress,
  frequencyLabel,
  day,
  reference,
  date,
  startTime,
  reschedulingState = null,
  endTime,
  dayShort,
  dateShort,
  serviceType,
  navigate,
  t
}) => {
  const buttons = [];
  const displayButtons = reschedulingState === RESCHEDULING_STATES.RESCHEDULABLE

  if (displayButtons) {
    buttons.push({
      iconName: 'calendar-edit',
      iconSet: 'MaterialCommunityIcons',
      iconSize: 24,
      iconColor: COLOR_PRIMARY,
      text: t('ui.reschedule'),
      onPress: () => navigateToRescheduleBook(
        navigate,
        reference,
        formatTitle(day, date),
        formatTimespan(startTime, endTime)
      )
    });

    buttons.push({
      iconName: 'close-circle-outline',
      iconSet: 'MaterialCommunityIcons',
      iconSize: 24,
      iconColor: COLOR_CANCEL_ACTION,
      text: t('ui.cancel'),
      textColor: COLOR_CANCEL_ACTION,
      onPress: () => navigateToCancelBook(
        navigate,
        reference,
        t('cancellation.job.title'),
        `${formatTitle(dayShort, dateShort)}: ${formatTimespan(
          startTime,
          endTime
        )}`
      )
    });
  }

  return (
      <Card
        introText={t('dashboard.overview.nextCleaningLabel') + ':'}
        title={`${serviceType}\n${day} ${date}`}
        body={
          formatTimespan(startTime, endTime) + ', ' +
          frequencyLabel
        }
        titleSize={14}
        buttons={buttons}
        cardStyle={false}
        displayChevron={!!reference}
        onPress={onPress}
        disabled={!onPress}
    />
  );
};

export default translate()(NextCleaning);
