import creditcardutils from 'creditcardutils';
import { Formik } from 'formik';
import { get } from 'lodash';
import React, { Component, createContext } from 'react';
import { Platform, Switch } from 'react-native';
import ListItem, { ListItemContent, ListItemIcon } from '../layout/ListItem';
import Picker from '../layout/Picker';
import TextField from '../layout/TextField';

export default function setupForm() {
  const FormContext = createContext();

  const Form = ({ children, ...props }) => (
    <Formik
      {...props}
      render={props => (
        <FormContext.Provider value={props} children={children(props)} />
      )}
    />
  );

  class FormItem extends Component {
    componentDidMount() {
      if (Platform.OS === 'web' && this._textInput && this.props.format) {
        this._textInput.setNativeProps({
          type: 'text'
        });
      }
    }

    render() {
      const {
        label,
        name,
        type = 'text',
        options = {},
        inputProps = {},
        placeholder = '',
        icon = null,
        format = null,
        onChange = value => {}
      } = this.props;

      return (
        <FormContext.Consumer>
          {({ setFieldValue, values, touched, errors }) => {
            const handleChange = value => {
              let formattedValue;

              switch (format) {
                case 'cc-number':
                  formattedValue = creditcardutils.formatCardNumber(value);
                  break;
                case 'cc-exp':
                  formattedValue = creditcardutils.formatCardExpiry(value);
                  break;
                default:
                  formattedValue = value;
              }

              setFieldValue(name, formattedValue);
              onChange && onChange(formattedValue);
            };

            const value = get(values, name);
            if (type === 'checkbox') {
              return (
                <ListItem
                  dense
                  title={label}
                  onPress={() => handleChange(!value)}
                  action={
                    <Switch
                      value={value}
                      onValueChange={() =>
                        Platform.OS !== 'web' && handleChange(!value)
                      }
                      {...inputProps}
                    />
                  }
                  icon={icon || <React.Fragment />}
                />
              );
            }

            const error =
              touched[name] && errors[name] && errors[name].toString();

            return (
              <ListItem>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemContent dense>
                  {type === 'text' && (
                    <TextField
                      label={label}
                      onChange={handleChange}
                      value={value}
                      error={error}
                      placeholder={placeholder}
                      innerRef={ref => (this._textInput = ref)}
                      {...inputProps}
                    />
                  )}
                  {type === 'select' && (
                    <Picker
                      label={label}
                      onChange={handleChange}
                      value={value}
                      error={error}
                      placeholder={placeholder}
                      items={Object.entries(options).map(([value, label]) => ({
                        value,
                        label
                      }))}
                      {...inputProps}
                    />
                  )}
                </ListItemContent>
              </ListItem>
            );
          }}
        </FormContext.Consumer>
      );
    }
  }

  return {
    Form,
    FormItem,
    FormContext
  };
}
