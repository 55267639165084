import { CachePersistor } from 'apollo-cache-persist';

let cachePersistor = null;

export function setupCachePersistor(cache, storage) {
  cachePersistor = new CachePersistor({
    cache,
    storage
  });

  return cachePersistor;
}

export function clearCache() {
  if (cachePersistor) {
    return cachePersistor.purge();
  } else {
    throw new Error('Cache is not ready');
  }
}
