import React, { Component } from 'react';
import { Interpolate, translate } from 'react-i18next';
import styled from 'styled-components/native';
import { COLOR_TEXT_PRIMARY } from '../constants/layout';
import { openWebpage } from '../helpers/navigation';
import Sentry from '../helpers/sentry';

class ErrorBoundary extends Component {
  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    const { children, t } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Container>
          <Title>{t('ui.error.critical.title')}</Title>
          <Paragraph>{t('ui.error.critical.line2')}</Paragraph>
          <Paragraph>
            <Interpolate
              i18nKey="ui.error.critical.line3"
              phone={
                <Link onPress={() => openWebpage(`tel:${t('ui.phoneNumber')}`)}>
                  {t('ui.phoneNumber')}
                </Link>
              }
            />
          </Paragraph>
        </Container>
      );
    }

    return children;
  }
}

export default translate()(ErrorBoundary);

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Title = styled.Text`
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 28px;
  text-align: center;
  color: ${COLOR_TEXT_PRIMARY};
`;

const Paragraph = styled.Text`
  padding: 0 16px;
  margin-bottom: 16px;
  font-size: 16px;
  text-align: center;
  color: ${COLOR_TEXT_PRIMARY};
`;

const Link = styled.Text`
  text-decoration: underline;
`;
