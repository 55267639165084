import parseUrl from 'url-parse';
import { makeAuthenticationUrl } from '../../constants/auth';
import { getEnvironmentVariable } from '../../constants/environment';
import { checkAndRefreshAuthToken } from '../auth';
import { FeaturesEnum, getFeatureValue } from '../../features';
export * from './shared-navigation';

export async function openWebpage(url, authenticate = false) {
  const parsedUrl = parseUrl(url, true);

  if (authenticate) {
    parsedUrl.query.redirectUri = encodeURIComponent(makeAuthenticationUrl());
    parsedUrl.query.clientId = getEnvironmentVariable(
      'REACT_APP_AUTH_CLIENT_ID'
    );

    const { accessToken } = await checkAndRefreshAuthToken();
    parsedUrl.query.accessToken = accessToken;
  }

  window.location = parsedUrl.toString();
}

const openInNewTab = (href) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href
  }).click();
}

export const openServices = () => {
  openInNewTab(getFeatureValue(FeaturesEnum.ADDITIONAL_SERVICES));
}

export function openFunnel(params = '', authenticate = true) {
  openWebpage(
    `${getEnvironmentVariable('REACT_APP_WEB_URL')}/checkout/details?` + params,
    authenticate
  );
}

export function closeBrowser() {}

export function setTopLevelNavigator(navigatorRef) {}

export function navigate(routeName, params) {
  window.location = routeName;
}

export function navigateToDetails(navigate, id, date, time) {
  navigate(`/details/:reference`, {
    reference: id,
    title: date,
    subtitle: time
  });
}

export function navigateToRating(navigate, id, date, time) {
  navigate(`/rate/:reference`, {
    reference: id,
    title: date,
    subtitle: time
  });
}

export function navigateToBook(navigate, id, date, time) {
  navigate(`/book/:reference`, {
    reference: id,
    title: date,
    subtitle: time
  });
}

export function navigateToCancelBook(navigate, id, date, time) {
  navigate(`/cancel/:reference`, {
    reference: id,
    title: date,
    subtitle: time
  });
}

export function navigateToRescheduleBook(navigate, id, date, time) {
  navigate(`/reschedule/:reference`, {
    reference: id,
    title: date,
    subtitle: time
  });
}

export function navigateToPublicBundle(navigate, id, title, bundle) {
  navigate(`/bundle/:reference`, {
    reference: id,
    title: title,
    bundle: bundle
  });
}

export function navigateToPrivateBundle(navigate, id, title, bundle) {
  navigate(`/plan/:reference`, {
    reference: id,
    title: title,
    bundle: bundle
  });
}
