import React from 'react';
import { Query } from 'react-apollo';
import { flowRight as compose, get } from 'lodash';
import Composer from 'react-composer';
import { translate } from 'react-i18next';
import ErrorHandler from '../components/ErrorHandler';
import Fab, { FabSpacer } from '../components/Fab';
import setupForm from '../components/form/MutatingForm';
import Icon from '../components/Icon';
import List from '../components/layout/List';
import { ScrollView } from '../components/layout/ScrollView';
import { getSalutations } from '../constants/customer';
import { COLOR_TEXT_SECONDARY, COLOR_WHITE } from '../constants/layout';
import { getUpdateProfileSchema } from '../constants/validations';
import { getCities } from '../graphql/cities.js';
import { getUpdateProfileConfig } from '../graphql/config.js';
import { getCustomer, updateProfile } from '../graphql/customer.js';
import withNavigation from '../helpers/withNavigation';

import GoogleAnalytics from '../helpers/analytics/googleAnalytics'

const { MutatingForm, FormItem } = setupForm();

function UpdateProfile({ navigate, t }) {
    const ga = new GoogleAnalytics();
    ga.recordView('updateProfile');

  return (
    <Composer
      components={[
        <Query
          query={getCustomer}
          fetchPolicy="cache-and-network"
          children={() => null}
          key="getCustomer"
        />,
        <Query
          query={getCities}
          fetchPolicy="cache-and-network"
          children={() => null}
          key="getCities"
        />,
        <Query
          query={getUpdateProfileConfig}
          fetchPolicy="cache-and-network"
          children={() => null}
          key="getUpdateProfileConfig"
        />
      ]}
    >
      {([customerProps, cityProps, updateProfileConfig]) => {
        const customer = get(customerProps, 'data.customer');
        const cities = get(cityProps, 'data.cities');
        const { loading: queryLoading } = customerProps;

        const citiesWithAreas = transformCities(cities);
        const { name: namePattern, mobile: mobilePattern } = get(
          updateProfileConfig,
          'data.config',
          {}
        );

        const citiesOptions = Object.entries(citiesWithAreas).reduce(
          (obj, [key, { name }]) => ({ ...obj, [key]: name }),
          {}
        );

        return (
          <ErrorHandler
            error={
              customerProps.error ||
              cityProps.error ||
              updateProfileConfig.error
            }
            loading={
              customerProps.loading ||
              cityProps.loading ||
              updateProfileConfig.loading
            }
            dataIsAvailable={
              !!customer && !!cities && !!namePattern && !!mobilePattern
            }
            refetch={(...args) => {
              customerProps.refetch(args);
              cityProps.refetch(args);
              updateProfileConfig.refetch(args);
            }}
            critical
            children={() => {
              return (
                <MutatingForm
                  mutation={updateProfile}
                  refetchQueries={['getCustomer']}
                  model="updateProfile"
                  initialValues={mapCustomerToFields(customer)}
                  validationSchema={getUpdateProfileSchema({
                    namePattern,
                    mobilePattern
                  })}
                  onSuccess={result => {
                    navigate('/profile', {
                      flashMessage: get(result, 'data.updateProfile.message')
                    });
                  }}
                  children={(
                    action,
                    { loading },
                    { values, setFieldValue, handleSubmit }
                  ) => {
                    const selectedCityId = get(values, 'city');
                    const areasOptions = citiesWithAreas[selectedCityId]
                      ? citiesWithAreas[selectedCityId].areas
                      : { '': '…' };

                    return (
                      <React.Fragment>
                        <ScrollView>
                          <List margin={false}>
                            <FormItem
                              type="select"
                              label={t('updateProfile.salutationTitle')}
                              name="salutation"
                              options={getSalutations()}
                              icon={
                                <Icon
                                  color={COLOR_TEXT_SECONDARY}
                                  name="person"
                                />
                              }
                            />
                            <FormItem
                              label={t('updateProfile.firstNameTitle')}
                              name="firstName"
                            />
                            <FormItem
                              label={t('updateProfile.lastNameTitle')}
                              name="lastName"
                            />

                            <FormItem
                              label={t('updateProfile.buildingTitle')}
                              name="building"
                              icon={
                                <Icon
                                  color={COLOR_TEXT_SECONDARY}
                                  name="business"
                                />
                              }
                            />
                            <FormItem
                              label={t('updateProfile.apartmentTitle')}
                              name="apartment"
                            />
                            <FormItem
                              type="select"
                              label={t('updateProfile.cityTitle')}
                              name="city"
                              options={citiesOptions}
                              placeholder={t('updateProfile.cityPlaceholder')}
                              onChange={value => setFieldValue('area', '')}
                            />
                            <FormItem
                              type="select"
                              label={t('updateProfile.areaTitle')}
                              name="area"
                              options={areasOptions}
                              placeholder={t('updateProfile.areaPlaceholder')}
                            />
                            <FormItem
                              label={t('updateProfile.descriptionTitle')}
                              name="description"
                            />
                            <FormItem
                              type="checkbox"
                              label={t('updateProfile.petsTitle')}
                              name="havePets"
                            />

                            <FormItem
                              label={t('updateProfile.phoneTitle')}
                              name="mobileNumber"
                              inputProps={{ keyboardType: 'phone-pad' }}
                              icon={
                                <Icon
                                  color={COLOR_TEXT_SECONDARY}
                                  name="notifications"
                                />
                              }
                            />
                            <FormItem
                              type="checkbox"
                              label={t('updateProfile.notificationsTitle')}
                              name="smsNotification"
                            />
                          </List>
                          <FabSpacer />
                        </ScrollView>
                        <Fab
                          disabled={queryLoading || loading}
                          onPress={handleSubmit}
                          icon={<Icon name="save" color={COLOR_WHITE} />}
                        />
                      </React.Fragment>
                    );
                  }}
                />
              );
            }}
          />
        );
      }}
    </Composer>
  );
}

const transformCities = cities =>
  cities
    ? cities.reduce(
        (cities, city) => ({
          ...cities,
          [city.id]: {
            name: city.name,
            areas: city.areas.reduce(
              (areas, area) => ({
                ...areas,
                [area.id]: area.name
              }),
              {}
            )
          }
        }),
        {}
      )
    : {};

const mapCustomerToFields = customer => {
  const additionalInfo = customer.additionalInfo;
  const havePets = additionalInfo ? !!additionalInfo.pets : false;
  return {
    salutation: customer.name.salutation,
    firstName: customer.name.firstName,
    lastName: customer.name.lastName,
    building: customer.address.building,
    apartment: customer.address.apartment,
    area: customer.address.area,
    city: customer.address.city,
    description: customer.address.description,
    havePets: havePets,
    mobileNumber: customer.mobileNumber,
    smsNotification: customer.smsNotifications
  };
};

export default compose(
  withNavigation(),
  translate()
)(UpdateProfile);
