import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Image, View, Platform } from 'react-native';
import { COLOR_PRIMARY } from '../../constants/layout';

export const CleanerAvatarSizeEnum = {
  SMALL: 69,
  MEDIUM: 85
};

const styles = StyleSheet.create({
  imageContainer: {
    borderColor: COLOR_PRIMARY,
    overflow: 'hidden',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export const CleanerAvatar = props => {
  const { size } = props;
  const borderWidth = Math.max(1, Math.round(size / 30));
  const width = size + 4 * borderWidth;
  const additionalStyles = {
    width,
    borderWidth,
    height: width,
    borderRadius: width / 2
  };

  return (
    <View style={[props.style, styles.imageContainer, additionalStyles]}>
      <Image
        source={
          Platform.OS === 'web'
            ? require('../../assets/images/cleaner-avatar.svg')
            : require('../../assets/images/cleaner-avatar.png')
        }
        style={{ width: size, height: size }}
      />
    </View>
  );
};

CleanerAvatar.propTypes = {
  size: PropTypes.oneOf(Object.values(CleanerAvatarSizeEnum)).isRequired
};
