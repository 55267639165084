import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from '../Icon';
import { COLOR_PRIMARY, COLOR_INACTIVE } from '../../constants/layout';
import { times } from 'lodash';

export default function Stars({
  size = 24,
  activeColor = COLOR_PRIMARY,
  inactiveColor = COLOR_INACTIVE,
  value = 0,
  onSelect = i => {},
  disabled = false
}) {
  return (
    <View style={styles.container}>
      {times(5, i => (
        <Icon
          key={i}
          name="star"
          set="CustomIcons"
          color={i + 1 > value ? inactiveColor : activeColor}
          size={size}
          onPress={disabled ? null : () => onSelect(i + 1)}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  }
});
