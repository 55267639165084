import React, { Component } from 'react';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import { translate } from 'react-i18next';
import { withApollo } from 'react-apollo';
import { storePushToken } from '../graphql/notifications.js'
import { Image, Platform, StatusBar, Text, TouchableOpacity, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import Button from '../components/Button';
import Icon from '../components/Icon';
import Redirect from '../components/routing/Redirect';
import { GA_CAMPAIGN_LOGIN_SCREEN } from '../constants/analytics';
import { makeAuthenticationUrl } from '../constants/auth';
import { getRegion } from '../constants/environment';
import { WarningModalTrigger } from '../components/WarningModalTrigger';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_PRIMARY,
  COLOR_WHITE,
  COLOR_SECONDARY_KSA,
  COLOR_ACTION
} from '../constants/layout';
import {
  executeAuthRequest,
  fetchAccessTokens,
  handleAuthRequest,
  makeAuthorizeUrl,
  setAuthTokens
} from '../helpers/auth';
import Sentry from '../helpers/sentry';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import getManifestInfo from '../helpers/manifest';
import withNavigation from '../helpers/withNavigation';
import { registerForPushNotificationsAsync } from '../helpers/notifications';
import { openFunnel } from '../helpers/navigation';
import { getRedirectAfterLoginPathname, setRedirectAfterLoginPathname } from '../helpers/navigation/redirectAfterLogin';
import { getMinimumPricingForBundles, getBasePricing } from '../graphql/jobs.js';

class Login extends Component {
  state = {
    loading: false,
    error: !!this.props.params.error,
    redirect: false,
    debugClicks: 0,
    consoleClicks: 0,
    debug: false,
    debugInfo: '',
    basePrice: '',
    basePriceForPackages: ''
  };

  _unmounted = false;
  constructor(props) {
    super(props);
    this._handleLogoDebugClick = this._handleLogoDebugClick.bind(this);
    this._handleConsoleClick = this._handleConsoleClick.bind(this);
    this.displayAlternativeScreen = getRegion() === 'sa';
  }

  _handleLogoDebugClick(event) {
    this.setState({ debugClicks: this.state.debugClicks + 1 });
    if (this.state.debugClicks === 4) {
      const info = getManifestInfo();
      const debugInfo = info
        ? `Revision Id: ${info.revisionId}
           Published: ${info.publishedTime}
           SDK: ${info.sdkVersion}
           ${info.bundleUrl}`
        : `No info`;
      this.setState({ error: false, debugClicks: 0, debug: true, debugInfo: debugInfo });
    }
  }

  _handleConsoleClick(event) {
    const { navigate } = this.props;
    this.setState({ consoleClicks: this.state.consoleClicks + 1 });
    if (this.state.consoleClicks >= 4) {
      navigate('/logger');
      this.setState({ consoleClicks: 0 });
    }
  }
  openFunnel = () => {
    openFunnel(GA_CAMPAIGN_LOGIN_SCREEN);
  };

  navigateToBundles = () => {
    const { navigate, t } = this.props;
    navigate('/bundles', {
      title: t('bundles.page.pagetitle'),
      replace: true
    });
  }

  requestTokens = async code => {
    const tokens = await fetchAccessTokens(code);
    await setAuthTokens(tokens.access_token, tokens.refresh_token, tokens.expires_in);
  };

  componentWillUnmount() {
    this._unmounted = true;
  }

  async componentDidMount() {
    if (Platform.OS === 'web') {
      const pathName = get(this.props, ['params', 'redirectPathname'], null);
      if (pathName) {
        await setRedirectAfterLoginPathname(pathName);
      }
    }

    const { client } = this.props;

    if (this.displayAlternativeScreen) {
      try {
        client.query({ query: getMinimumPricingForBundles, fetchPolicy: 'network-only' }).then(response => {
          if (response && response.data && response.data.lowestPricePerHour && !this._unmounted) {
            const { data: { lowestPricePerHour } } = response;
            this.setState({
              ...this.state,
              basePriceForPackages: lowestPricePerHour.formatted
            });
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      }

      try {
        client.query({ query: getBasePricing, fetchPolicy: 'network-only' }).then(response => {
          if (response && response.data && response.data.basePrice && !this._unmounted) {
            const { data: { basePrice } } = response;
            this.setState({
              ...this.state,
              basePrice: basePrice.formatted
            });
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    try {
        registerForPushNotificationsAsync(false).then(({ update, token, status }) => {
          token && update && client.mutate({
            mutation: storePushToken,
            variables: {
              status,
              token
            }
          });
        });

      const ga = new GoogleAnalytics();
      ga.recordView('login');

      const code = await handleAuthRequest();

      if (this._unmounted) {
        return;
      }

      this.setState({ error: false, loading: true });
      await this.requestTokens(code);

      if (this._unmounted) {
        return;
      }

      const redirectPathname = await getRedirectAfterLoginPathname();
      await setRedirectAfterLoginPathname(null);

      if (this._unmounted) {
        return;
      }

      this.setState({
        loading: false,
        error: false,
        redirect: true,
        redirectPathname
      });
    } catch (e) {
      Sentry.captureException(e);
      if (this._unmounted) {
        return;
      }
      this.setState({ loading: false, error: true });
    }
  }

  render() {
    const { t, navigate } = this.props;
    const { loading, error, redirect, debug, debugInfo, basePrice, basePriceForPackages, redirectPathname = '/' } = this.state;
    const authUrl = makeAuthorizeUrl(makeAuthenticationUrl());
    const region = getRegion();
    const { displayAlternativeScreen } = this;
    const textColor = displayAlternativeScreen ? COLOR_PRIMARY : COLOR_WHITE;

    return redirect ? (
      <Redirect to={redirectPathname} />
    ) : (
      <Container
        source={
          displayAlternativeScreen
            ? require('../assets/images/login_bg_ar.jpg')
            : require('../assets/images/login_bg.jpg')
        }
        imageStyle={{
          resizeMode: displayAlternativeScreen ? 'contain' : 'cover'
        }}
        alternate={displayAlternativeScreen}
      >
        <SafeContainer>
          <MainContent alternate={displayAlternativeScreen}>
            <StatusBar
              backgroundColor={COLOR_PRIMARY}
              barStyle="light-content"
            />
            <TouchableOpacity activeOpacity = { 1 } onPress={ this._handleLogoDebugClick }>
            <Logo
              alternate={displayAlternativeScreen}
              source={
                displayAlternativeScreen
                ? Platform.OS === 'web'
                    ? require('../assets/images/logo_ar.svg')
                    : require('../assets/images/logo_ar.png')
                : Platform.OS === 'web'
                  ? require('../assets/images/logo.svg')
                  : require('../assets/images/logo.png')
              }
              style={
                region !== 'sa' && Platform.OS === 'web'
                ? { filter: 'drop-shadow(0 3px 4px rgba(68, 68, 68, .5))' }
                : {}}
            />
            </TouchableOpacity>
            <Region
              onPress={ this._handleConsoleClick }
              style={{ color: textColor }}
            >
              {t(`regions.${region}`)}
            </Region>
            {error && <Paragraph color={textColor}>{t('login.error')}</Paragraph>}
            {debug && <Paragraph color={textColor}>{debugInfo}</Paragraph>}
            {loading || redirect
              ? (
                <Paragraph color={textColor}>{t('login.loggingIn')}</Paragraph>
              )
              : (
              <ButtonBar alternate={displayAlternativeScreen}>
                {
                  displayAlternativeScreen
                  ? (
                      <View style={{ flexDirection: 'row', width: '100%' }}>
                        <Cell>
                          <WarningModalTrigger
                            component={CTAButton}
                            primary
                            noshadow
                            title={t('login.bookingButton')}
                            style={{ width: '100%' }}
                            color={COLOR_PRIMARY}
                            onPress={this.openFunnel}
                          />
                          <FunnelInfo>{t('login.bookNowDescription').replace('%d', basePrice)}</FunnelInfo>
                        </Cell>
                        <Cell>
                          <WarningModalTrigger
                            component={CTAButton}
                            primary
                            noshadow
                            title={t('login.packagesButton')}
                            style={{ width: '100%' }}
                            color={COLOR_SECONDARY_KSA}
                            onPress={this.navigateToBundles}
                            addon={
                              <Percentage>
                                  <Image source={
                                    Platform.OS === 'web'
                                      ? require('../assets/images/percentage.svg')
                                      : require('../assets/images/percentage.png')
                                    }
                                    style={{ width: '100%', height: '100%' }}
                                  />
                              </Percentage>
                            }
                          />
                          <PackagesInfo>{t('login.bookNowDescription').replace('%d', basePriceForPackages)}</PackagesInfo>
                        </Cell>
                      </View>)
                  : (
                    <WarningModalTrigger
                      component={CTAButton}
                      primary
                      title={t('login.bookingButton')}
                      style={{ width: 180 }}
                      color={COLOR_PRIMARY}
                      onPress={this.openFunnel}
                    />
                  )
                }

                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                  <Text style={{ fontSize: 10, color: textColor }}>{`${
                    process.env.REACT_APP_REVISION
                  } // ${process.env.REACT_APP_ENVIRONMENT}`}</Text>
                )}
              </ButtonBar>
            )}
          </MainContent>

          <Footer>
            <TouchableOpacity onPress={() => executeAuthRequest(authUrl)}>
              <Text style={{ color: textColor, alignItems: 'center' }}>
                {t('login.loginButton')}
              </Text>
            </TouchableOpacity>

            {process.env.REACT_APP_ENABLE_REGION_CHANGE === 'true' && (
              <Text style={{ fontSize: 20, color: textColor }}>&nbsp;|&nbsp;</Text>
            )}

            {process.env.REACT_APP_ENABLE_REGION_CHANGE === 'true' && (
              <TouchableOpacity
                onPress={() => navigate('/changeRegion')}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Icon
                  name="language"
                  color={textColor}
                  size={20}
                  style={{ marginRight: 5 }}
                />
                <Text style={{ color: textColor, alignItems: 'center' }}>
                  {t('login.changeRegion')}
                </Text>
              </TouchableOpacity>
            )}
          </Footer>
        </SafeContainer>
      </Container>
    );
  }
}

export default compose(
  withApollo,
  translate(),
  withNavigation()
)(Login);

const Container = styled.ImageBackground`
  flex: 1;
  padding: 16px;
  background-color: #fff;
`;

const SafeContainer = styled.SafeAreaView`
  flex: 1;
`;

const MainContent = styled(({ alternate, ...props }) => <View {...props}/>)`
  flex: 1;
  align-items: center;
  ${({ alternate }) => css`
    justify-content: ${alternate ? 'flex-start' : 'center'};
  `};
`;

const ButtonBar = styled(({ alternate, ...props }) => <View {...props}/>)`
  align-items: center;
  width: 100%;
  ${({ alternate }) => css`
    flex-grow: ${alternate ? '1' : '0'};
    justify-content: ${alternate ? 'flex-end' : 'center'};
  `};
  margin-bottom: 30px;
`;

const Footer = styled.View`
  display: flex;
  alignItems: center;
  justifyContent: center;
  flexDirection: row;
`;

const Logo = styled(({ alternate, ...props }) => <Image {...props}/>)`
  width: 200px;
  height: 60px;
  padding: 20px;
  margin-bottom: 10px;
  ${({ alternate }) => css`
    margin-top: ${alternate ? '50px' : '0'};
  `};
`;

const Percentage = styled(({ alternate, ...props }) => <View {...props}/>)`
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: 100;
  right: -14px;
  top: -14px;
`;

const Region = styled(({ region, ...props }) => <Text {...props}/>)`
  text-align: center;
  font-weight: bold;
  margin-bottom: 32px;
`;

const Paragraph = styled.Text`
  max-width: 216px;
  padding: 8px;
  margin-bottom: 16px;
  text-align: center;
  background-color: ${COLOR_WHITE};
  color: ${COLOR_TEXT_PRIMARY};
  z-index: 1;
  box-shadow: 0 0 8px #00000033;
  border-radius: 4px;
  elevation: 4;
`;

const CTAButton = styled(props => <Button justrounded {...props} />)`
  margin-bottom: 16px;
  max-width: 95%;
`;

const Cell = styled.View`
  flex: 1;
  max-width: 49%;
  align-items: center;
  display: flex;
  margin: 0%;
  flex-direction: column;
`;

const PackagesInfo = styled.Text`
  color: ${COLOR_ACTION};
  text-align: center;
`;

const FunnelInfo = styled.Text`
  color: ${COLOR_PRIMARY};
  text-align: center;
`;
