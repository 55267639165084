import { AsyncStorage } from 'react-native';

const LOG_INFO_KEY = 'log-info';

export async function logRequestResult(uri, options, data) {
  let logs = JSON.parse(await AsyncStorage.getItem(LOG_INFO_KEY));
  if (!logs || logs.length > 30) {
    logs = [];
  }

  let nl = {
    timestamp: Date.now(),
    url: uri,
    params: options,
    success: !data.error,
    response: data
  };
  logs.push(nl);
  await AsyncStorage.setItem(LOG_INFO_KEY, JSON.stringify(logs));
}

export async function getLogs() {
  return JSON.parse(await AsyncStorage.getItem(LOG_INFO_KEY));
}

export async function clearLogs() {
  await AsyncStorage.setItem(LOG_INFO_KEY, JSON.stringify([]));
}
