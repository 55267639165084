import { AsyncStorage } from 'react-native';

export function partition(array, isValid) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
}

export function toQueryString(obj) {
  let str = [];

  if (obj instanceof Object) {
    Object.keys(obj).forEach(key => {
      if (obj[key] != null && obj[key].toString().length) {
        str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    });
  }
  return str.join('&');
}

export async function getLocalClientId() {
  return AsyncStorage.getItem('LOCAL_CLIENT_ID');
}

export function setLocalClientId(cid = null) {
  cid = cid || generateUniqueMessageId();
  AsyncStorage.setItem('LOCAL_CLIENT_ID', cid);
  return cid;
}
export function generateUniqueMessageId() {
  return Math.floor((1 + Math.random() * new Date().getTime()) * 0x10000)
    .toString(16);
}

export function getWeekdays(t) {
  const idx = [...Array(7).keys()];
  return idx.map(d => t(`date.weekdays.${d}`));
}

export function getMonths(t) {
  const idx = [...Array(12).keys()];
  return idx.map(m => t(`date.months.${m}`));
}
