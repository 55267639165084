import React from 'react';
import {
  SafeAreaView,
  View,
  ScrollView,
  Text,
  StyleSheet,
  AsyncStorage
} from 'react-native';
import { Modal } from './Modal';

import propTypes from 'prop-types';
import { translate, Trans } from 'react-i18next';

import { SimpleCard } from './SimpleCard';
import List from './layout/List';

import Button from './Button';
import { WarningModalForm } from './WarningModalForm';
import {
  COLOR_PRIMARY,
  COLOR_INACTIVE,
  COLOR_GRAY,
  COLOR_TERTIARY
} from '../constants/layout';
import { ModalContent } from './ModalContent';
import compose from 'lodash/flowRight';
import { openServices } from '../helpers/navigation';

const imageWidth = 139;
const imageHeight = 109;

const styles = StyleSheet.create({
  wrapper: {
    padding: 0,
    flexGrow: 1,
    backgroundColor: COLOR_INACTIVE,
    justifyContent: 'center'
  },
  container: {
    alignItems: 'center',
    width: '100%',
    padding: 0,
    flexGrow: 1
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: imageWidth,
    maxWidth: '100%',
    width: 410
  },
  image: {
    zIndex: 1,
    width: imageWidth,
    height: imageHeight,
    marginBottom: 20,
    alignSelf: 'center'
  },
  headline: {
    textAlign: 'center',
    fontWeight: '800',
    color: COLOR_PRIMARY,
    fontSize: 18,
    lineHeight: 26,
    marginBottom: 8
  },
  text: {
    textAlign: 'center',
    color: COLOR_GRAY,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8
  },
  secondaryButton: {
    width: '100%',
    alignItems: 'center',
    alignSelf: 'center'
  },
  list: {
    flexWrap: 'wrap',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  inlineButton: {
    flexBasis: '40%'
  },
  bold: {
    fontWeight: '800'
  }
});
class WarningModalComponent extends React.Component {
  static propTypes = {
    onPress: propTypes.func,
    t: propTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      displayForm: false,
      formSubmitted: false
    };
  }

  openForm = () => {
    this.setState({ displayForm: true });
  };

  closeForm = () => {
    this.setState({ displayForm: false });
  };

  onFormSubmitted = () => {
    this.setState({ formSubmitted: true, displayForm: false });
  };

  componentWillUnmount() {
    this._unmounted = true;
  }
  async componentDidMount() {
    const token = await AsyncStorage.getItem('AUTH_ACCESS_TOKEN');
    this.safeSetState({ isLoggedIn: Boolean(token) });
  }

  safeSetState(...args) {
    if (!this._unmounted) {
      this.setState(...args);
    }
  }
  render() {
    const { t, onPress } = this.props;
    const { displayForm, formSubmitted, isLoggedIn } = this.state;

    const additionalText = (
      <Trans i18nKey="nonOperational.additionalText">
        1<Text style={styles.bold}>2</Text>3
      </Trans>
    );

    const offersButton = (
      <Button
        style={[styles.inlineButton, styles.ctaButton]}
        cta
        color={COLOR_PRIMARY}
        onPress={openServices}
        title={t('nonOperational.offers')}
      />
    );
    return (
      <Modal animationType="fade">
        <ScrollView contentContainerStyle={styles.wrapper}>
          <SafeAreaView>
            <View style={styles.container}>
              <View style={styles.content}>
                <SimpleCard style={styles.card}>
                  {formSubmitted && (
                    <>
                      <ModalContent
                        image={require('../assets/images/success-modal-bg.png')}
                        headline={t('nonOperational.formSubmitted.headline')}
                      >
                        {t('nonOperational.formSubmitted.text')}
                        {'\n\n'}
                        {additionalText}
                      </ModalContent>
                      <List margin={false}>
                        {offersButton}
                        <Button
                          secondary
                          underline
                          style={styles.secondaryButton}
                          textColor={COLOR_PRIMARY}
                          onPress={onPress}
                          title={t('nonOperational.formSubmitted.cta')}
                        />
                      </List>
                    </>
                  )}
                  {!formSubmitted && (
                    <ModalContent
                      image={require('../assets/images/non-operational.png')}
                      headline={t('nonOperational.headline')}
                    >
                      <Text>
                        {t('nonOperational.text')}
                        {'\n\n'}
                      </Text>
                      {additionalText}
                    </ModalContent>
                  )}
                  {(!displayForm || isLoggedIn) && !formSubmitted && (
                    <>
                      <List margin={false} style={styles.list}>
                        <Button
                          cta
                          onPress={this.openForm}
                          color={COLOR_TERTIARY}
                          title={t('nonOperational.cta')}
                          style={styles.inlineButton}
                        />
                        {offersButton}
                      </List>
                      <Button
                        secondary
                        underline
                        style={styles.secondaryButton}
                        textColor={COLOR_PRIMARY}
                        onPress={onPress}
                        title={t('nonOperational.secondary')}
                      />
                    </>
                  )}
                </SimpleCard>
                {displayForm && (
                  <WarningModalForm
                    isLoggedIn={isLoggedIn}
                    onSuccess={this.onFormSubmitted}
                    onCancel={this.closeForm}
                  />
                )}
              </View>
            </View>
          </SafeAreaView>
        </ScrollView>
      </Modal>
    );
  }
}

export const WarningModal = compose(translate())(WarningModalComponent);
