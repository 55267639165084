import React from 'react';
import { ScrollView as NativeScrollView } from 'react-native';

export function ScrollView(props) {
  return (
    <NativeScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
      {...props}
    />
  );
}
