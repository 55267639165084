import PropTypes from 'prop-types';
import React from 'react';
import { Platform, StyleSheet, Text, View, Image } from 'react-native';
import Toolbar from '../components/layout/Toolbar';
import { COLOR_WHITE, SHADOW_STYLE } from '../constants/layout';
import Icon from './Icon';
import ProgressBar from './ProgressBar';

export function HeaderTitle({ title, subtitle }) {
  return (
    <View style={styles.titleContainer}>
      {title && (
        <Text numberOfLines={1} style={styles.title}>
          {title}
        </Text>
      )}
      {subtitle && (
        <Text numberOfLines={1} style={styles.subtitle}>
          {subtitle}
        </Text>
      )}
    </View>
  );
}

function Header({
  title = null,
  showLogo = false,
  subtitle = null,
  showBackButton = false,
  showMenuButton = false,
  onBackButtonClick,
  onMenuButtonClick,
  loading = false,
  tab
}) {
  return (
    <View style={styles.container} accessibilityTraits="header">
      <ProgressBar style={styles.progressBar} loading={loading} />
      <Toolbar
        leftIcon={
          showBackButton && (
            <Icon
              name="arrow-back"
              color={COLOR_WHITE}
              onPress={onBackButtonClick}
              flipRTL
            />
          )
        }
        rightIcon={
          showMenuButton && Platform.OS !== 'web' && (
            <Icon
              name="menu"
              color={COLOR_WHITE}
              onPress={onMenuButtonClick}
              size={28}
            />
          )
        }
        title={
          (showLogo || !!tab) && Platform.OS === 'web'
          ? <Image
            source={
              require('../assets/images/logo.svg')
            }
            style={{
              height: 30,
              width: 100,
              marginLeft: 0
            }}
          />
          : <HeaderTitle title={title} subtitle={subtitle} />
        }
      />
    </View>
  );
}

Header.propTypes = {
  parentRoute: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  onProfileButtonClick: PropTypes.func
};

export default Header;

const styles = StyleSheet.create({
  container: {
    ...SHADOW_STYLE,
    width: '100%'
  },
  titleContainer: {
    paddingHorizontal: Platform.OS === 'android' ? 16 : 0
  },
  title: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: Platform.OS === 'ios' ? 'center' : 'left'
  },
  subtitle: {
    color: '#FFF',
    fontSize: 12,
    marginTop: 2,
    textAlign: Platform.OS === 'ios' ? 'center' : 'left'
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    start: 0,
    end: 0,
    zIndex: 2
  }
});
