export const STATES = {
  NEW: 'NEW',
  READY: 'READY',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
  CANCELED_WITH_FEE: 'CANCELED_WITH_FEE'
};

export const FREQUENCIES = {
  ONCE: 'once',
  WEEKLY: 'weekly',
  BIWEEKLY: 'biweekly'
};

export const RESCHEDULING_STATES = {
  RESCHEDULABLE: 'RESCHEDULABLE',
  RESCHEDULING_REQUESTED: 'RESCHEDULING_REQUESTED',
  NOT_RESCHEDULABLE: 'NOT_RESCHEDULABLE'
};
