const uiResolver = {
  Mutation: {
    setUILoading: (_, { loading }, { cache }) => {
      const data = {
        uiState: {
          __typename: 'UIState',
          loading
        }
      };
      cache.writeData({ data });
      return data;
    },
    setDashboardTab: (_, { index }, { cache }) => {
      const data = {
        uiState: {
          __typename: 'UIState',
          dashboardTab: index
        }
      };
      cache.writeData({ data });
      return data;
    }
  }
};

export default uiResolver;
