import React from 'react';
import { View, TextInput as RnTextInput, StyleSheet, Text } from 'react-native';

import RnPicker from './layout/Picker/PickerInput';
import List from './layout/List';
import Button from './Button';
import { createCard } from './SimpleCard';
import { translate } from 'react-i18next';
import {
  COLOR_PRIMARY,
  SECONDARY_COLOR,
  COLOR_ACTION
} from '../constants/layout';
import { SALUTATION_MR, SALUTATION_MRS } from '../constants/customer';
import setupForm from '../components/form/MutatingForm';
import { saveContact } from '../graphql/contact';
import { saveContactSchema } from '../constants/validations';

const { MutatingForm, FormContext } = setupForm();

const createFormItem = (
  Consumer,
  Component,
  changeListener = 'onChange',
  valueGetter = e => e.nativeEvent.text
) => props => {
  const { name, style, ...rest } = props;

  return (
    <Consumer>
      {({ setFieldValue, values, touched, errors }) => {
        const handleChange = e => {
          setFieldValue(name, valueGetter(e));
        };
        const inputProps = {
          ...rest,
          [changeListener]: handleChange
        };
        const value = values[name];
        const error = touched[name] && errors[name] && errors[name].toString();

        return (
          <View style={styles.input}>
            <Component
              style={[error ? styles.error : null, styles.inputField]}
              value={value}
              {...inputProps}
            />
            <Text style={styles.errorText}>{error}</Text>
          </View>
        );
      }}
    </Consumer>
  );
};

const CardTextInput = createCard(RnTextInput, {
  margin: '8px',
  padding: '20px'
});
const CardPicker = createCard(RnPicker, { margin: '8px', padding: '20px' });

const TextInput = createFormItem(FormContext.Consumer, props => (
  <CardTextInput {...props} placeholderTextColor={SECONDARY_COLOR} />
));

const Picker = createFormItem(
  FormContext.Consumer,
  props => (
    <CardPicker
      {...props}
      androidStyle={null}
      placeholderTextColor={SECONDARY_COLOR}
    />
  ),
  'onValueChange',
  e => e
);

class DoMutation extends React.Component {
  componentDidMount() {
    const { mutate } = this.props;
    mutate();
  }

  render() {
    return null;
  }
}
export class WarningModalFormComponent extends React.Component {
  componentDidMount() {}
  render() {
    const { t, onSuccess, onCancel, isLoggedIn } = this.props;
    const initialValues = {
      salutation: null,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      reason: 'COVID-19'
    };
    return (
      <MutatingForm
        model="saveContact"
        onSuccess={onSuccess}
        mutation={saveContact}
        validationSchema={isLoggedIn ? null : saveContactSchema}
        initialValues={initialValues}
      >
        {(mutate, { loading }, { handleSubmit }) =>
          isLoggedIn ? (
            <DoMutation mutate={handleSubmit} />
          ) : (
            <View style={styles.container}>
              <View style={styles.row}>
                <Picker
                  items={[
                    {
                      label: t('customer.salutations.mr').toUpperCase(),
                      value: SALUTATION_MR
                    },
                    {
                      label: t('customer.salutations.mrs').toUpperCase(),
                      value: SALUTATION_MRS
                    }
                  ]}
                  style={styles.picker}
                  placeholder={t(
                    'nonOperational.form.salutation'
                  ).toUpperCase()}
                  name="salutation"
                ></Picker>
              </View>
              <View style={styles.row}>
                <TextInput
                  style={styles.input}
                  name="firstName"
                  placeholder={t('nonOperational.form.firstName').toUpperCase()}
                />
                <TextInput
                  style={styles.input}
                  name="lastName"
                  placeholder={t('nonOperational.form.lastName').toUpperCase()}
                />
              </View>
              <View style={styles.row}>
                <TextInput
                  style={styles.input}
                  name="email"
                  placeholder={t('nonOperational.form.email').toUpperCase()}
                />
              </View>
              <View style={styles.row}>
                <TextInput
                  style={styles.input}
                  placeholder={t(
                    'nonOperational.form.phoneNumber'
                  ).toUpperCase()}
                  name="phone"
                />
              </View>
              <List margin={false} style={styles.list}>
                <Button
                  cta
                  disabled={loading}
                  onPress={handleSubmit}
                  color={COLOR_PRIMARY}
                  title={t('nonOperational.form.cta')}
                />
                <Button
                  secondary
                  underline
                  style={styles.secondaryButton}
                  textColor={COLOR_PRIMARY}
                  onPress={onCancel}
                  title={t('nonOperational.form.secondary')}
                />
              </List>
            </View>
          )
        }
      </MutatingForm>
    );
  }
}

export const WarningModalForm = translate()(WarningModalFormComponent);
const styles = StyleSheet.create({
  secondaryButton: {
    alignItems: 'center'
  },
  container: {
    flex: 1,
    width: '100%'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -8,
    margin: 8,
    flexWrap: 'wrap'
  },
  input: {
    flexGrow: 1,
    width: '40%',
    minWidth: 100
  },
  inputField: {
    height: 60
  },
  picker: {
    color: 'red',
    flexGrow: 1,
    borderColor: 'transparent'
  },
  list: {
    margin: 16,
    marginTop: 16
  },
  error: {
    borderColor: COLOR_ACTION,
    borderWidth: 0,
    borderBottomWidth: 3
  },
  errorText: {
    flexGrow: 1,
    color: COLOR_ACTION,
    marginHorizontal: 16
  },
  placeholder: {
    color: SECONDARY_COLOR
  }
});
