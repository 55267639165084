import React from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity, Text } from 'react-native'
import styled, { css } from 'styled-components/native'
import Icon from './Icon'
import { COLOR_PRIMARY } from '../constants/layout'

const TouchableContainer = styled(({ stretched, color, borderColor, borderWidth, disabled, ...props }) => (
  <TouchableOpacity disabled={disabled} {...props} />
))`
  border-radius: 5px;
  ${({ stretched, color, borderColor, borderWidth, disabled }) => css`
    width: ${stretched ? '100%' : 'auto'};
    background-color: ${disabled ? COLOR_PRIMARY : color};
    border-color: ${borderColor};
    border-width: ${borderWidth};
  `}
`

const ButtonTextContainer = styled(View)`
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const ButtonText = styled(({ ref, textColor, disabled, ...props }) => <Text {...props} />)`
  font-size: 16px;
  text-align: center;
  ${({ textColor, disabled }) => css`
    color: ${textColor};
    font-weight: ${disabled ? 300 : 500};
  `};
`

const ButtonIcon = styled(({ ref, textColor, ...props }) => <Icon {...props} />)`
  margin-right: 5px;
`

const MobileButton = ({
  children,
  onPress,
  color = '#FFFFFF',
  borderColor = '#FFFFFF',
  borderWidth = 0,
  stretched = false,
  textColor = '#000000',
  icon,
  set = 'MaterialIcons',
  disabled = false,
  style = {}
}) => (
  <TouchableContainer
    onPress={onPress}
    color={color}
    borderColor={borderColor}
    borderWidth={borderWidth}
    stretched={stretched}
    disabled={disabled}
    style={style}
  >
    <ButtonTextContainer>
      {icon && (typeof icon === 'string' ? <ButtonIcon name={icon} color="#fff" set={set} /> : icon)}
      {typeof children === 'string' ? (
        <ButtonText disabled={disabled} textColor={textColor}>
          {children}
        </ButtonText>
      ) : (
        children
      )}
    </ButtonTextContainer>
  </TouchableContainer>
)

MobileButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  icon: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  set: PropTypes.string
}

const CTAButton = ({ ...props }) => {
    return <MobileButton {...props} />
}

CTAButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
  icon: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.node,
  disabled: PropTypes.bool
}

export default CTAButton
