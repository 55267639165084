import React from 'react';
import { Picker as NativePicker, Platform, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import {
  COLOR_ACTION,
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY
} from '../../../constants/layout';
import PickerInput from './PickerInput';

export default function Picker({
  label,
  value,
  items,
  placeholder,
  helperText = null,
  error,
  onChange,
  ...props
}) {
  return (
    <PickerContainer>
      <PickerLabel
        pointerEvents="none"
        floating={true}
        error={!!error}
        accessibilityRole="text"
      >
        {label}
      </PickerLabel>
      <StyledPickerInput
        onValueChange={onChange}
        selectedValue={value}
        placeholder={placeholder}
        items={items}
        {...props}
      />
      <PickerUnderline error={!!error} />
      {error && <PickerHelperText error>{error}</PickerHelperText>}
      {helperText && <PickerHelperText>{helperText}</PickerHelperText>}
    </PickerContainer>
  );
}

Picker.Item = NativePicker.Item;

const PickerContainer = styled.View`
  padding-top: 14px;
`;

const PickerLabel = styled(
  ({ floating, focused, error, ref, ...props }) => (
    <Text ref={ref} {...props} />
  )
)`
  position: absolute;
  top: 22px;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR_TEXT_SECONDARY};

  ${props =>
    props.floating &&
    css`
      top: 0;
      font-size: 12px;
      line-height: 16px;
    `};

  ${props =>
    props.focused &&
    css`
      color: ${COLOR_PRIMARY};
    `};

  ${props =>
    props.error &&
    css`
      color: ${COLOR_ACTION};
    `};
`;

const StyledPickerInput = styled(
  ({ ref, items, selectedValue, ...props }) => (
    <PickerInput
      ref={ref}
      items={items}
      selectedValue={selectedValue}
      {...props}
    />
  )
)`
  padding: 7px 0;
  ${Platform.OS === 'ios' &&
    css`
      font-size: 16px;
      line-height: 20px;
    `};
  ${Platform.OS === 'web' && 'outline: none'};
  border-radius: 0;
  border-width: 0;
  background-color: transparent;
  height: 42px;
`;

const PickerUnderline = styled(({ focused, error, ref, ...props }) => (
  <View ref={ref} {...props} />
))`
  border-top-width: 1px;
  border-top-color: ${COLOR_TEXT_SECONDARY};
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  ${props =>
    props.focused &&
    css`
      border-top-color: ${COLOR_PRIMARY};
      border-bottom-color: ${COLOR_PRIMARY};
    `};

  ${props =>
    props.error &&
    css`
      border-top-color: ${COLOR_ACTION};
      border-bottom-color: ${COLOR_ACTION};
    `};
`;

const PickerHelperText = styled(({ error, ref, ...props }) => (
  <Text ref={ref} {...props} />
))`
  margin-top: 6px;
  font-size: 12px;
  color: ${props => (props.error ? COLOR_ACTION : COLOR_TEXT_SECONDARY)};
`;
