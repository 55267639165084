import React from 'react';
import { I18nManager } from 'react-native';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import styled, { css } from 'styled-components/native';
import customIconsConfig from '../assets/icons/font/config.json';

const CustomIcons = createIconSetFromFontello(customIconsConfig, 'CustomIcons');

function Icon(props) {
  const {
    name,
    size = 24,
    color = '#000',
    style = {},
    set = 'MaterialIcons',
    flipRTL = false,
    ...rest
  } = props;

  const sizeStyle = { width: size, height: size };

  switch (set) {
    case 'MaterialCommunityIcons':
      return (
        <IconContainer flipRTL={flipRTL} style={[sizeStyle, style]}>
          <MaterialCommunityIcons
            name={name}
            size={size}
            color={color}
            {...rest}
          />
        </IconContainer>
      );
    case 'CustomIcons':
      return (
        <IconContainer flipRTL={flipRTL} style={[sizeStyle, style]}>
          <CustomIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      );
    case 'MaterialIcons':
    default:
      return (
        <IconContainer flipRTL={flipRTL} style={[sizeStyle, style]}>
          <MaterialIcons name={name} size={size} color={color} {...rest} />
        </IconContainer>
      );
  }
}

const IconContainer = styled.View`
  width: 24px;
  height: 24px;
  justify-content: center;
  ${({ flipRTL }) =>
    flipRTL &&
    I18nManager.isRTL &&
    css`
      transform: rotateY(180deg);
    `};
`;

export default Icon;
