import React from 'react';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';
import { COLOR_PRIMARY, COLOR_WHITE } from '../constants/layout';

export default function Notification({
  open = false,
  message = '',
  action = '',
  persistent = false,
  onClose = null,
  onAction = null
}) {
  return (
    open && (
      <TouchableWithoutFeedback onPress={onClose} disabled={persistent}>
        <NotificationContainer>
          <NotificationLabel>{message}</NotificationLabel>
          {!!action && (
            <TouchableOpacity activeOpacity={0.7} onPress={onAction}>
              <NotificationAction>{action}</NotificationAction>
            </TouchableOpacity>
          )}
        </NotificationContainer>
      </TouchableWithoutFeedback>
    )
  );
}

const NotificationContainer = styled.View`
  position: absolute;
  bottom: 0;
  start: 0;
  end: 0;
  flex-direction: row;
  align-items: center;
  padding: 14px 24px;
  background-color: black;
  z-index: 2;
`;

const NotificationLabel = styled.Text`
  flex: 1;
  color: ${COLOR_WHITE};
  line-height: 20px;
`;

const NotificationAction = styled.Text`
  margin-start: 24px;
  color: ${COLOR_PRIMARY};
  line-height: 20px;
  font-weight: bold;
`;
