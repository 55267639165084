import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import styled from 'styled-components/native';
import PrivateRoute from '../components/PrivateRoute';
import { getRoutes } from '../constants/routing';
import { partition } from '../helpers/helpers';
import Screen from './Screen';
import Sidebar from '../components/sidebar';

const history = createBrowserHistory();

history.listen(function(location) {
  if (process.env.REACT_APP_ANALYTICS_ID && window.gtag) {
    window.gtag('config', process.env.REACT_APP_ANALYTICS_ID, {
      page_path: location.pathname + location.search
    });
  }
});

class Router extends Component {
  renderRoutes(routes) {
    return routes.map(([path, config]) => {
      if (config.enabled !== undefined && !config.enabled) {
        return null;
      }
      const RouteComponent = config.public ? Route : PrivateRoute;
      return (
        <RouteComponent
          key={path}
          path={path}
          exact={config.exact}
          render={props => (
            <Screen showHeader={config.header} {...props}>
              <config.component {...props} />
            </Screen>
          )}
        />
      );
    });
  }

  render() {
    const [tabRoutes, stackRoutes] = partition(
      Object.entries(getRoutes()),
      ([path, config]) => config.tab
    );

    return (
      <BrowserRouter history={history}>
        <Container>
          <Switch>
            {this.renderRoutes(stackRoutes)}
            <Route
              path="/"
              render={({ match: { path } }) => (
                <React.Fragment>
                  {this.renderRoutes(tabRoutes)}
                </React.Fragment>
              )}
            />
          </Switch>
          <Sidebar />
        </Container>
      </BrowserRouter>
    );
  }
}

const Container = styled.View`
  flex: 1;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`;

export default Router;

if (module.hot) {
  module.hot.accept();
}
