export default class googleAnalytics {
  recordView() {
  }

  recordEvent(category, action, label = null, value = null) {
    window.ga = window.ga || function() {
      // eslint-disable-next-line
      (ga.q = ga.q || []).push(arguments)
    }
    // eslint-disable-next-line
    window.ga.l = +new Date;
    window.ga('send', 'event', category, action, label, value);
  }
}
