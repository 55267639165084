import { JobCancellation, OrderCancellation } from '../containers/Cancellation';
import JobRescheduling from '../containers/JobRescheduling';
import ChangeRegion from '../containers/ChangeRegion';
import Dashboard from '../containers/Dashboard';
import JobDetails from '../containers/JobDetails';
import QuickBook from '../containers/QuickBook';
import Bundles from '../containers/Bundles';
import OrderBundle from '../containers/OrderBundle';
import Login from '../containers/Login';
import Logout from '../containers/Logout';
import Payments from '../containers/Payments';
import Profile from '../containers/Profile';
import Rating from '../containers/Rating';
import Logger from '../components/Logger';
import UpdatePassword from '../containers/UpdatePassword';
import UpdatePayment from '../containers/UpdatePayment';
import UpdateProfile from '../containers/UpdateProfile';
import i18n from '../helpers/i18n';
import RatingSuccess from '../containers/RatingSuccess';
import { RateApp } from '../containers/RateApp';

export const getRoutes = () => ({
  // Tab routes
  '/': {
    component: Dashboard,
    tab: true,
    public: false,
    header: true,
    title: i18n.t('dashboard.title'),
    exact: true,
    backButton: false
  },

  // Basic routes
  '/payments': {
    component: Payments,
    public: false,
    header: true,
    title: i18n.t('payments.title'),
    exact: true,
    parent: '/profile',
    deepLink: true
  },
  '/payments/update': {
    component: UpdatePayment,
    public: false,
    header: true,
    title: i18n.t('updatePayment.title'),
    parent: '/payments'
  },
  '/profile': {
    component: Profile,
    public: false,
    header: true,
    title: i18n.t('profile.title'),
    exact: true,
    parent: '/'
  },
  '/profile/update': {
    component: UpdateProfile,
    public: false,
    header: true,
    title: i18n.t('updateProfile.title'),
    parent: '/profile'
  },
  '/profile/password': {
    component: UpdatePassword,
    public: false,
    header: true,
    title: i18n.t('updatePassword.title'),
    exact: true,
    parent: '/profile'
  },
  '/details/:reference': {
    component: JobDetails,
    public: false,
    header: true,
    title: i18n.t('jobDetails.title'),
    parent: '/',
    exact: true
  },
  '/book/:reference': {
    component: QuickBook,
    public: false,
    header: true,
    title: i18n.t('jobDetails.title'),
    parent: '/',
    exact: true
  },
  '/cancel/:reference': {
    component: JobCancellation,
    public: false,
    header: true,
    title: i18n.t('cancellation.job.title'),
    exact: true
  },
  '/cancelOrder/:reference': {
    component: OrderCancellation,
    public: false,
    header: true,
    title: i18n.t('cancellation.order.title'),
    exact: true
  },
  '/rate/:reference': {
    component: Rating,
    public: false,
    header: true,
    title: i18n.t('rating.title'),
    exact: true
  },
  '/rating-success/:reference': {
    component: RatingSuccess,
    public: false,
    header: true,
    title: false,
    showLogo: true,
    exact: true
  },
  '/rate-app': {
    component: RateApp,
    public: false,
    header: false,
    title: false,
    exact: true,
    modal: true
  },
  '/reschedule/:reference': {
    component: JobRescheduling,
    public: false,
    header: true,
    title: i18n.t('reschedule.page.title'),
    exact: true
  },
  // System routes
  '/login': {
    component: Login,
    public: true,
    title: i18n.t('login.title'),
    header: false,
    parent: '/',
    modal: true,
    system: true
  },
  '/logout': {
    component: Logout,
    public: false,
    title: i18n.t('logout.title'),
    header: false,
    parent: '/',
    modal: true,
    system: true
  },
  '/changeRegion': {
    enabled: process.env.REACT_APP_ENABLE_REGION_CHANGE === 'true',
    component: ChangeRegion,
    public: true,
    title: i18n.t('changeRegion.title'),
    header: true,
    parent: '/login',
    modal: true,
    system: true
  },
  '/logger': {
    enabled: true,
    component: Logger,
    public: true,
    title: 'Logger',
    header: true,
    parent: '/login',
    modal: true,
    system: true
  },
  '/privateLogger': {
    component: Logger,
    public: false,
    title: 'Logger',
    exact: true,
    tab: false,
    headerShadow: false
  },
  '/bundles': {
    enabled: true,
    component: Bundles,
    title: i18n.t('bundles.page.pagetitle'),
    public: true,
    header: true,
    parent: '/login',
    modal: true,
    system: true
  },
  '/bundlesPrivate': {
    component: Bundles,
    public: false,
    header: true,
    title: i18n.t('bundles.page.pagetitle'),
    exact: true,
    parent: '/'
  },
  '/bundle/:reference': {
    enabled: true,
    component: OrderBundle,
    title: i18n.t('bundles.page.details.title'),
    public: true,
    header: true,
    parent: '/bundles',
    modal: true,
    system: true
  },
  '/plans': {
    component: Bundles,
    title: i18n.t('bundles.page.pagetitle'),
    public: false,
    header: true,
    exact: true,
    parent: '/'
  },
  '/plan/:reference': {
    component: OrderBundle,
    title: i18n.t('bundles.page.details.title'),
    public: false,
    header: true,
    parent: '/plans'
  }
});
