import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { AsyncStorage } from 'react-native';
import { getEnvironmentVariable } from '../constants/environment';
import resolvers from '../resolvers';
import { getLocalTokens, checkAndRefreshAuthToken } from './auth';
import { setupCachePersistor } from './cache';
import { navigate } from './navigation';
import { networkStatusNotifierLink } from './networkStatus';
import Sentry from './sentry';
import { generateUniqueMessageId } from './helpers'
import { logRequestResult } from '../helpers/logger';

const SCHEMA_VERSION = process.env.REACT_APP_REVISION || 'Infinity';
const DISABLE_CACHE = process.env.REACT_APP_DISABLE_CACHE || false;
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

const defaults = {
  uiState: {
    __typename: 'UIState',
    loading: false,
    dashboardTab: 0
  },
  errorState: {
    __typename: 'ErrorState',
    error: false
  }
};

export default async function setupClient() {
  const cache = new InMemoryCache();

  const stateLink = withClientState({ cache, resolvers: resolvers, defaults });
  const authLink = setContext(async (request, { headers, cache }) => {
    const tokens = await checkAndRefreshAuthToken(request.operationName);
    return {
      headers: {
        ...headers,
        authorization: tokens.accessToken ? `Bearer ${tokens.accessToken}` : '',
        'X-Uid': generateUniqueMessageId()
      }
    };
  });

  const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
    let ctx = operation.getContext();
    let requestUID = (ctx.headers && ctx.headers['X-Uid']) ? ctx.headers['X-Uid'] : '';

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { message, locations, path } = error;
        // eslint-disable-next-line
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        Sentry.setTags({
          'uid': requestUID
        });
        Sentry.captureException(error);
      });
    }

    if (networkError) {
      if (networkError.message.startsWith('refreshToken')) {
        logRequestResult('setupClient: NetworkError', networkError, { error: true });
        navigate('/logout', {
          error: true
        });
      }

      // eslint-disable-next-line
      console.warn(`[Network error]: ${networkError}`);
      Sentry.setTags({
        'uid': requestUID
      });
      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Info);
        Sentry.captureException(networkError);
      });
    }

    cache.writeData({
      data: {
        errorState: {
          __typename: 'ErrorState',
          error: true
        }
      }
    });
  });

  if (!DISABLE_CACHE) {
    const cachePersistor = setupCachePersistor(cache, AsyncStorage);

    // Read the current schema version from AsyncStorage.
    const currentVersion = await AsyncStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      // If the current version matches the latest version,
      // we're good to go and can restore the cache.
      await cachePersistor.restore();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache
      // and mark ourselves as having updated to the latest version.
      await cachePersistor.purge();
      await AsyncStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  }

  const oauthFetch = createOauthFetcher(cache);

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      errorLink,
      authLink,
      stateLink,
      networkStatusNotifierLink,
      new BatchHttpLink({
        uri: getEnvironmentVariable('REACT_APP_API_URL'),
        fetch: oauthFetch
      })
    ]),
    resolvers
  });
}

function createOauthFetcher(cache) {
  return async (uri, options) => {
    const tokens = await getLocalTokens();
    if (tokens) {
      const request = fetch(uri, options);
      const response = await request;
      // We need to clone the response stream, otherwise it will break when we pass it through
      const data = await response.clone().json();
      await logRequestResult(uri, options, data);

      if (data && data.error) {
        throw new Error(`Hint: ${data.error.hint}, Code: ${data.error.code}`);
      }

      return request;
    } else {
      return [];
    }
  };
}
