import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, withApollo } from 'react-apollo';
import { Platform } from 'react-native';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import ErrorHandler from '../components/ErrorHandler';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { ScrollView } from '../components/layout/ScrollView';
import { RESCHEDULING_STATES } from '../constants/jobs';
import { COLOR_WHITE, COLOR_GRAY, COLOR_PRIMARY, COLOR_ACTION } from '../constants/layout';
import { getJob, rescheduleJob } from '../graphql/jobs.js';
import withNavigation from '../helpers/withNavigation';
import styled from 'styled-components/native';
import Notification from '../components/Notification';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import { navigateToDetails } from '../helpers/navigation';
import { formatTitle, formatTimespan } from '../helpers/formatters';

class JobReschedule extends Component {
  state = {
    loading: false,
    success: false,
    error: null
  };
  ga = new GoogleAnalytics();

  submitRescheduleRequest = () => {
    const { client, navigate, params: { reference }, t } = this.props;

    this.setState({ ...this.state, loading: true });
    client.mutate({
      mutation: rescheduleJob,
      variables: {
        reference
      }
    }).then((response) => {
      const {
        data: {
          rescheduleJob: {
            status
          }
        }
      } = response;
      if (status && status === 'SUCCESS') {
        this.setState({ ...this.state, loading: false, success: true });
        navigate('/', {
          refresh: true
        });
      }
    }).catch(error => {
      this.setState({ ...this.state, loading: t('ui.error.generic') });
    });
  }

  renderJob = ({ data: { job }, refetch, ldng }) => {
    const { t, navigate } = this.props;
    const { reschedulingState } = job;
    const { error, loading } = this.state;
    const jobIsRescheduling = reschedulingState === RESCHEDULING_STATES.RESCHEDULING_REQUESTED;
    const jobIsReschedulable = reschedulingState === RESCHEDULING_STATES.RESCHEDULABLE

    return (
      <React.Fragment>
        <PageContainer>
          <ScrollView>
            <MainImgGroup>
            <ThanksImgCenter
                source={
                Platform.OS === 'web'
                  ? require('../assets/images/reschedule/reschedule.svg')
                  : require('../assets/images/reschedule/reschedule.png')
              } />
            </MainImgGroup>

            <Title>{t('reschedule.title.text')}</Title>
            <MainText>{t('reschedule.body.text')}</MainText>
            {jobIsReschedulable && !jobIsRescheduling &&
              <CTAButton
                title={t('reschedule.cta.confirm')}
                rounded
                loading={!!loading}
                disabled={!!loading}
                gigantic
                primary
                noshadow
                bold
                onPress={() => this.submitRescheduleRequest()}
              />
            }
            {jobIsRescheduling &&
              <CTAButton
                title={t('jobDetails.cta.rescheduleInProgress')}
                rounded
                gigantic
                primary
                noshadow
                bold
                color={COLOR_GRAY}
                disabledGreyedOut
              />
            }
            <BackLink
              title={t('reschedule.cta.back')}
              transparent
              textColor={COLOR_ACTION}
              primary
              noshadow
              bold
              onPress={() => navigateToDetails(
                navigate,
                job.reference,
                formatTitle(job.bookingDetails.day, job.bookingDetails.date),
                formatTimespan(
                  job.bookingDetails.startTime,
                  job.bookingDetails.endTime
                )
              )}
              icon={
                <CTAIcon
                  color={COLOR_ACTION}
                  name="arrow-left"
                  set="MaterialCommunityIcons"
                />
              }
            />
          </ScrollView>
        </PageContainer>
        <Notification
          open={!!error}
          message={error}
          onClose={() => this.setState({ error: null })}
        />
      </React.Fragment>
    )
  };

  render() {
    const {
      params: { reference }
    } = this.props;

    return (
      <Query
        query={getJob}
        variables={{ reference }}
        fetchPolicy="cache-and-network"
        children={props => {
          const { data: { job } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!job}
              children={this.renderJob}
            />
          );
        }}
      />
    );
  }
}

JobReschedule.propTypes = {
  client: PropTypes.object,
  params: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func
}

export default compose(
  withNavigation(),
  withApollo,
  translate()
)(JobReschedule);

const PageContainer = styled.View`
  padding: 0 15px;
  background: ${COLOR_WHITE};
  display: flex;  
  flex: 1;
`;

const Title = styled.Text`
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_PRIMARY};
  text-align: center;
  margin-top: 34px;
  margin-bottom: 10px;
`;

const CTAButton = styled(props =>
  <Button {...props} />)`
  margin: 26px 0px 15px 0px;
  height: 60px;
`;

const BackLink = styled(props =>
  <Button {...props} />)`
  margin: 0px 0px 15px 0px;
`;

const CTAIcon = styled(props =>
  <Icon {...props} />)`
  justify-content: flex-start;
`;

const MainText = styled.Text`
  font-size: 15px;
  color: ${COLOR_GRAY};
  margin-top: 11px;
  flex: 1;
  text-align: center;
`;

const MainImgGroup = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-top: 55px;
  margin-bottom: 23px;
`;

const ThanksImgCenter = styled.Image`
  margin-right: 15px;
  width: 68px;
  height: 68px;
`;
