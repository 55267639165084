import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import {
  COLOR_BACKGROUND,
  COLOR_TEXT_SECONDARY,
  COLOR_WHITE
} from '../../constants/layout';
import styled, { css } from 'styled-components/native';

const Container = styled(({ white, bordered, ref, ...params }) => (
  <View ref={ref} {...params} />
))`
  top: 0;
  justify-content: center;
  padding: 12px 16px;
  margin-top: 4;
  z-index: 1;
  ${params =>
    params.white &&
    css`
      background-color: ${COLOR_WHITE};
    `};
  ${params =>
    params.bordered &&
    css`
      border-top-width: 1px;
      border-top-color: ${COLOR_BACKGROUND};
    `};
`;

const Title = styled.Text`
  color: ${COLOR_TEXT_SECONDARY};
  font-size: 16px;
  line-height: 16px;
`;

export default class ListDivider extends Component {
  componentDidMount() {
    if (Platform.OS === 'web' && this.props.sticky) {
      this._divider.setNativeProps({
        style: { position: 'sticky' }
      });
    }
  }

  render() {
    const { title, white, bordered } = this.props;
    return (
      <Container
        white={white}
        bordered={bordered}
        ref={c => (this._divider = c)}
      >
        <Title>{title}</Title>
      </Container>
    );
  }
}
