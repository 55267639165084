import { AsyncStorage } from 'react-native';

export const saveToStorage = async (key, value) => {
  return AsyncStorage.setItem(key, value);
}

export const saveMultipleToStorage = async (toSave) => {
  return AsyncStorage.multiSet(toSave);
}

export const getFromStorage = async (key) => {
  return AsyncStorage.getItem(key);
}

export const getMultipleFromStorage = async (keys) => {
  return AsyncStorage.multiGet(keys);
}

export const removeFromStorage = async (key) => {
  return AsyncStorage.removeItem(key);
}

export const removeMultiFromStorage = async (toRemove) => {
  return AsyncStorage.multiRemove(toRemove);
}
