import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import {
  Platform,
  Switch,
  Text,
  TouchableHighlight,
  StyleSheet
} from 'react-native';
import Button from '../components/Button';
import ErrorHandler from '../components/ErrorHandler';
import List from '../components/layout/List';
import ListDivider from '../components/layout/ListDivider';
import ListItem from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';
import Notification from '../components/Notification';
import RatingHeader from '../components/rating/RatingHeader';
import Stars from '../components/rating/Stars';
import TextInput from '../components/TextInput';
import {
  COLOR_RATING,
  COLOR_BACKGROUND,
  SECONDARY_COLOR,
  CARD_TEXT_COLOR
} from '../constants/layout';
import { getJob, rateJob } from '../graphql/jobs.js';
import { formatTimespan, formatTitle } from '../helpers/formatters';
import { isGreatRating } from '../helpers/isGreatRating';
import withNavigation from '../helpers/withNavigation';
import styled from 'styled-components/native';
import { SimpleCard } from '../components/SimpleCard';
import { FREQUENCIES } from '../constants/jobs';
import {
  navigateToRatingSuccess,
  navigateToRateApp
} from '../helpers/navigation';

const ListItemSeparator = styled.View`
  height: 1px;
  background-color: ${SECONDARY_COLOR};
  margin-top: -1px;
  margin-left: 15px;
  margin-right: 15px;
`;

const styles = StyleSheet.create({
  noTopMargin: {
    marginTop: 0
  },
  cardWithAction: {
    marginTop: 0,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center'
  },
  customerWasHomeText: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '500',
    color: CARD_TEXT_COLOR,
    flexBasis: '70%'
  },
  switch: {
    marginRight: 16
  },
  noPadding: {
    padding: 0
  }
});

class Rating extends Component {
  state = {
    customerAtHome: true,
    qualityRating: null,
    punctualityRating: null,
    friendlinessRating: null,
    comment: '',
    ratingError: null
  };

  handleHomeToggle = () => {
    const { customerAtHome } = this.state;
    this.setState({
      customerAtHome: !customerAtHome,
      punctualityRating: null,
      friendlinessRating: null
    });
  };

  handleRatingCompleted = (job, { rateJob: { status, message } = {} } = {}) => {
    const {
      navigate,
      params: { reference }
    } = this.props;
    const {
      bookingDetails: { frequency }
    } = job;
    if (status !== 'SUCCESS') {
      this.setState({ ratingError: message });
      return;
    }
    const isOnce = frequency === FREQUENCIES.ONCE;
    const isNative = Platform.OS === 'android' || Platform.OS === 'ios';
    const isGreatRating = this.isGreatRating();
    switch (true) {
      case isOnce && isGreatRating:
        navigateToRatingSuccess(navigate, reference);
        break;
      case !isOnce && isNative && isGreatRating:
        navigateToRateApp(navigate);
        break;
      default:
        navigate('/', { flashMessage: message });
    }
  };

  handleRatingError = e => {
    const { t } = this.props;
    this.setState({ ratingError: t('ui.error.network') });
  };

  isGreatRating() {
    const {
      customerAtHome,
      qualityRating,
      punctualityRating,
      friendlinessRating
    } = this.state;
    const arr = customerAtHome
      ? [qualityRating, punctualityRating, friendlinessRating]
      : [qualityRating];

    return isGreatRating(arr);
  }
  renderRating = ({ data: { job } }) => {
    const {
      qualityRating,
      punctualityRating,
      friendlinessRating,
      customerAtHome,
      comment,
      ratingError
    } = this.state;

    const {
      params: { reference },
      t
    } = this.props;

    const canSubmit = customerAtHome
      ? !!qualityRating && !!punctualityRating && !!friendlinessRating
      : !!qualityRating;

    return (
      <React.Fragment>
        <ScrollView>
          <RatingHeader
            cleaner={job.cleaner}
            cleaningDate={formatTitle(
              job.bookingDetails.day,
              job.bookingDetails.date
            )}
            cleaningInfo={[
              formatTimespan(
                job.bookingDetails.startTime,
                job.bookingDetails.endTime
              ),
              job.bookingDetails.formattedFrequency
            ]}
          />
          <ListDivider title={t('rating.ratingTitle')} />
          <TouchableHighlight
            onPress={this.handleHomeToggle}
            underlayColor={COLOR_BACKGROUND}
          >
            <SimpleCard style={[styles.noTopMargin, styles.cardWithAction]}>
              <Switch
                style={styles.switch}
                value={customerAtHome}
                onValueChange={() =>
                  Platform.OS !== 'web' && this.handleHomeToggle()
                }
              />
              <Text style={[styles.customerWasHomeText, { flexShrink: 1 }]}>
                {t('rating.customerWasHome')}
              </Text>
            </SimpleCard>
          </TouchableHighlight>

          <SimpleCard style={[styles.noPadding, styles.noTopMargin]}>
            <List margin={false}>
              <ListItem
                title={t('rating.qualityTitle')}
                action={
                  <Stars
                    value={qualityRating}
                    activeColor={COLOR_RATING}
                    size={28}
                    onSelect={score => this.setState({ qualityRating: score })}
                  />
                }
              />
              <ListItemSeparator />
              <ListItem
                title={t('rating.punctualityTitle')}
                style={!customerAtHome && { opacity: 0.5 }}
                action={
                  <Stars
                    value={punctualityRating}
                    activeColor={COLOR_RATING}
                    size={28}
                    disabled={!customerAtHome}
                    onSelect={score =>
                      this.setState({ punctualityRating: score })
                    }
                  />
                }
              />
              <ListItemSeparator />
              <ListItem
                title={t('rating.friendlinessTitle')}
                style={!customerAtHome && { opacity: 0.5 }}
                action={
                  <Stars
                    value={friendlinessRating}
                    activeColor={COLOR_RATING}
                    size={28}
                    disabled={!customerAtHome}
                    onSelect={score =>
                      this.setState({ friendlinessRating: score })
                    }
                  />
                }
              />
            </List>
          </SimpleCard>
          <List margin={false}>
            <ListDivider title={t('rating.commentsTitle')} />
            <SimpleCard style={[styles.noPadding, styles.noTopMargin]}>
              <TextInput
                placeholder={t('rating.commentsPlaceholder')}
                multiline
                numberOfLines={4}
                onChangeText={text => this.setState({ comment: text })}
                value={comment}
              />
            </SimpleCard>
          </List>
        </ScrollView>
        <Mutation
          mutation={rateJob}
          refetchQueries={['getJob']}
          onCompleted={(...args) => this.handleRatingCompleted(job, ...args)}
          onError={this.handleRatingError}
        >
          {(rateJob, { loading, error }) => (
            <React.Fragment>
              <Button
                transparent
                gigantic
                color={COLOR_RATING}
                disabled={loading || !canSubmit}
                title={t('rating.submit')}
                onPress={() => {
                  rateJob({
                    variables: {
                      reference,
                      customerAtHome,
                      quality: qualityRating,
                      punctuality: punctualityRating,
                      friendliness: friendlinessRating,
                      comment
                    }
                  });
                }}
              />
              <Notification
                open={!!ratingError}
                message={ratingError}
                onClose={() => this.setState({ ratingError: null })}
              />
            </React.Fragment>
          )}
        </Mutation>
      </React.Fragment>
    );
  };

  render() {
    const {
      params: { reference }
    } = this.props;

    return (
      <Query
        query={getJob}
        variables={{ reference }}
        fetchPolicy="cache-and-network"
      >
        {props => {
          const { data: { job } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!job}
              children={this.renderRating}
            />
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(Rating);
