import gql from 'graphql-tag';

const transactionFields = gql`
  fragment transactionFields on Transaction {
    id: reference
    date(format: DATE_SHORT)
    month: date(format: MONTH)
    amount {
      formatted
    }
    invoice
    details {
      appointmentStart(format: DATE_SHORT)
      weekday: appointmentStart(format: DAY)
      frequency
      formattedFrequency: frequency(translate: true)
    }
  }
`;

export const getPayments = gql`
  query getPayments($skip: Int!, $limit: Int!) {
    wallet {
      balance {
        raw
        formatted
      }

      successfulPayments: payments(
        state: SUCCESSFUL
        skip: $skip
        limit: $limit
      ) {
        ...transactionFields
      }

      failedPayments: payments(state: FAILED) {
        ...transactionFields
      }
    }
  }
  ${transactionFields}
`;

export const getFailedPayments = gql`
  query getFailedPayments {
    wallet {
      failedPayments: payments(state: FAILED) {
        ...transactionFields
      }
    }
  }
  ${transactionFields}
`;

export const addCreditCard = gql`
  mutation addCreditCard(
    $encrypted: String!
    $generationTime: String!
    $truncated: String!
    $ckoCardToken: String!
  ) {
    addCreditCard(
      encrypted: $encrypted
      generationTime: $generationTime
      truncated: $truncated
      ckoCardToken: $ckoCardToken
    ) {
      status
      message
    }
  }  
`;
