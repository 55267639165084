import React from 'react';
import { View } from 'react-native';

const List = ({ margin = true, style = {}, ...props }) => (
  <View
    {...props}
    style={[
      {
        marginTop: margin ? 8 : 0,
        marginBottom: 8,
        borderTopWidth: 0,
        backgroundColor: 'transparent'
      },
      style
    ]}
  />
);

export default List;
