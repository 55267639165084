import React from 'react';
import { View, Text, Image, StyleSheet, Platform } from 'react-native';
import propTypes from 'prop-types';
import List from './layout/List';

import Button from './Button';
import { COLOR_PRIMARY, COLOR_INACTIVE, COLOR_GRAY } from '../constants/layout';

const imageWidth = 139;
const imageHeight = 109;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    width: '100%'
  },
  wrapper: {
    padding: 0,
    flexGrow: 1,
    backgroundColor: COLOR_INACTIVE,
    justifyContent: 'center'
  },
  container: {
    alignItems: 'center',
    width: '100%',
    padding: 0,
    flexGrow: 1
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: imageWidth,
    maxWidth: '100%',
    width: 410
  },
  image: {
    zIndex: 1,
    width: imageWidth,
    height: imageHeight,
    marginBottom: 20,
    alignSelf: 'center'
  },
  headline: {
    textAlign: 'center',
    fontWeight: '800',
    color: COLOR_PRIMARY,
    fontSize: 18,
    lineHeight: 26,
    marginBottom: 8
  },
  text: {
    textAlign: 'center',
    color: COLOR_GRAY,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 8
  }
});
export class ModalContent extends React.Component {
  static propTypes = {
    onPress: propTypes.func,
    headline: propTypes.string,
    text: propTypes.string,
    buttons: propTypes.arrayOf(
      propTypes.shape({
        onPress: propTypes.func,
        color: propTypes.string,
        title: propTypes.string,
        textColor: propTypes.string,
        cta: propTypes.bool,
        secondary: propTypes.bool,
        underline: propTypes.bool
      })
    ),
    image: Platform.OS === 'web' ? propTypes.string : propTypes.number
  };

  render() {
    const { headline, children, buttons = [], image } = this.props;
    return (
      <>
        <View>
          <Image style={styles.image} source={image} />
          <Text style={styles.headline}>{headline}</Text>
          <Text style={styles.text}>
            {children}
          </Text>
        </View>
        <List margin={false} style={styles.list}>
          {buttons.map((button, index) => (
            <Button key={index} {...button} />
          ))}
        </List>
      </>
    );
  }
}
