import React from 'react';
import {
  COLOR_PRIMARY,
  COLOR_GRAY
} from '../../constants/layout';
import Icon from '../Icon';
import styled from 'styled-components/native';

export default function USPs({ title, options, outterStyle }) {
  return (
    <Container style={{ ...outterStyle }}>
      {title && <Title>{title}</Title>}
      {options &&
        <List>
        {
          options.map((label, i) =>
            (<ListItem key={i}>
              <Icon name="done" color={COLOR_PRIMARY} size={24}/>
              <Label>{label}</Label>
            </ListItem>)
          )
        }
      </List>
      }
    </Container>
  );
}

const Container = styled.View`
  width: 100%;
  justify-content: center;
`;

const List = styled.View`
  justify-content: center;
  align-items: center
`;

const ListItem = styled.View`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Title = styled.Text`
  margin-left: 10px;
  margin-right: 10px;
  marginBottom: 23px;
  font-size: 15px;
  line-height: 17px;
  color: ${COLOR_GRAY};
  text-align: center;
`;

const Label = styled.Text`
  margin-left: 10px;
  font-weight: 300;
  font-size: 15px;
`;
