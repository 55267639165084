import React, { Component } from 'react';
import {
  Text,
  TouchableHighlight,
  View
} from 'react-native';
import {
  CARD_CHEVRON_COLOR,
  COLOR_WHITE,
  COLOR_GRAY
} from '../../../constants/layout';
import Icon from '../../Icon';
import cardStyles, { cardStyleRules } from './CardStyles';
import PropTypes from 'prop-types';

class Card extends Component {
  render() {
    const {
      title,
      titleSize,
      introText = '',
      body,
      children,
      icon,
      buttons = [],
      onPress,
      displayChevron,
      marginTop = 0,
      bottomMargin = true
    } = this.props;

    return (
      <View style={{ backgroundColor: COLOR_WHITE, marginTop: marginTop, zIndex: 1 }}>
        {introText.length > 0 && <Text style={cardStyles.cardTitle}>{introText} </Text>}
        <View style={bottomMargin ? cardStyles.containerWithBottomMargin : cardStyles.container}>
          <TouchableHighlight
            onPress={onPress}
            disabled={!onPress}
            activeOpacity={0.8}
            underlayColor={COLOR_WHITE}
          >
            <View style={cardStyles.card}>
              { icon &&
                <Icon
                  name={icon.name}
                  set={icon.set}
                  size={icon.size}
                  style={{
                    ...icon.iconBubbleColor ? cardStyleRules.cardIconwithBubble : cardStyleRules.cardIcon,
                    backgroundColor: icon.iconBubbleColor ? icon.iconBubbleColor : 'none' }}
                  color={icon.iconColor}
                  flipRTL
                />
              }
              <View style={cardStyles.content}>
                {
                  !!title &&
                  <Text style={[onPress ? cardStyles.titleWithAction : cardStyles.title, { fontSize: titleSize }]}>
                    {title}
                  </Text>
                }
                {
                  !!body &&
                  <Text style={onPress ? cardStyles.bodyWithAction : cardStyles.body}>
                    {body}
                  </Text>
                }
                {
                  !!children && children
                }
                {
                  displayChevron &&
                  <Icon
                    name="chevron-right"
                    set='MaterialCommunityIcons'
                    size={28}
                    style={ buttons.length ? cardStyles.chevronWithButtons : cardStyles.chevron}
                    flipRTL
                    color={CARD_CHEVRON_COLOR}
                  />
                }
                {
                  (buttons && buttons.length > 0) && <View style={cardStyles.horizontalSeparator} />
                }
                <View style={cardStyles.buttonsContainer}>
                {
                  (buttons && buttons.length > 0) && buttons.map((button, i) => {
                    const ButtonComponent = button.renderComponent || TouchableHighlight;
                    return (
                    <View
                      style={i === 0 ? cardStyles.buttonOutsideLeft : cardStyles.buttonOutsideRight}
                      key={i}
                    >
                      <ButtonComponent
                        component={button.renderComponent ? TouchableHighlight : undefined }
                        style={cardStyles.buttonTouch}
                        onPress={button.onPress}
                        disabled={!button.onPress}
                        activeOpacity={0.8}
                        underlayColor={COLOR_WHITE}
                      >
                        <View style={cardStyles.button}>
                          <Icon
                            name={button.iconName}
                            set={button.iconSet}
                            size={button.iconSize}
                            style={cardStyles.buttonIcon}
                            flipRTL
                            color={button.iconColor}
                          />
                          <Text style={{ ...cardStyleRules.buttonText, color: button.iconColor }}>
                            {button.text}
                          </Text>
                        </View>
                      </ButtonComponent>
                      {
                        (i === 0 && buttons.length > 1) &&
                        <View style={cardStyles.vertical}>
                          <Text style={{ ...cardStyleRules.buttonText, color: COLOR_GRAY }}>
                            |
                          </Text>
                        </View>
                      }
                    </View>);
                  })
                }
              </View>
            </View>
          </View>
        </TouchableHighlight>
      </View>
    </View>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  titleSize: PropTypes.number,
  marginTop: PropTypes.number,
  body: PropTypes.string,
  icon: PropTypes.object,
  introText: PropTypes.string,
  onPress: PropTypes.func
};

export default (Card);
