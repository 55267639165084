import React from 'react';
import flowRight from 'lodash/flowRight'
import { translate } from 'react-i18next';

import { RateAppModal } from '../components/rateAppModal';
import withNavigation from '../helpers/withNavigation';

class RateAppComponent extends React.Component {
  onPress = () => {
    const { navigate } = this.props;
    navigate('/', { refresh: true });
  };
  render() {
    const { t } = this.props;
    return <RateAppModal t={t} onPress={this.onPress} />;
  }
}

export const RateApp = flowRight(
  translate(),
  withNavigation()
)(RateAppComponent)
