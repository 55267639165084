import React from 'react';
import { Picker } from 'react-native';

export default function PickerInput({ items, placeholder, ...props }) {
  return (
    <Picker {...props}>
      {[
        ...(placeholder
          ? [<Picker.Item key={null} value={null} label={placeholder} />]
          : []),
        ...items.map(({ value, label }) => (
          <Picker.Item key={value} value={value} label={label} />
        ))
      ]}
    </Picker>
  );
}
