export const COLOR_PRIMARY = '#52B68D';
export const SECONDARY_COLOR = '#999999';
export const COLOR_SECONDARY_KSA = '#fbb03b';
export const COLOR_TERTIARY = '#6798DD';
export const COLOR_BACKGROUND = '#FAFAFA';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_TEXT_PRIMARY = '#000000DE';
export const COLOR_TEXT_SECONDARY = '#0000008A';
export const COLOR_ACTION = '#FF7275';
export const COLOR_CANCEL_ACTION = '#de6063';
export const COLOR_LIST_ITEM_ACTION = '#444444';
export const COLOR_ACTION_LIGHT = '#ff898b';
export const COLOR_ACTION_DISABLED = '#FF9C9E';
export const COLOR_RATING = '#FBB13B';
export const COLOR_SHADOW = '#000000';
export const COLOR_BACKGROUND_LIGHT_GREEN = '#a9dbc6';
export const CARD_TEXT_COLOR = '#444444';
export const CARD_CHEVRON_COLOR = '#999999';
export const CARD_UNDERLAY_COLOR = '#FAFAFA';
export const COLOR_GRAY = '#444444';
export const COLOR_GRAYOUT = '#CCC';
export const COLOR_SUBTITLE = '#90a1b5'
export const COLOR_GOLD = '#dac269';
export const COLOR_DIVIDER = '#dddddd';
export const COLOR_INACTIVE = '#d8d8d8';

export const SHADOW_STYLE = {
  zIndex: 1,
  elevation: 4,
  shadowRadius: 2,
  shadowOffset: {
    width: 0,
    height: 2
  },
  shadowOpacity: 0.2,
  shadowColor: COLOR_SHADOW
};

export const TAB_BAR_STYLE = {
  borderBottomStyle: 'solid',
  borderBottomColor: '#ccc'
}

export const NO_SHADOW_STYLE = {
  zIndex: 1,
  elevation: 0,
  shadowRadius: 0,
  shadowOffset: {
    width: 0,
    height: 0
  },
  shadowOpacity: 0,
  shadowColor: 'transparent'
};
