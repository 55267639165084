import { ACTION_OPEN_FUNNEL, ACTION_RATE_APP } from '../constants/actions';
import { openWebpage } from './navigation';
import { getEnvironmentVariable } from '../constants/environment';
import { Linking, Platform } from 'react-native';
import {
  appStoreNativeURL,
  appStoreWebURL,
  playstoreStoreNativeURL,
  playstoreStoreWebURL
} from '../constants/appstore';

export function executeAction(action, payload) {
  switch (action) {
    case ACTION_OPEN_FUNNEL:
        actionOpenFunnel(payload);
    break;

    case ACTION_RATE_APP:
        actionOpenStore();
    break;

    default:
    break;
  }
}

export function actionOpenFunnel(payload = null) {
  openWebpage(
    `${getEnvironmentVariable(
      'REACT_APP_WEB_URL'
    )}/checkout/details?vc=${payload.code || ''}`,
    true
  )
}

export function actionOpenStore() {
  if (Platform.OS === 'android') {
    actionOpenPlayStore();
  }
  if (Platform.OS === 'ios') {
    actionOpenAppStore();
  }
}

export function actionOpenAppStore() {
  Linking.canOpenURL(appStoreNativeURL).then(
    supported => {
      if (supported) {
        Linking.openURL(appStoreNativeURL);
      } else {
        Linking.openURL(appStoreWebURL);
      }
    }
  );
}

export function actionOpenPlayStore() {
  Linking.canOpenURL(playstoreStoreNativeURL).then(
    supported => {
      if (supported) {
        Linking.openURL(playstoreStoreNativeURL);
      } else {
        Linking.openURL(playstoreStoreWebURL);
      }
    }
  );
}
