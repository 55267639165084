import gql from 'graphql-tag';

export const setLoadingState = (client, loading) => {
  const mutation = gql`
  mutation setLoadingState($loading: Boolean!) {
    setUILoading(loading: $loading) @client
  }
  `;
  return client.mutate({
    mutation,
    variables: { loading }
  })
}

export const getLoadingState = client => {
  const query = gql`
  query getLoadingState {
    uiState @client {
      loading
    }
  }
  `;
  return client.query({ query })
}

export const setDashboardTab = gql`
  mutation setDashboardTab($index: Integer!) {
    setDashboardTab(index: $index) @client
  }
`;

export const getDashboardTab = gql`
  query getDashboardTab {
    uiState @client {
      dashboardTab
    }
  }
`;
