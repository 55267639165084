import React, { Component } from 'react';
import { Platform, Text, View } from 'react-native';
import styled, { css } from 'styled-components/native';
import {
  COLOR_ACTION,
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY
} from '../../constants/layout';

const TextFieldContainer = styled.View`
  padding-top: 14px;
`;

const TextFieldLabel = styled(
  ({ floating, focused, error, innerRef, ...props }) => (
    <Text ref={innerRef} {...props} />
  )
)`
  position: absolute;
  top: 22px;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR_TEXT_SECONDARY};

  ${props =>
    props.floating &&
    css`
      top: 0;
      font-size: 12px;
      line-height: 16px;
    `};

  ${props =>
    props.focused &&
    css`
      color: ${COLOR_PRIMARY};
    `};

  ${props =>
    props.error &&
    css`
      color: ${COLOR_ACTION};
    `};
`;

const TextFieldInput = styled.TextInput`
  flex: 1;
  flex-shrink: 0;
  padding: 7px 0;
  font-size: 16px;
  line-height: 20px;
  min-height: 42px;
  ${Platform.OS === 'web' && 'outline-width: 0px'};
`;

const TextFieldUnderline = styled(({ focused, error, innerRef, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  border-top-width: 1px;
  border-top-color: ${COLOR_TEXT_SECONDARY};
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  ${props =>
    props.focused &&
    css`
      border-top-color: ${COLOR_PRIMARY};
      border-bottom-color: ${COLOR_PRIMARY};
    `};

  ${props =>
    props.error &&
    css`
      border-top-color: ${COLOR_ACTION};
      border-bottom-color: ${COLOR_ACTION};
    `};
`;

const TextFieldHelperText = styled(({ error, innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  margin-top: 6px;
  font-size: 12px;
  color: ${props => (props.error ? COLOR_ACTION : COLOR_TEXT_SECONDARY)};
`;

export default class TextField extends Component {
  state = {
    focused: this.props.autoFocus
  };

  componentDidMount() {
    if (this._input) {
      this.setState({ focused: this._input.isFocused() });
    }
  }

  render() {
    const {
      label,
      value,
      placeholder,
      helperText,
      error,
      onChange,
      innerRef,
      ...props
    } = this.props;
    const { focused } = this.state;
    const labelIsFloating = !!value || focused || placeholder;

    return (
      <TextFieldContainer>
        <TextFieldLabel
          pointerEvents="none"
          floating={labelIsFloating}
          focused={focused}
          error={!!error}
          accessibilityRole="text"
        >
          {label}
        </TextFieldLabel>
        <TextFieldInput
          innerRef={ref => {
            this._input = ref;
            innerRef && innerRef(ref);
          }}
          onChangeText={onChange}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          value={value}
          placeholder={placeholder}
          underlineColorAndroid="transparent"
          {...props}
        />
        <TextFieldUnderline focused={focused} error={!!error} />
        {error && <TextFieldHelperText error>{error}</TextFieldHelperText>}
        {helperText && <TextFieldHelperText>{helperText}</TextFieldHelperText>}
      </TextFieldContainer>
    );
  }
}
