import React, { Component } from 'react';
import { AsyncStorage } from 'react-native';
import Redirect from '../components/routing/Redirect';
import getDisplayName from './getDisplayName';

export default function withAuthentication({ isPublic = false }) {
  return WrappedComponent =>
    (class extends Component {
      static displayName = `WithAuthentication(${getDisplayName(
        WrappedComponent
      )})`;

      state = {
        token: undefined
      };

      async componentDidMount() {
        const token = await AsyncStorage.getItem('AUTH_ACCESS_TOKEN');
        if (!this._unmounted) {
          this.setState({ token });
        }
      }

      componentWillUnmount() {
        this._unmounted = true;
      }

      render() {
        const { location = {} } = this.props;
        const { token } = this.state;

        if (token === undefined) {
          return null;
        }

        if (isPublic || !!token) {
          return <WrappedComponent {...this.props} />
        } else {
          return <Redirect to={{ ...location, redirectPathname: this.props.path, pathname: '/login', disableAuth: true }} />
        }
      }
    });
}
