import React, { Component } from 'react';
import {
  Text,
  TouchableHighlight,
  View
} from 'react-native';
import {
  COLOR_WHITE, COLOR_GRAY
} from '../../../constants/layout';
import Icon from '../../Icon';
import cardStyles from './CardStyles';
import PropTypes from 'prop-types';

class DetailsCard extends Component {
  state = {
    showingDetails: true
  };

  toggleDetails() {
    this.setState({ showingDetails: !this.state.showingDetails });
  }

  render() {
    const {
      title,
      titleSize,
      marginTop = 0,
      body,
      icon,
      introText = '',
      expandable,
      onPress
    } = this.props;

    const { showingDetails } = this.state;

    return (
      <View style={{ backgroundColor: COLOR_WHITE, marginTop: marginTop }}>
        {introText.length > 0 && <Text style={cardStyles.cardTitle}>{introText}: </Text>}
        <View style={cardStyles.detailsCardContainer}>
            <View style={cardStyles.card}>
              { !!icon &&
                <Icon
                  name={icon.name}
                  set={icon.set}
                  size={icon.size}
                  style={icon.iconBubbleColor ? cardStyles.cardIconwithBubble : cardStyles.cardIcon}
                  color={icon.iconColor}
                  flipRTL
                />
              }
              <View style={cardStyles.content}>
                {
                  !!title &&
                    <Text style={{ ...onPress ? cardStyles.titleWithAction : cardStyles.title, fontSize: titleSize }}>
                      {title}:
                    </Text>
                }
                {
                  !!expandable &&
                    <Icon
                      set='MaterialCommunityIcons'
                      name={showingDetails ? 'chevron-down' : 'chevron-up'}
                      style={cardStyles.expandableChevron}
                      color={COLOR_GRAY}
                      onPress={() => this.toggleDetails()}
                    />
                }
                {
                  !!title &&
                    <View style={cardStyles.horizontalSeparator} />
                }
                {
                  (body && body.length) &&
                  <View style={cardStyles.detailsBody}>
                    {
                      (body && body.length) &&
                        body.map((el, i) => {
                        return (
                          <View
                            style={
                              !expandable || (expandable && !showingDetails) || !el.hideable
                              ? cardStyles.infoRow
                              : cardStyles.hiddenRow
                            }
                            key={i}
                          >
                            {
                              !!el.title &&
                                <Text style={cardStyles.infoRowTitle}>{el.title}</Text>
                            }
                            {
                              !!el.icon &&
                                el.icon
                            }
                            {
                              el.onPress
                              ? (<TouchableHighlight
                                  style={cardStyles.infoRowHighlight}
                                  onPress={el.onPress}
                                  activeOpacity={0.8}
                                  underlayColor={COLOR_WHITE}
                                >
                                  <Text>{el.content}</Text>
                                </TouchableHighlight>
                              )
                              : (
                                <Text style={cardStyles.infoRowContentText}>{el.content}</Text>
                              )
                            }
                          </View>
                        )
                      })
                    }
                  </View>
                }
            </View>
          </View>
      </View>
    </View>
    );
  }
}

DetailsCard.propTypes = {
  title: PropTypes.string,
  titleSize: PropTypes.number,
  marginTop: PropTypes.number,
  body: PropTypes.array,
  icon: PropTypes.func,
  introText: PropTypes.string,
  expandable: PropTypes.bool,
  onPress: PropTypes.func
};
export default (DetailsCard);
