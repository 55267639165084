import React from 'react';
import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import {
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_GOLD
} from '../../constants/layout';

const ChoiceGroup = ({
  choices = [],
  index = 0,
  style,
  onChange = (e, index) => {}
}) => {
  return (
    <ChoiceContainer style={style}>
        {choices.map((option, tabIndex) => {
          const active = tabIndex === index;
          return (
            <Highlight key={tabIndex} onPress={e => onChange(e, tabIndex)}>
              <Choice active={active}>
                  <Label active={active} numberOfLines={1}>
                    {option.label}
                  </Label>
                  {option.subtitle && <Subtitle active={active} numberOfLines={1}>{option.subtitle}</Subtitle>}
              </Choice>
            </Highlight>
          );
        })}
    </ChoiceContainer>
  );
};

export default ChoiceGroup;

const ChoiceContainer = styled.View`
  margin: 22px;
  background-color: ${COLOR_WHITE};
  align-items: stretch;
  min-height: 60px;
  border-radius: 4px;
  border: 1px solid ${COLOR_PRIMARY};
  display: flex;
  flex-direction: row;
`;

const Highlight = styled.TouchableHighlight`
  flex: 1;
  width: 50%;
`;

const Choice = styled(({ active, innerRef, ...props }) => (
  <View ref={innerRef} {...props} />
))`
  width: 100%;
  padding: 12px 12px;
  flex: 1;
  justify-content: center;
  background-color: ${({ active }) => (active ? COLOR_PRIMARY : COLOR_WHITE)};
`;

const Label = styled(({ active, innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  color: ${({ active }) => (active ? COLOR_WHITE : COLOR_PRIMARY)};
  font-weight: bold;
  font-size: 13px;
  letter-spacing: -0.08px;
  text-align: center;
  line-height: 20px;
  width: 100%;
  
`;

const Subtitle = styled(({ active, innerRef, ...props }) => (
  <Text ref={innerRef} {...props} />
))`
  color: ${COLOR_GOLD}
  font-weight: bold;
  font-size: 11px;
  letter-spacing: -0.07px;
  text-align: center;
  line-height: 11px;
  
  min-height: 13px;
`;
