import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorHandler from '../ErrorHandler';
import { ScrollView } from '../layout/ScrollView';
import NextCleaning from './cards/NextCleaning';
import LastCleaning from './cards/LastCleaning';
import { COLOR_ACTION, COLOR_WHITE } from '../../constants/layout';
import { getFutureJobs, getPastJobs } from '../../graphql/jobs.js';
import { hasActiveBundlebookings } from '../../graphql/bundles.js';
import { getFailedPayments } from '../../graphql/wallet.js';
import { formatTimespan, formatTitle } from '../../helpers/formatters';
import { STATES, RESCHEDULING_STATES } from '../../constants/jobs';
import PushNotificationCard from './cards/PushNotificationCard';
import RescheduleInProgressCard from './cards/RescheduleInProgressCard';
import USPs from './USPs';
import PushNotificationsHandler from '../../components/PushNotificationsHandler';
import { navigateToDetails } from '../../helpers/navigation';
import Card from '../layout/Card/Card';
import { executeAction } from '../../helpers/actions';
import withNavigation from '../../helpers/withNavigation';
import { flowRight as compose } from 'lodash';
import { plansPath } from '../../helpers/urls';
import { translate } from 'react-i18next';
import Announcement from './cards/Announcement';
import { getRegion } from '../../constants/environment';
import Sentry from '../../helpers/sentry';
import { WarningModalTrigger } from '../WarningModalTrigger';

class Overview extends Component {
  state = {
    notification: false,
    consoleClicks: 0,
    data: {
      futureJobs: {
        data: {
          jobs: []
        }
      },
      pastJobs: {
        data: {
          jobs: []
        }
      },
      failedPayments: {
        data: {
          jobs: []
        }
      },
      hasActiveBundleBooking: null
    }
  };

  constructor(props) {
    super(props);
    this._handleConsoleClick = this._handleConsoleClick.bind(this);
    this.refetch = this.refetch.bind(this);
    this.displayAlternativeScreen = getRegion() === 'sa';
  }

  componentDidMount() {
    this.refetch();
  }

  componentDidUpdate() {
    const { params: { refresh = false } } = this.props;
    if (refresh) {
      this.refetch();
    }
  }

  refetch() {
    const { client } = this.props;
    const { displayAlternativeScreen } = this;

    try {
      client.query({ query: getFutureJobs, fetchPolicy: 'network-only' }).then(response => {
        this.props.params.refresh = false;
        this.setState({
          ...this.state,
          data: { ...this.state.data, futureJobs: response }
        });
      }).then(() =>
        client.query({ query: getPastJobs, fetchPolicy: 'network-only' }).then(response =>
          this.setState({
            ...this.state,
            data: { ...this.state.data, pastJobs: response }
          }))
      ).then(() =>
        client.query({ query: getFailedPayments, fetchPolicy: 'network-only' }).then(response => {
          this.setState({
            ...this.state,
            data: { ...this.state.data, failedPayments: response }
          });
        })
      ).then(() => {
        if (displayAlternativeScreen) {
          client.query({ query: hasActiveBundlebookings, fetchPolicy: 'network-only' }).then(response => {
            this.setState({
              ...this.state,
              data: { ...this.state.data, hasActiveBundleBooking: response.data.atLeastOneActiveBundleBooking }
            });
          });
        }
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  _handleConsoleClick() {
    const { navigate } = this.props;
    this.setState({
      ...this.state,
      consoleClicks: this.state.consoleClicks + 1
    });
    if (this.state.consoleClicks >= 9) {
      navigate('/privateLogger');
      this.setState({ consoleClicks: 0 });
    }
  }

  _receivedPushNotification(notification) {
    const { navigate } = this.props;

    this.setState({ ...this.state, notification: notification });
    if (notification.origin === 'selected') {
      navigate(`/`, {
        notification: notification
      });
    }
  }

  _pressedPushNotification() {
    const { notification: { data } } = this.state;

    if (data && data.action) {
      executeAction(data.action, data.payload || null);
    }
    this.setState({ ...this.state, notification: null });
  }

  LastExecutedJobCard = () => {
    const { data } = this.state;
    const { navigate } = this.props;

    if (!data || !data.pastJobs) {
      return null;
    }

    const pastJobs = data.pastJobs;
    const { data: { jobs = [] } = {} } = pastJobs;

    if (!jobs || !jobs.length) {
      return null;
    }

    const lastJob = jobs.find(job => {
      return job.state === STATES.DONE;
    });

    if (!lastJob || !lastJob.reference) {
      return null;
    }

    return (
      <LastCleaning
        onPress={() => {
          if (!lastJob.reference) {
            return false;
          }
          return navigateToDetails(
            navigate,
            lastJob.id,
            formatTitle(
              lastJob.bookingDetails.day,
              lastJob.bookingDetails.date
            ),
            formatTimespan(
              lastJob.bookingDetails.startTime,
              lastJob.bookingDetails.endTime
            )
          );
        }}
        id={lastJob.reference}
        displayRatingButton={lastJob.rating === null}
        frequency={lastJob.bookingDetails.frequency}
        frequencyLabel={lastJob.bookingDetails.formattedFrequency}
        day={lastJob.bookingDetails.day}
        date={lastJob.bookingDetails.date}
        start={lastJob.bookingDetails.start}
        end={lastJob.bookingDetails.end}
        startTime={lastJob.bookingDetails.startTime}
        endTime={lastJob.bookingDetails.endTime}
        serviceType={lastJob.bookingDetails.serviceType}
        navigate={navigate}
      />
    );
  };

  renderUpdatePaymentButton = () => {
    const { navigate, t } = this.props;
    const { data } = this.state;

    if (!data || !data.pastJobs) {
      return null;
    }

    const { data: { wallet: { failedPayments = [] } = {} } = {} } = data;

    if (!failedPayments || !failedPayments.length) {
      return null;
    }

    return (
      <Card
        title={t('payments.pendingTitle')}
        body={t('updatePayment.updateCreditCardNow')}
        titleSize={14}
        icon={{
          name: 'error',
          iconSize: 20,
          iconColor: COLOR_ACTION
        }}
        buttons={false}
        cardStyle={false}
        onPress={() => navigate('/payments/update')}
        displayChevron={true}
      />
    );
  };

  render() {
    const { t, navigate } = this.props;
    const { LastExecutedJobCard } = this;
    const { notification, data: { pastJobs, futureJobs, hasActiveBundleBooking } } = this.state;
    const { data: { jobs } = {}, loading } = futureJobs;
    const { data: { jobs: pjobs } = {} } = pastJobs;
    const nextJob = jobs.length ? jobs[0] : null;
    const lastJob = pjobs.find(job => (job.state === STATES.DONE && !!job.reference));
    const rescheduleJob =
      jobs.find(job =>
        job.reschedulingState === RESCHEDULING_STATES.RESCHEDULING_REQUESTED && !!job.reference
      );
    const rescheduleInProgress = !!rescheduleJob;
    const isDashboardEmpty =
      !nextJob && !lastJob && !notification && !rescheduleInProgress &&
      !(hasActiveBundleBooking === false && this.displayAlternativeScreen);

    return (
      <ErrorHandler {...this.props} dataIsAvailable={!!jobs}>
        {() => {
          return (
            <ScrollView
              refresh={this.refetch}
              loading={loading}
              style={{ backgroundColor: COLOR_WHITE }}
            >
              <PushNotificationsHandler
                notify={this._receivedPushNotification.bind(this)}
              />
              {notification && (
                <PushNotificationCard
                  notification={notification}
                  onPress={this._pressedPushNotification.bind(this)}
                />
              )}
              {
                (hasActiveBundleBooking === false && this.displayAlternativeScreen) &&
                <Announcement
                  navigate={navigate}
                />
              }
              {rescheduleJob && (
                <RescheduleInProgressCard
                  jobDate={rescheduleJob.bookingDetails.start}
                />
              )}
              {this.renderUpdatePaymentButton()}
              {nextJob && (
                <NextCleaning
                  navigate={navigate}
                  onPress={() => {
                    if (!nextJob.reference) {
                      return false;
                    }
                    return navigateToDetails(
                      navigate,
                      nextJob.reference,
                      formatTitle(
                        nextJob.bookingDetails.day,
                        nextJob.bookingDetails.date
                      ),
                      formatTimespan(
                        nextJob.bookingDetails.startTime,
                        nextJob.bookingDetails.endTime
                      )
                    );
                  }}
                  reference={nextJob.reference}
                  frequency={nextJob.bookingDetails.frequency}
                  frequencyLabel={
                    nextJob.bookingDetails.formattedFrequency
                  }
                  day={nextJob.bookingDetails.day}
                  date={nextJob.bookingDetails.date}
                  startTime={nextJob.bookingDetails.startTime}
                  endTime={nextJob.bookingDetails.endTime}
                  dayShort={nextJob.bookingDetails.dayShort}
                  dateShort={nextJob.bookingDetails.dateShort}
                  reschedulingState={nextJob.reschedulingState}
                  serviceType={nextJob.bookingDetails.serviceType}
                />
              )}
              {
                !!lastJob && <LastExecutedJobCard />
              }
              {(isDashboardEmpty || !nextJob) && (
                <WarningModalTrigger
                  component={USPs}
                  triggerProp="onCTAPress"
                  message={t('dashboard.upcoming.emptyMessage')}
                  options={[
                    t('dashboard.upcoming.usp1'),
                    t('dashboard.upcoming.usp2')
                  ]}
                  CTAText={t('dashboard.book.cta')}
                  displayOnlyCTA={!(isDashboardEmpty && !nextJob)}
                  onCTAPress={() => navigate(plansPath)}
                  itemPress={this._handleConsoleClick}
                />
              )}
            </ScrollView>
          );
        }}
      </ErrorHandler>
    );
  }
}

Overview.propTypes = {
  client: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func
}

export default compose(
  withNavigation(),
  translate()
)(Overview);
