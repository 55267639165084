import React, { Component } from 'react';
import { getLogs, clearLogs } from '../helpers/logger';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Button, Clipboard
} from 'react-native';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_PRIMARY,
  COLOR_ACTION
} from '../constants/layout';
import Icon from './Icon';
import { ScrollView } from '../components/layout/ScrollView';

class Logger extends Component {
  state = {
    info: []
  };

  _handleLogItemClick(key) {
    const { info } = this.state;
    let logs = info.map(i => {
      i.shown = key === i.timestamp
      ? !i.shown
      : i.shown;
      return i;
    })
    this.setState({ info: logs })
  }

  _handleCopy() {
    Clipboard.setString(JSON.stringify(this.state.info));
  }

  async _handleClear() {
    await clearLogs();
    this.setState({ info: [] });
  }

  async componentDidMount() {
    const logInfo = await getLogs();
    this.setState({ info: logInfo });
  }

  render() {
    const { info } = this.state;

    return (
        <ScrollView>
          <TouchableOpacity activeOpacity = { 1 } key="loggerActions">
            <View style={styles.buttonContainer}>
              <Button
                style={styles.button}
                title="Copy Messages"
                onPress={() => this._handleCopy()}/>
              <Button
                style={styles.button}
                title="Clear Messages"
                color={COLOR_ACTION}
                onPress={() => this._handleClear()}/>
            </View>
            <View style={styles.separator} />
          </TouchableOpacity>
        {
            info && info.map((i, idx) => {
            let d = new Date(i.timestamp);
            return <TouchableOpacity activeOpacity = { 1 } key={idx} onPress={ () => this._handleLogItemClick(i.timestamp) }>
                <View style={styles.card}>
                <Icon style={styles.cardIcon}
                    size={20}
                    name={ i.success ? 'check-circle' : 'cancel' }
                    set='MaterialIcons'
                    color={i.success ? COLOR_PRIMARY : COLOR_ACTION}
                />
                <View style={styles.content}>
                  <Text style={styles.timestamp}>
                    { d.getDate() }/{ d.getMonth() + 1 } &nbsp;-&nbsp;
                    { d.getHours() }:{ d.getMinutes() }:{ d.getSeconds() }:{ d.getMilliseconds() }
                  </Text>
                  <Text style={styles.url}>
                    {i.url}
                  </Text>
                  {i.shown && (
                    <View>
                      <View style={styles.separator} />
                      <Text style={styles.params}>
                        {JSON.stringify(i.params)}
                      </Text>
                    </View>
                    )
                  }
                  {i.shown && (
                    <View>
                      <View style={styles.separator} />
                      <Text style={styles.response}>
                        {JSON.stringify(i.response)}
                      </Text>
                    </View>
                    )
                  }
                </View>
              </View>
            </TouchableOpacity>
          })
        }
        </ScrollView>
    );
  }
}
export default (Logger);

// @ts-ignore
const styles = StyleSheet.create({
  separator: {
    borderBottomColor: '#cecece',
    height: 1,
    marginTop: 5,
    marginBottom: 5,
    borderWidth: 1
  },
  timestamp: {
    color: '#656565',
    fontSize: 12
  },
  url: {
    color: '#000',
    fontSize: 12
  },
  params: {
    fontSize: 10
  },
  response: {
    fontSize: 10
  },
  container: {
    marginBottom: 8
  },
  inner: {
    backgroundColor: 'white'
  },
  cardIcon: {
    alignItems: 'flex-start',
    marginLeft: 5,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 20
  },
  content: {
    flex: 1,
    padding: 10,
    paddingLeft: 0
  },
  body: {
    fontSize: 16,
    lineHeight: 20,
    color: COLOR_TEXT_PRIMARY
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
    marginBottom: 5,
    padding: 5,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#fafafa',
    borderRadius: 4,
    shadowOpacity: 0.15,
    shadowRadius: 7,
    shadowOffset: {
        height: 2,
        width: 2
    },
    elevation: 7
  },
  btn: {
    width: '100%',
    height: 50
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
    marginHorizontal: 20
  }

});
