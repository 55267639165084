import { Query } from 'react-apollo';
import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import { getJob } from '../graphql/jobs';
import { Text, StyleSheet } from 'react-native';
import Button from '../components/Button';
import ErrorHandler from '../components/ErrorHandler';

import {
  ListItemForButton,
  ListItemContent
} from '../components/layout/ListItem';
import { ScrollView } from '../components/layout/ScrollView';

import {
  COLOR_RATING,
  COLOR_GRAY,
  SECONDARY_COLOR,
  COLOR_ACTION
} from '../constants/layout';

import withNavigation from '../helpers/withNavigation';
import { SimpleCard } from '../components/SimpleCard';
import { CleanerAvatar, CleanerAvatarSizeEnum } from '../components/rating/CleanerAvatar';
import { navigateToBook } from '../helpers/navigation';
import { plansPath } from '../helpers/urls';

import { isFeatureAvailable, FeaturesEnum } from '../features'

const styles = StyleSheet.create({
  avatar: {
    alignSelf: 'center',
    marginBottom: 16
  },
  headline: {
    marginTop: 16,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    color: COLOR_RATING
  },
  boldDescription: {
    fontSize: 16,
    fontWeight: '500',
    color: COLOR_GRAY,
    lineHeight: 25,
    textAlign: 'center'
  },
  description: {
    color: COLOR_GRAY,
    fontSize: 14,
    textAlign: 'center'
  },
  ctaSubtitle: {
    fontSize: 12,
    marginTop: 10,
    fontWeight: '800',
    textAlign: 'center'
  },
  primaryCtaSubtitle: {
    color: SECONDARY_COLOR
  },
  secondaryCtaSubtitle: {
    color: COLOR_ACTION
  }
});

class RatingSuccess extends Component {
  renderInternal() {
    const {
      t,
      navigate,
      params: { reference }
    } = this.props;

    const supportBundles = isFeatureAvailable(FeaturesEnum.SUPPORT_BUNDLES);

    return (
      <ScrollView>
        <Text style={styles.headline}>{t('ratingSuccess.headline')}</Text>
        <SimpleCard>
          <CleanerAvatar style={styles.avatar} size={CleanerAvatarSizeEnum.MEDIUM} />
          <Text style={styles.boldDescription}>
            {t('ratingSuccess.descriptionTitle')}
          </Text>
          <Text style={styles.description}>
            {t('ratingSuccess.description')}
          </Text>
          <ListItemForButton>
            <ListItemContent>
              <Button
                onPress={() =>
                  navigateToBook(
                    navigate,
                    reference,
                    t('quickbook.newbooking.mainTitle')
                  )
                }
                cta
                title={t('ratingSuccess.cta.primary')}
              />
              <Text
                style={[styles.ctaSubtitle, styles.primaryCtaSubtitle]}
              >
                {t('ratingSuccess.cta.primary.subtitle')}
              </Text>
            </ListItemContent>
            {supportBundles ? (
              <ListItemContent>
                <Button
                  onPress={() => navigate(plansPath)}
                  cta
                  color={COLOR_RATING}
                  title={t('ratingSuccess.cta.secondary')}
                />
                <Text
                  style={[
                    styles.ctaSubtitle,
                    styles.secondaryCtaSubtitle
                  ]}
                >
                  {t('ratingSuccess.cta.secondary.subtitle')}
                </Text>
              </ListItemContent>
            ) : null}
          </ListItemForButton>
        </SimpleCard>
      </ScrollView>
    );
  }
  render() {
    const {
      params: { reference }
    } = this.props;
    return (
      <Query
        query={getJob}
        variables={{ reference }}
        fetchPolicy="cache-and-network"
        children={props => {
          const { data: { job } = {} } = props;
          return (
            <ErrorHandler
              {...props}
              dataIsAvailable={!!job}
              children={() => this.renderInternal()}
            />
          );
        }}
      />
    );
  }
}

export default compose(
  withNavigation(),
  translate()
)(RatingSuccess);
