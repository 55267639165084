import React, { Component } from 'react';
import { Platform, Keyboard } from 'react-native';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
import { translate } from 'react-i18next';
import styled from 'styled-components/native';
import { ScrollView } from '../components/layout/ScrollView';
import { isFeatureAvailable, FeaturesEnum } from '../features';

import {
  COLOR_GRAY,
  COLOR_SUBTITLE,
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_DIVIDER,
  SHADOW_STYLE,
  COLOR_TEXT_PRIMARY
} from '../constants/layout';
import USPs from '../components/bundles/USPs';
import withNavigation from '../helpers/withNavigation';
import Button from '../components/Button';
import { withApollo } from 'react-apollo';
import { createBundleBooking } from '../graphql/bundles.js';
import GoogleAnalytics from '../helpers/analytics/googleAnalytics';
import * as events from '../constants/analyticsEvents';
import { getUpdateProfileConfig } from '../graphql/config.js';
import Notification from '../components/Notification';

class OrderBundle extends Component {
  state = {
    firstname: '',
    lastname: '',
    phone: '',
    loading: false,
    success: false,
    config: {},
    error: null
  };
  ga = new GoogleAnalytics();

  constructor(props) {
    super(props);
    this.getConfig();
  }

  getConfig() {
    const { client } = this.props;

    client.query({
      query: getUpdateProfileConfig,
      fetchPolicy: 'network-only'
    })
    .then(response => {
      if (response && response.data && response.data.config) {
        const { config } = response.data;
        this.setState({ ...this.state, config: config })
      }
    });
  }

  submitRequest() {
    const { client, t } = this.props;
    const { firstname, lastname, phone, config } = this.state;
    const { params: { bundle = null } } = this.props;

    if (Keyboard) {
      Keyboard.dismiss();
    }

    const match = phone.match(new RegExp(config.mobile || ''));
    if (!match) {
      this.setState({ ...this.state, error: t('ui.form.validation.phoneNumber') })
      return;
    }

    client.mutate({
      mutation: createBundleBooking,
      variables: {
        firstName: firstname,
        lastName: lastname,
        phoneNumber: phone,
        name: bundle.name,
        price: bundle.price.raw,
        period: parseInt(bundle.nbOfWeeks, 10),
        nbAppointmentsPerWeek: parseInt(bundle.nbAppointmentsPerWeek),
        nbHoursPerAppointment: parseFloat(bundle.nbHoursPerAppointment)
      }
    }).then((response) => {
      const {
        data: {
          createBundleBooking: {
            status
          }
        }
      } = response;
      if (status === 'ERROR') {
        this.setState({ ...this.state, error: t('ui.error.generic') });
        this.setState({ ...this.state, loading: false, success: false });
      } else {
        this.ga.recordEvent('Customer App', events.BUNDLES_BOOK_BUNDLE + ' ' + bundle.name);
        this.setState({ ...this.state, loading: false, success: true });
      }
    })
    .catch((e) => {
      this.setState({ ...this.state, loading: false, success: false });
      this.setState({ ...this.state, error: t('ui.error.generic') });
    })
  }

  render() {
    const { t, navigate, params: { bundle = null } } = this.props;
    const { success, loading, firstname, lastname, phone, error } = this.state;

    const uspBundles = [
      isFeatureAvailable(FeaturesEnum.CLEANING_MATERIALS) ? t('bundles.page.usps.cleaningMaterials') : false,
      t('bundles.page.usps.cancelBookingAnytime')
    ].filter(Boolean)

    return success
    ? (
      <PageContainer>
        <ScrollView>
          <ThanksImgGroup>
            <ThanksImgLeft
              source={
              Platform.OS === 'web'
                ? require('../assets/images/plansThankyou/left.svg')
                : require('../assets/images/plansThankyou/left.png')
            } />
           <ThanksImgCenter
              source={
              Platform.OS === 'web'
                ? require('../assets/images/plansThankyou/center.svg')
                : require('../assets/images/plansThankyou/center.png')
            } />
            <ThanksImgRight
              source={
              Platform.OS === 'web'
                ? require('../assets/images/plansThankyou/right.svg')
                : require('../assets/images/plansThankyou/right.png')
            } />
          </ThanksImgGroup>

          <ThanksTitle>{t('bundles.page.confirmation.title')}</ThanksTitle>
          <ThanksText>{t('bundles.page.confirmation.text')}</ThanksText>
          <CTAButton
              title={t('quickbook.cta.backToOverview')}
              ctaBorder
              textColor={COLOR_PRIMARY}
              justrounded
              gigantic
              primary
              noshadow
              bold
              onPress={() => navigate('/')}
            />
        </ScrollView>
      </PageContainer>
    )
    : (
      <React.Fragment>
      <PageContainer>
        <ScrollView>
          {!!bundle.name && <Title>{bundle.name}</Title>}
          {!!bundle.price &&
          <Container>
            <Subtitle>
              {t('bundles.page.subtitle')}
            </Subtitle>
            <StartingPrice>&nbsp;{bundle.price.formatted}</StartingPrice>
          </Container>
          }
          {!!bundle.nbOfWeeks &&
          <Container>
            <Text>
              {t('bundles.card.period').replace('%d', bundle.nbOfWeeks)}
            </Text>
          </Container>
          }
          {!!bundle.nbAppointmentsPerWeek &&
          <Container>
            <Text>
            {t('bundles.card.cleanings', { cleanings: bundle.nbAppointmentsPerWeek })}
            </Text>
          </Container>
          }
          {!!bundle.nbHoursPerAppointment &&
          <Container>
            <Text>
            {bundle.nbHoursPerAppointment} {t('bundles.card.hours')}
            </Text>
          </Container>
          }

          <React.Fragment>
            <USPs
              options={uspBundles}
              outterStyle={{ marginTop: 37, marginBottom: 10 }}
            />
            <Divider />
          </React.Fragment>

          <React.Fragment>
            <FormText>{t('bundles.page.book.title')}</FormText>
            <FormRow>
              <FormInputShort
                placeholder={t('bundles.page.book.firstname')}
                id="book-firstname"
                style={{
                  ...SHADOW_STYLE,
                  ...Platform.select({
                    web: {
                        outlineColor: COLOR_PRIMARY
                    }
                  })
                }}
                onChangeText={(txt) => {
                  this.setState({ ...this.state, firstname: txt.trim() });
                }}
              />
              <FormInputShort
                placeholder={t('bundles.page.book.lastname')}
                id="book-lastname"
                style={{
                  ...SHADOW_STYLE,
                  ...Platform.select({
                    web: {
                        outlineColor: COLOR_PRIMARY
                    }
                  })
                }}
                onChangeText={(txt) => {
                  this.setState({ ...this.state, lastname: txt.trim() });
                }}
              />
            </FormRow>
            <FormInput
              placeholder={t('bundles.page.book.phone')}
              id="book-phone"
              style={{ ...SHADOW_STYLE }}
              onChangeText={(txt) => {
                this.setState({ ...this.state, phone: txt.trim() });
              }}
              keyboardType='phone-pad'
              />
            <CTAButton
              title={t('bundles.page.book.cta')}
              justrounded
              gigantic
              primary
              noshadow
              bold
              loading={loading}
              disabled={loading || !firstname.length || !lastname.length || !phone.length}
              onPress={() => {
                this.submitRequest();
              }}
            />
          </React.Fragment>
        </ScrollView>
      </PageContainer>
      <Notification
        open={!!error}
        message={error}
        onClose={() => this.setState({ error: null })}
      />
      </React.Fragment>
    );
  }
}

OrderBundle.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  navigateBack: PropTypes.func,
  navigateReplace: PropTypes.func,
  onProfileButtonClick: PropTypes.func,
  params: PropTypes.object,
  pathName: PropTypes.string,
  t: PropTypes.func
};

export default compose(
  withNavigation(),
  translate(),
  withApollo
)(OrderBundle);

const PageContainer = styled.View`
  padding: 0 15px;
  background: ${COLOR_WHITE};
  display: flex;  
  flex: 1;
`;

const Title = styled.Text`
  font-size: 21px;
  font-weight: bold;
  color: ${COLOR_GRAY};
  text-align: center;
  margin-top: 34px;
  margin-bottom: 10px;
`;

const Subtitle = styled.Text`
  padding-top: 5px;
  font-size: 13px;
  font-weight: normal;
  color: ${COLOR_SUBTITLE};
  text-align: center;
`;

const Text = styled.Text`
  padding-top: 5px;
  font-size: 13px;
  font-weight: normal;
  color: ${COLOR_TEXT_PRIMARY};
  text-align: center;
`;

const StartingPrice = styled.Text`
  font-size: 21px;
  font-weight: bold;
  line-height: 22px;
  color: ${COLOR_PRIMARY};
  text-align: center;
`;

const Container = styled.Text`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
`;

const Divider = styled.View`
  margin: 0px 15px 30px 0px;
  height: 0.5px;
  backgroundColor: ${COLOR_DIVIDER};
`;

const FormText = styled.Text`
  font-size: 15px;
  font-weight: normal;
  color: ${COLOR_GRAY};
  margin: 5px 10px 15px 10px;
`;

const FormInput = styled.TextInput`
  width: 98%;
  height: 50px;
  border-radius: 4px;
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
  background-color: ${COLOR_WHITE};
  margin: 2px auto 7px auto;
  font-size: 14px;
  font-weight: 300;
  color: #999;
  padding: 15px;
`;

const FormInputShort = styled.TextInput`
  width: 48%;
  height: 50px;
  border-radius: 4px;
  box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.1);
  background-color: ${COLOR_WHITE};
  margin: 2px auto 7px auto;
  font-size: 14px;
  font-weight: 300;
  color: #999;
  padding: 15px;
`;

const FormRow = styled.View`
  display: flex;
  flex-direction: row;
`;

const CTAButton = styled(props =>
  <Button {...props} />)`
  margin: 26px 0px 15px 0px;
  height: 60px;
`;

const ThanksTitle = styled.Text`
  font-size: 21px;
  color: ${COLOR_GRAY};
  text-align: center;
`;

const ThanksText = styled.Text`
  font-size: 15px;
  color: ${COLOR_GRAY};
  margin-top: 11px;
  margin-bottom: 99px;
  text-align: center;
`;

const ThanksImgGroup = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-top: 55px;
  margin-bottom: 23px;
`;

const ThanksImgLeft = styled.Image`
  margin-right: 15px;
  margin-top: 2px;
  width: 61px;
  height: 61px;
`;

const ThanksImgCenter = styled.Image`
  margin-right: 15px;
  width: 68px;
  height: 68px;
`;

const ThanksImgRight = styled.Image`
  margin-top: 8px;
  width: 51px;
  height: 51px;
`;
