import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { translate } from 'react-i18next';
import Notification from '../Notification';
import setupBasicForm from './Form';

export default function setupForm() {
  const { Form, FormItem, FormContext } = setupBasicForm();

  class MutatingForm extends Component {
    state = {
      error: null
    };

    setError = error => this.setState({ error });

    render() {
      const {
        t,
        mutation,
        model,
        initialValues,
        validationSchema,
        onSuccess = result => {},
        onError = error => {},
        mapFieldsToParameters = fields => fields,
        mapParametersToFields = parameters => parameters,
        children = (action, mutation, form) => {},
        refetchQueries = []
      } = this.props;
      const { error } = this.state;

      return (
        <React.Fragment>
          <Mutation
            mutation={mutation}
            refetchQueries={refetchQueries}
            children={(action, mutationProps) => {
              return (
                <Form
                  onSubmit={async (values, { setErrors }) => {
                    try {
                      const variables = await mapFieldsToParameters(values);                    
                      const result = await action({ variables });
                      const {
                        data: {
                          [model]: { status, message, fieldErrors } = {}
                        } = {}
                      } = result;

                      if (status === 'SUCCESS') {
                        onSuccess(result);
                      } else if (fieldErrors && fieldErrors.length) {
                        setErrors(
                          mapParametersToFields(transformApiErrors(fieldErrors))
                        );
                      } else {
                        onError(message);
                        this.setError(message);
                      }
                    } catch (e) {
                      onError(e);
                      if (e.networkError) {
                        this.setError(t('ui.error.network'));
                      } else {
                        this.setError(t('ui.error.generic'));
                      }
                    }
                  }}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  children={formProps =>
                    children(action, mutationProps, formProps)
                  }
                />
              );
            }}
          />
          <Notification
            open={!!error}
            message={error}
            onClose={() => this.setState({ error: null })}
          />
        </React.Fragment>
      );
    }
  }

  return { MutatingForm: translate()(MutatingForm), FormItem, FormContext };
}

const transformApiErrors = errors =>
  (errors || []).reduce(
    (obj, error) => ({ ...obj, [error.fieldName]: error.errorMessage }),
    {}
  );
