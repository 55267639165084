import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { COLOR_PRIMARY } from '../constants/layout';

export default function Spinner() {
  return (
    <View style={{ height: 48 }}>
      <ActivityIndicator color={COLOR_PRIMARY} animating />
    </View>
  );
}
