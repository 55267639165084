export const formatTitle = (day, date) =>
  [day, date].filter(Boolean).join(', ');

export const formatTimespan = (from, to) =>
  [from, to].filter(Boolean).join(' – ');

export const formatTime = (from) =>
  [from].filter(Boolean);

export const formatTimeForBooking = (date) =>
  `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

export const formatFullAddress = (building, apartment, city, area) =>
  `
${building} ${apartment}
${area}, ${city}
`.trim();

export const formatShortDate = (date = null) => {
  const d = new Date(date);
  const isValidDate = (!isNaN(d) && d instanceof Date);

  return !date || !isValidDate
  ? null
  : `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}`
}
