import { Component } from 'react';
import withNavigation from '../../helpers/withNavigation';

class Redirect extends Component {
  componentDidMount() {
    const { to, navigate } = this.props;

    if (typeof to === 'string') {
      navigate(to);
    } else {
      navigate(to.pathname, to);
    }
  }

  render() {
    return null;
  }
}

export default withNavigation()(Redirect);
