import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableHighlight,
  View
} from 'react-native';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_SECONDARY,
  SHADOW_STYLE,
  NO_SHADOW_STYLE,
  COLOR_ACTION,
  COLOR_WHITE,
  COLOR_GRAYOUT
} from '../constants/layout';

export default function Button({
  onPress,
  title,
  style = {},
  disabled = false,
  disabledGreyedOut = false,
  primary = false,
  secondary = false,
  transparent = false,
  action = false,
  loading = false,
  color = null,
  textColor = null,
  gigantic = false,
  rounded = false,
  justrounded = false,
  innerRef = null,
  icon = null,
  alignInline = false,
  cta = false,
  ctaBorder = false,
  ctaAlert = false,
  ctaAlertBorder = false,
  bold = false,
  noshadow = false,
  addon = false,
  empty,
  underline = false
}) {
  const underlayColor = transparent ? 'transparent' : 'white';
  return (
    <TouchableHighlight
      onPress={onPress}
      underlayColor={underlayColor}
      activeOpacity={0.8}
      disabled={disabled}
      style={[styles.container, style, alignInline && styles.buttonAlignInline]}
      ref={innerRef}
    >
      <View>
        <View
          style={[
            styles.button,
            primary && styles.buttonPrimary,
            cta && styles.buttonCTA,
            ctaBorder && styles.buttonCTABorder,
            ctaAlert && styles.buttonCTAAlert,
            ctaAlertBorder && styles.buttonCTAAlertBorder,
            secondary && styles.buttonSecondary,
            transparent && styles.buttonTransparent,
            gigantic && styles.buttonGigantic,
            rounded && styles.buttonRounded,
            justrounded && styles.buttonJustRounded,
            empty && styles.buttonEmpty,
            noshadow && styles.noShadow,
            disabled && styles.buttonDisabled,
            disabledGreyedOut && styles.buttonDisabledGreyedOut,
            color && { backgroundColor: color }
          ]}
        >
          {loading ? (
            <ActivityIndicator color="#FFFFFF" style={{ marginVertical: -2 }} />
          ) : (
            <View style={!!icon && styles.flexContainer}>
              {icon}
              <Text
                style={[
                  styles.buttonTitle,
                  secondary && styles.buttonTitleSecondary,
                  action && styles.buttonTitleAction,
                  textColor && { color: textColor },
                  bold && styles.bold,
                  icon && styles.textWithIcon,
                  underline && styles.underline
                ]}
              >
                {title ? title.toUpperCase() : ''}
              </Text>
            </View>
          )}
        </View>
        {!!addon && addon}
      </View>
    </TouchableHighlight>
  );
}

const styles = StyleSheet.create({
  container: {},
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    zIndex: 1
  },
  buttonGigantic: {
    padding: 20
  },
  buttonPrimary: {
    backgroundColor: COLOR_PRIMARY,
    padding: 8,
    ...SHADOW_STYLE
  },
  buttonSecondary: {
    padding: 16,
    backgroundColor: 'transparent'
  },
  buttonTransparent: {
    backgroundColor: 'transparent',
    padding: 8,
    ...NO_SHADOW_STYLE
  },
  buttonDisabled: {
    opacity: 0.5
  },
  buttonDisabledGreyedOut: {
    backgroundColor: COLOR_GRAYOUT
  },
  buttonJustRounded: {
    borderRadius: 3
  },
  buttonRounded: {
    borderRadius: 30
  },
  buttonEmpty: {
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderWidth: 0
  },
  buttonTitle: {
    color: 'white',
    textAlign: 'center',
    lineHeight: 20
  },
  buttonTitleSecondary: {
    color: COLOR_TEXT_SECONDARY,
    textAlign: 'left',
    fontWeight: 'bold'
  },
  buttonTitleAction: {
    color: COLOR_PRIMARY
  },
  buttonAlignInline: {
    display: 'flex'
  },
  buttonCTA: {
    padding: 20
  },
  buttonCTABorder: {
    padding: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR_PRIMARY,
    backgroundColor: COLOR_WHITE
  },
  buttonCTAAlert: {
    padding: 20,
    backgroundColor: COLOR_ACTION
  },
  buttonCTAAlertBorder: {
    padding: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR_ACTION,
    backgroundColor: COLOR_WHITE
  },
  bold: {
    fontWeight: 'bold'
  },
  textWithIcon: {
    lineHeight: 24,
    marginLeft: 5
  },
  noShadow: {
    ...NO_SHADOW_STYLE
  },
  underline: {
    textDecorationLine: 'underline'
  }
});
