import i18next from 'i18next';
import { I18nManager, NativeModules, Platform } from 'react-native';
import { getEnvironmentVariable } from '../constants/environment';
import arabic from '../translations/ar.json';
import english from '../translations/en.json';

const i18n = i18next.init({
  lng: getEnvironmentVariable('REACT_APP_LANGUAGE'),
  // debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
  keySeparator: '>',
  resources: {
    en: { translation: english },
    ar: { translation: arabic }
  }
});

export function setAppLanguage(id) {
  i18n.changeLanguage(id);

  I18nManager.forceRTL(id === 'ar');

  if (Platform.OS === 'web') {
    window.document.documentElement.lang = id;
  }
}

export function getLocale() {
  let locale = 'en';

  if (Platform.OS === 'android') {
    locale = NativeModules.I18nManager.localeIdentifier;
  } else if (Platform.OS === 'ios') {
    locale = NativeModules.SettingsManager.settings.AppleLocale;
  } else if (Platform.OS === 'web') {
    locale = navigator.language;
  }

  return locale;
}

export default i18n;
