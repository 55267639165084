import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Linking, Platform, AsyncStorage } from 'react-native';
import { CACHE_FONTS, CACHE_IMAGES } from './constants/assets';
import ErrorBoundary from './containers/ErrorBoundary';
import Router from './containers/Router';
import StateManager from './containers/StateManager';
import { cacheFonts, cacheImages } from './helpers/assets';
import './helpers/fontLoader';
import i18n from './helpers/i18n';
import { closeBrowser } from './helpers/navigation';
import { LoadingScreen } from './helpers/splashScreen';
import { WarningModal } from './components/WarningModal';
import Sentry from './helpers/sentry';
import { isFeatureAvailable, FeaturesEnum } from './features';
export default class App extends React.Component {
  state = {
    isReady: false,
    isWarningConfirmed: null
  };

  async _loadAssetsAsync() {
    const imageAssets = cacheImages(CACHE_IMAGES);

    const fontAssets = cacheFonts(CACHE_FONTS);

    await Promise.all([...imageAssets, ...fontAssets]);
  }

  async componentDidMount() {
    Linking.addEventListener('url', e => {
      closeBrowser();
    });
    if (isFeatureAvailable(FeaturesEnum.NON_OPERATIONAL_WARNING)) {
      const warning = await AsyncStorage.getItem(
        FeaturesEnum.NON_OPERATIONAL_WARNING
      );
      this.setState({ isWarningConfirmed: Boolean(warning) });
    }
  }
  confirmWarning = async () => {
    try {
      await AsyncStorage.setItem(FeaturesEnum.NON_OPERATIONAL_WARNING, '1');
      this.setState({ isWarningConfirmed: true });
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  render() {
    const { isReady, isWarningConfirmed } = this.state;
    const displayWarning =
      isFeatureAvailable(FeaturesEnum.NON_OPERATIONAL_WARNING) &&
      // explicitly check if false as the null means the value is still loading from the storage
      isWarningConfirmed === false;

    if (Platform.OS !== 'web' && !isReady) {
      return (
        <LoadingScreen
          startAsync={this._loadAssetsAsync}
          onFinish={() => this.setState({ isReady: true })}
        />
      );
    }
    return (
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <StateManager>
            <Router />
            { displayWarning ? <WarningModal onPress={this.confirmWarning}/> : null }
          </StateManager>
        </ErrorBoundary>
      </I18nextProvider>
    );
  }
}
