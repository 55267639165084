import React, { Component } from 'react';
import ReactDatePicker from 'react-date-picker';
import '../css/react-datepicker.css';

class DatePicker extends Component {
  render() {
    return (
      <ReactDatePicker
        {...this.props}
        clearIcon={null}
        isCalendarOpen={false}
        disableClock={true}
      />
    );
  }
}

export default DatePicker;
