import adyen from 'adyen-cse-js';
import { Component } from 'react';

export default class AdyenEncryptor extends Component {
  encrypt(data) {
    if (this._encryptor) {
      return this._encryptor.encrypt(data);
    } else {
      throw new Error('Encryptor is not ready');
    }
  }

  componentDidMount() {
    this._encryptor = adyen.createEncryption(this.props.encryptionKey);
  }

  render() {
    return null;
  }
}
