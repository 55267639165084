import React from 'react';
import styled from 'styled-components/native';
import { Platform, View } from 'react-native';
import { COLOR_PRIMARY, COLOR_GRAY } from '../../constants/layout';
import Icon from '../Icon';
import Button from '../../components/Button';
import ListItem, {
    ListItemContent,
    ListItemForButton
  } from '../layout/ListItem';

export default function USPs({ message, options, CTAText, displayOnlyCTA, onCTAPress, itemPress }) {
  return (
    <MainContainer>
      {
        !displayOnlyCTA &&
          <MainImageContainer>
            <MainImage
              source={
                Platform.OS === 'web'
                  ? require('../../assets/images/usp.svg')
                  : require('../../assets/images/usp.png')
              }
              resizeMode="contain"
            />
          </MainImageContainer>
      }
      {(!displayOnlyCTA && message.length) ? (<TextMessage>{message}</TextMessage>) : null}
      {!displayOnlyCTA &&
        <USPSContainer>
          {options.map((l, i) =>
              <ListItem
                key={i}
                title={l}
                style={{ backgroundColor: 'transparent', marginBottom: 10 }}
                bareIcon={true}
                nopadding={true}
                onPress={itemPress}
                icon={<Icon name="done" color={COLOR_PRIMARY} size={24}/>}
              />
          )}
        </USPSContainer>
      }
      {displayOnlyCTA && <View style={{ flexGrow: 1 }}></View>}
      <ListItemForButton style={{ marginBottom: 30 }}>
        <ListItemContent>
          <Button
            cta
            rounded
            title={CTAText}
            onPress={onCTAPress}
          />
        </ListItemContent>
      </ListItemForButton>
    </MainContainer>
  );
}

const TextMessage = styled.Text`
  margin: 16px;
  marginBottom: 40px;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR_GRAY};
  text-align: center;
`;

const MainImage = styled.Image`
  flex: 1;
  width: 100%;
`;

const MainImageContainer = styled.View`
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 30;
`;

const MainContainer = styled.View`
  width: 100%;
  flex: 1;
  justify-content: center;
  margin-top: 27px;
`;

const USPSContainer = styled.View`
  justify-content: center;
  align-items: center;
`;
