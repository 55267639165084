import gql from 'graphql-tag';

export const getCities = gql`
  query getCities {
    cities {
      id: name
      name
      areas {
        id: name
        name
      }
    }
  }
`;
