import gql from 'graphql-tag';

export const saveContact = gql`
  mutation saveContact(
    $salutation: ESalutation
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $reason: String
  ) {
    saveContact(
      salutation: $salutation
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
      reason: $reason
    ) {
      status,
      message
    }
  }
`;
